$white-color: #ffffff;
$dark-blue-color: #009ef7;
$dark-yellow-color: #ffc700;
$dark-grey-color: #7e8299;
$dark-red-color: #ff0000;
$dark-orange-color: #ff5213;
$dark-green-color: #309830;
$dark-royal-blue-color: #2563eb;
$root-surface-ground-color: #eff3f8;

$light-blue-color: #f1faff;
$light-grey-color: #f9f9f9;
$light-red-color: #fff5f8;
$light-orange-color: #fcf3ec;
$light-yellow-color: #fff8dd;
$light-green-color: #e8fff3;
$light-blue-gray-color: #e2e5e9;
$light-diff-gray-color: #f3f6f9;
$light-royal-blue-color: #d0e1fd;
$light-pink-color: #ffe7e6;
$light-footer-blue-color: #f7fcfe;
$light-grey: #9fa9b7;
$ligth-blue-box-shadow: rgba(38, 132, 252, 0.20);
$ligth-royal-blue-box-shadow: #9dc1fb;

$royal-blue-color: #3b82f6;
$hot-royal-blue-color: #eff6ff;
$hot-green-color: #008804;
$hot-red-color: #f1416c;
$hot-green-color: #50cd89;
$apricot-color: #fb8459;
$yellow-apricot-color: #ffdf6f;
$space-grey-color: #dee2e6;
$orange-red-color: #f44336;
$pale-red-color: #ffd0ce;
$vibrant-red-color: #ff5757;

$single-light-yellow-color: #fff3cd;
$single-light-gray-color: #dedede;
$single-dark-gray-color: #707072;
$single-bej-gray-color: #999;

$pink-color: #ff4081;
$brown-color: #856404;
$gray-color: #555759;
$yellow-color: #eec800;
$green-color: rgb(143, 224, 21);
$blue-color: rgb(44, 73, 167);
$black-color: #000000;

$table-gray-color: #f2f2f2;
$table-light-gray-color: #e8e8e8;

$text-gray-color: #495057;
$border-light-gray-color: #ced4da;

$box-shadow-gray-color: #ccc;
$transparent-white-color: rgba(255, 255, 255, 0.2);
$transparent-black-color: rgba(0, 0, 0, 0.4);
$focus-blue-color: 0 0 0 0.2rem #a6d5fa;

// Figma colors
$global-text-secondary-color: #6c757d;
$global-mask-background-color: #00000066;
$global-error-color: #e24c4c;

$theme-primary-light-color: #bfdbfe;
$theme-primary-darker-color: #1d4ed8;
$theme-highlight-background: #eff6ff;

$action-icon-background: #ffffff00;
$action-icon-hover-background: #e9ecef;
$action-icon-hover-color: #343a40;

$image-mask-background: #000000e6;
$image-preview-indicator-color: #f8f9fa;
$image-preview-indicator-background: #00000080;
$image-preview-action-icon-hover-background: #ffffff1a;

$color-picker-background: #323232;
$color-picker-border-color: #191919;

$input-switch-slider-off-hover-background: #b1bac4;

$password-weak-background: #ea5455;
$password-medium-background: #ff9f42;
$password-strong-background: #29c76f;

$rating-cancel-icon-hover-color: #e73d3e;

$button-secondary-background: #64748b;
$button-secondary-focus-shadow: #e2e8f0;
$button-secondary-hover-background: #475569;
$button-secondary-active-background: #334155;
$button-secondary-outlined-hover-background: #64748b0a;
$button-secondary-outlined-active-background: #64748b29;

$button-info-outlined-hover-background: #3b82f60a;
$button-info-outlined-active-background: #3b82f629;

$button-success-background: #22c55e;
$button-success-focus-shadow: #bbf7d0;
$button-success-hover-background: #16a34a;
$button-success-active-background: #15803d;
$button-success-outlined-hover-background: #22c55e29;
$button-success-outlined-active-background: #22c55e0a;

$button-warning-background: #f59e0b;
$button-warning-focus-shadow: #fde68a;
$button-warning-hover-background: #d97706;
$button-warning-active-background: #b45309;
$button-warning-outlined-hover-background: #f59e0b0a;
$button-warning-outlined-active-background: #f59e0b29;

$button-help-background: #a855f7;
$button-help-focus-shadow: #e9d5ff;
$button-help-hover-background: #9333ea;
$button-help-active-background: #7e22ce;
$button-help-outlined-hover-background: #a855f70a;
$button-help-outlined-active-background: #a855f729;

$button-danger-background: #ef4444;
$button-danger-focus-shadow: #fecaca;
$button-danger-hover-background: #dc2626;
$button-danger-active-background: #b91c1c;
$button-danger-outlined-hover-background: #ef44440a;
$button-danger-outlined-active-background: #ef444429;

$speed-dial-action-hover-background: #022354;
$table-body-even-row-background: #fcfcfc;
$dock-border-color: #ffffff33;
$surface-100: #f5f5f5;

$message-info-background: #e9e9ff;
$message-info-color: #696cff;

$message-success-background: #e4f8f0;
$message-success-color: #1ea97c;

$message-warning-background: #fff2e2;
$message-warning-color: #cc8925;

$message-error-background: #ffe7e6;
$message-error-color: #ff5757;

$carousel-indicator-hover-background: #adb5bd;
$galleria-indicator-hover-onItem-background: #ffffff99;
$galleria-indicator-onItem-background: #ffffff66;
$scrolltop-background: #000000b3;
$scrolltop-hover-background: #000000cc;
$skeleton-background: #dee2e6b3;

$border-color-footer: rgba(255, 255, 255, 0);
//prime colors
$blue-50: #f5f9ff;
$blue-200: #abc9fb;
$blue-300: #85b2f9;
$blue-400: #609af8;
$blue-600: #326fd1;
$blue-700: #295bac;
$blue-800: #204887;
$blue-900: #183462;

$green-50: #f4fcf7;
$green-100: #caf1d8;
$green-200: #a0e6ba;
$green-300: #76db9c;
$green-400: #4cd07d;
$green-700: #188a42;
$green-800: #136c34;
$green-900: #0e4f26;

$yellow-50: #fefbf3;
$yellow-100: #faedc4;
$yellow-200: #f6de95;
$yellow-300: #f2d066;
$yellow-400: #eec137;
$yellow-500: #eab308;
$yellow-600: #c79807;
$yellow-700: #a47d06;
$yellow-800: #816204;
$yellow-900: #5e4803;

$cyan-50: #f3fbfd;
$cyan-100: #c3edf5;
$cyan-200: #94e0ed;
$cyan-300: #65d2e4;
$cyan-400: #35c4dc;
$cyan-500: #06b6d4;
$cyan-600: #059bb4;
$cyan-700: #047f94;
$cyan-800: #036475;
$cyan-900: #024955;

$pink-50: #fef6fa;
$pink-100: #fad3e7;
$pink-200: #f7b0d3;
$pink-300: #f38ec0;
$pink-400: #f06bac;
$pink-500: #ec4899;
$pink-600: #c93d82;
$pink-700: #a5326b;
$pink-800: #822854;
$pink-900: #5e1d3d;

$indigo-50: #f7f7fe;
$indigo-100: #dadafc;
$indigo-200: #bcbdf9;
$indigo-300: #9ea0f6;
$indigo-400: #8183f4;
$indigo-500: #6366f1;
$indigo-600: #5457cd;
$indigo-700: #4547a9;
$indigo-800: #363885;
$indigo-900: #282960;

$teal-50: #f3fbfb;
$teal-100: #c7eeea;
$teal-200: #9ae0d9;
$teal-300: #6dd3c8;
$teal-400: #41c5b7;
$teal-500: #14b8a6;
$teal-600: #119c8d;
$teal-700: #0e8174;
$teal-800: #0b655b;
$teal-900: #084a42;

$orange-50: #fff8f3;
$orange-100: #feddc7;
$orange-200: #fcc39b;
$orange-300: #fba86f;
$orange-400: #fa8e42;
$orange-500: #f97316;
$orange-600: #d46213;
$orange-700: #ae510f;
$orange-800: #893f0c;
$orange-900: #642e09;

$bluegray-50: #f7f8f9;
$bluegray-70: #a4a4a4;
$bluegray-100: #dadee3;
$bluegray-200: #bcc3cd;
$bluegray-300: #9fa9b7;
$bluegray-400: #818ea1;
$bluegray-600: #556376;
$bluegray-700: #465161;
$bluegray-800: #37404c;
$bluegray-900: #282e38;
$bluegray-1000: #38424c;
$bluegray-2000: #353f4a;

$purple-50: #fbf7ff;
$purple-100: #ead6fd;
$purple-200: #dab6fc;
$purple-300: #c996fa;
$purple-400: #b975f9;
$purple-600: #8f48d2;
$purple-700: #763cad;
$purple-800: #5c2f88;
$purple-900: #432263;

$red-50: #fff5f5;
$red-100: #ffc0cb;
$red-200: #ffaca7;
$red-300: #ff8780;
$red-400: #ff6259;
$red-500: #ff3d32;
$red-600: #d9342b;
$red-700: #b32b23;
$red-800: #8c221c;
$red-900: #661814;
