@import "./variables";

$color_1: $single-dark-gray-color;
$color_2: $text-gray-color;
$color_3: $royal-blue-color;
$color_4: $orange-red-color;
$color_5: $dark-blue-color;
$color_6: $white-color;
$color_7: #d9362b;
$color_8: #607d8b;
$color_9: #263238;
$color_10: #fbc02d;
$color_11: #9c27b0;
$color_12: $dark-green-color;
$color_13: $light-royal-blue-color;
$color_14: inherit;
$color_15: #0d3c61;
$color_16: #1e4620;
$color_17: #644d12;
$color_18: #661a14;
$color_19: $light-grey-color;
$color_20: #212121;
$color_21: #cae6fc;
$color_22: #c2eff5;
$color_23: #a0d2fa;
$color_24: #1769aa;
$color_25: #fde4a5;
$color_26: #852196;
$color_27: #d4a2dd;
$color_28: #b32d23;
$color_29: #ffada7;
$color_30: #d4ecd5;
$color_31: #4caf50;
$color_32: #fef0cd;
$color_33: #ffd1ce;
$color_34: #ff4032;
$color_35: #419544;
$color_36: #d5a326;
$font-family_1: -apple-system, BlinkMacSystemFont, "Inter", Roboto, Helvetica,
  Arial, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
$font-family_2: -apple-system, BlinkMacSystemFont, " Inter", Roboto, Helvetica,
  Arial, "Apple Color Emoji", "Segoe UI Emoji";
$background-color_1: $transparent-black-color;
$background-color_2: $light-grey-color;
$background-color_3: $white-color;
$background-color_4: transparent;
$background-color_5: #e9ecef;
$background-color_6: $light-royal-blue-color;
$background-color_7: $space-grey-color;
$background-color_8: inherit;
$background-color_9: $transparent-white-color;
$background-color_10: rgba(0, 0, 0, 0.5);
$background-color_11: rgba(255, 255, 255, 0.1);
$background-color_12: #607d8b;
$background-color_13: $dark-green-color;
$background-color_14: $royal-blue-color;
$background-color_15: #fbc02d;
$background-color_16: #d9362b;
$background-color_17: $dark-blue-color;
$border-color_1: $dark-blue-color;
$border-color_2: $orange-red-color;
$border-color_3: transparent;
$border-color_4: $royal-blue-color;
$border-color_5: $white-color;
$border-color_6: $border-light-gray-color;
$border-color_7: $single-dark-gray-color;
$border-color_8: #1c80cf;
$border-color_9: #1769aa;
$border-color_10: $dark-yellow-color;
$border-color_11: #852196;
$border-color_12: #9c27b0;
$border-color_13: #b32d23;
$border-color_14: $space-grey-color;
$border-color_15: $light-royal-blue-color;
$border-color_16: transparent transparent $space-grey-color transparent;
$border-color_17: rgba(255, 255, 255, 0);
$border-color_18: 1px dashed $dark-blue-color;
$border-top-color_1: $white-color;
$border-top-color_2: $text-gray-color;
$border-right-color_1: $text-gray-color;
$border-bottom-color_1: $white-color;
$border-bottom-color_2: $table-gray-color;
$border-bottom-color_3: $text-gray-color;
$border-left-color_1: $text-gray-color;
$outline-color_1: transparent;
$outline-color_2: $focus-blue-color;

@keyframes p-component-overlay-enter-animation {
  from {
    background-color: transparent;
  }
  to {
    background-color: $transparent-black-color;
  }
}
@keyframes p-component-overlay-leave-animation {
  from {
    background-color: $transparent-black-color;
  }
  to {
    background-color: transparent;
  }
}
.custom-icon-tr-TR {
  width: 1rem;
  height: 1rem;
  background-image: url("../img/flags/tr-TR.svg");
  background-repeat: round;
}

.custom-icon-es-ES {
  width: 1rem;
  height: 1rem;
  background-image: url("../img/flags/es-ES.svg");
  background-repeat: round;
}
.custom-icon-en-US {
  width: 1rem;
  height: 1rem;
  background-image: url("../img/flags/en-US.svg");
  background-repeat: round;
}
.custom-icon-de-DE {
  width: 1rem;
  height: 1rem;
  background-image: url("../img/flags/de-DE.svg");
  background-repeat: round;
}
.custom-icon-fa-IR {
  width: 1rem;
  height: 1rem;
  background-image: url("../img/flags/fa-IR.svg");
  background-repeat: round;
}
.custom-icon-fr-FR {
  width: 1rem;
  height: 1rem;
  background-image: url("../img/flags/fr-FR.svg");
  background-repeat: round;
}
.custom-icon-it-IT {
  width: 1rem;
  height: 1rem;
  background-image: url("../img/flags/it-IT.svg");
  background-repeat: round;
}
.custom-icon-ko-KR {
  width: 1rem;
  height: 1rem;
  background-image: url("../img/flags/ko-KR.svg");
  background-repeat: round;
}
.custom-icon-ro-RO {
  width: 1rem;
  height: 1rem;
  background-image: url("../img/flags/ro-RO.svg");
  background-repeat: round;
}
.custom-icon-ru-RU {
  width: 1rem;
  height: 1rem;
  background-image: url("../img/flags/ru-RU.svg");
  background-repeat: round;
}
.custom-icon-sk-SK {
  width: 1rem;
  height: 1rem;
  background-image: url("../img/flags/sk-SK.svg");
  background-repeat: round;
}
.custom-icon-zh-CN {
  width: 1rem;
  height: 1rem;
  background-image: url("../img/flags/zh-CN.svg");
  background-repeat: round;
}
.custom-icon-pt-PT {
  width: 1rem;
  height: 1rem;
  background-image: url("../img/flags/pt-PT.svg");
  background-repeat: round;
}
.custom-icon-add-columns {
  width: 1rem;
  height: 1rem;
  background-image: url("../img/custom-icons/Reorder.svg");
  background-repeat: round;
}

.p-editor-container {
  .p-editor-toolbar {
    background: $light-blue-color;
    border-radius: 6px;
  }
  .p-editor-toolbar.ql-snow {
    border: 1px solid $space-grey-color;
    .ql-stroke {
      stroke: $single-dark-gray-color;
    }
    .ql-fill {
      fill: $single-dark-gray-color;
    }
    .ql-picker {
      .ql-picker-label {
        border: 0 none;
        color: $color_1;
        &:hover {
          color: $color_2;
          .ql-stroke {
            stroke: $text-gray-color;
          }
          .ql-fill {
            fill: $text-gray-color;
          }
        }
      }
    }
    .ql-picker.ql-expanded {
      .ql-picker-label {
        color: $color_2;
        .ql-stroke {
          stroke: $text-gray-color;
        }
        .ql-fill {
          fill: $text-gray-color;
        }
      }
      .ql-picker-options {
        background: $white-color;
        border: 0 none;
        box-shadow: 0 2px 4px -1px rgba(0, 0, 0, 0.2),
          0 4px 5px 0 rgba(0, 0, 0, 0.14), 0 1px 10px 0 rgba(0, 0, 0, 0.12);
        border-radius: 6px;
        padding: 0.5rem 0;
        .ql-picker-item {
          color: $color_2;
          &:hover {
            color: $color_2;
            background: $white-color;
          }
        }
      }
      &:not(.ql-icon-picker) {
        .ql-picker-item {
          padding: 0.5rem 1rem;
        }
      }
    }
  }
  .p-editor-content {
    border-bottom-right-radius: 6px;
    border-bottom-left-radius: 6px;
    .ql-editor {
      background: $white-color;
      color: $color_2;
      border-bottom-right-radius: 6px;
      border-bottom-left-radius: 6px;
    }
  }
  .p-editor-content.ql-snow {
    border: 1px solid $space-grey-color;
  }
  .ql-snow.ql-toolbar {
    button {
      &:hover {
        color: $color_2;
        .ql-stroke {
          stroke: $text-gray-color;
        }
        .ql-fill {
          fill: $text-gray-color;
        }
      }
      &:focus {
        color: $color_2;
        .ql-stroke {
          stroke: $text-gray-color;
        }
        .ql-fill {
          fill: $text-gray-color;
        }
      }
    }
    button.ql-active {
      color: $color_3;
      .ql-stroke {
        stroke: $royal-blue-color;
      }
      .ql-fill {
        fill: $royal-blue-color;
      }
      .ql-picker-label {
        color: $color_3;
      }
    }
    .ql-picker-label.ql-active {
      color: $color_3;
      .ql-stroke {
        stroke: $royal-blue-color;
      }
      .ql-fill {
        fill: $royal-blue-color;
      }
      .ql-picker-label {
        color: $color_3;
      }
    }
    .ql-picker-item.ql-selected {
      color: $color_3;
      .ql-stroke {
        stroke: $royal-blue-color;
      }
      .ql-fill {
        fill: $royal-blue-color;
      }
      .ql-picker-label {
        color: $color_3;
      }
    }
  }
}
@layer {
  primeng {
    * {
      box-sizing: border-box;
    }
  }
  .p-component {
    font-family: $font-family_2;
    font-feature-settings: $font-family_1;
    font-size: 1rem;
    font-weight: normal;
    &:disabled {
      opacity: 0.6;
    }
  }
  .p-component-overlay {
    background-color: $background-color_1;
    transition-duration: 0.2s;
  }
  .p-disabled {
    opacity: 0.6;
  }
  .p-error {
    color: $color_4;
  }
  .p-text-secondary {
    color: $color_1;
  }
  .pi {
    font-size: 1rem;
  }
  .p-icon {
    width: 1rem;
    height: 1rem;
  }
  .p-link {
    font-family: $font-family_2;
    font-feature-settings: $font-family_1;
    font-size: 1rem;
    border-radius: 6px;
    &:focus-visible {
      outline: 0 none;
      outline-offset: 0;
      box-shadow: $focus-blue-color;
    }
  }
  .p-component-overlay-enter {
    animation: p-component-overlay-enter-animation 150ms forwards;
  }
  .p-component-overlay-leave {
    animation: p-component-overlay-leave-animation 150ms forwards;
  }
  .p-autocomplete {
    .p-autocomplete-loader {
      right: 0.5rem;
    }
    &:not(.p-disabled) {
      &:hover {
        .p-autocomplete-multiple-container {
          border-color: $border-color_1;
        }
      }
    }
    &:not(.p-disabled).p-focus {
      .p-autocomplete-multiple-container {
        outline: 0 none;
        outline-offset: 0;
        box-shadow: 0 0 0 0.2rem $focus-blue-color;
        border-color: $border-color_1;
      }
    }
    .p-autocomplete-multiple-container {
      padding: 0.25rem 0.5rem;
      gap: 0.5rem;
      .p-autocomplete-input-token {
        padding: 0.25rem 0;
        input {
          font-family: $font-family_2;
          font-feature-settings: $font-family_1;
          font-size: 1rem;
          color: $color_2;
          padding: 0;
          margin: 0;
        }
      }
      .p-autocomplete-token {
        padding: 0.25rem 0.5rem;
        background: $space-grey-color;
        color: $color_2;
        border-radius: 16px;
        .p-autocomplete-token-icon {
          margin-left: 0.5rem;
        }
      }
      .p-autocomplete-token.p-focus {
        background: $border-light-gray-color;
        color: $color_2;
      }
    }
  }
  .p-autocomplete.p-autocomplete-dd {
    .p-autocomplete-loader {
      right: 2.857rem;
    }
  }
  .p-autocomplete.p-invalid.p-component {
    > .p-inputtext {
      border-color: $border-color_2;
    }
  }
  .p-autocomplete-panel {
    background: $white-color;
    color: $color_2;
    border: 0 none;
    border-radius: 6px;
    box-shadow: 0 2px 4px -1px rgba(0, 0, 0, 0.2),
      0 4px 5px 0 rgba(0, 0, 0, 0.14), 0 1px 10px 0 rgba(0, 0, 0, 0.12);
    .p-autocomplete-items {
      padding: 0.5rem 0;
      .p-autocomplete-item {
        margin: 0;
        padding: 0.5rem 1rem;
        border: 0 none;
        color: $color_2;
        background: transparent;
        transition: box-shadow 0.2s;
        border-radius: 0;
        &:not(.p-highlight) {
          &:not(.p-disabled).p-focus {
            color: $color_2;
            background: $space-grey-color;
          }
          &:not(.p-disabled) {
            &:hover {
              color: $color_2;
              background: $background-color_5;
            }
          }
        }
      }
      .p-autocomplete-item.p-highlight {
        color: $color_2;
        background: $white-color;
      }
      .p-autocomplete-item.p-highlight.p-focus {
        background: rgba(33, 150, 243, 0.24);
      }
      .p-autocomplete-item-group {
        margin: 0;
        padding: 0.75rem 1rem;
        color: $color_2;
        background: $white-color;
        font-weight: 600;
      }
      .p-autocomplete-empty-message {
        padding: 0.5rem 1rem;
        color: $color_2;
        background: transparent;
      }
    }
  }
  p-autocomplete.ng-dirty.ng-invalid {
    > .p-autocomplete {
      > .p-inputtext {
        border-color: $border-color_2;
      }
    }
  }
  p-autocomplete.p-autocomplete-clearable {
    .p-inputtext {
      padding-right: 2rem;
    }
    .p-autocomplete-clear-icon {
      color: $color_1;
      right: 0.5rem;
    }
    .p-autocomplete-dd {
      .p-autocomplete-clear-icon {
        color: $color_1;
        right: 2.857rem;
      }
    }
  }
  p-calendar.ng-dirty.ng-invalid {
    > .p-calendar {
      > .p-inputtext {
        border-color: $border-color_2;
      }
    }
  }
  .p-calendar {
    &:not(.p-calendar-disabled).p-focus {
      > .p-inputtext {
        outline: 0 none;
        outline-offset: 0;
        box-shadow: 0 0 0 0.2rem $focus-blue-color;
        border-color: $border-color_1;
      }
    }
  }
  .p-datepicker {
    padding: 0.5rem;
    background: $white-color;
    color: $color_2;
    border: 1px solid $border-light-gray-color;
    border-radius: 6px;
    &:not(.p-datepicker-inline) {
      background: $white-color;
      border: 0 none;
      box-shadow: 0 2px 4px -1px rgba(0, 0, 0, 0.2),
        0 4px 5px 0 rgba(0, 0, 0, 0.14), 0 1px 10px 0 rgba(0, 0, 0, 0.12);
      .p-datepicker-header {
        background: $white-color;
      }
    }
    .p-datepicker-header {
      padding: 0.5rem;
      color: $color_2;
      background: $white-color;
      font-weight: 600;
      margin: 0;
      border-bottom: 1px solid $space-grey-color;
      border-top-right-radius: 6px;
      border-top-left-radius: 6px;
      .p-datepicker-prev {
        width: 2rem;
        height: 2rem;
        color: $color_1;
        border: 0 none;
        background: transparent;
        border-radius: 50%;
        transition: background-color 0.2s, color 0.2s, box-shadow 0.2s;
        &:enabled {
          &:hover {
            color: $color_2;
            border-color: $border-color_3;
            background: $background-color_5;
          }
        }
        &:focus-visible {
          outline: 0 none;
          outline-offset: 0;
          box-shadow: 0 0 0 0.2rem $focus-blue-color;
        }
      }
      .p-datepicker-next {
        width: 2rem;
        height: 2rem;
        color: $color_1;
        border: 0 none;
        background: transparent;
        border-radius: 50%;
        transition: background-color 0.2s, color 0.2s, box-shadow 0.2s;
        &:enabled {
          &:hover {
            color: $color_2;
            border-color: $border-color_3;
            background: $background-color_5;
          }
        }
        &:focus-visible {
          outline: 0 none;
          outline-offset: 0;
          box-shadow: 0 0 0 0.2rem $focus-blue-color;
        }
      }
      .p-datepicker-title {
        line-height: 2rem;
        .p-datepicker-year {
          color: $color_2;
          transition: background-color 0.2s, color 0.2s, box-shadow 0.2s;
          font-weight: 600;
          padding: 0.5rem;
          &:enabled {
            &:hover {
              color: $color_5;
            }
          }
        }
        .p-datepicker-month {
          color: $color_2;
          transition: background-color 0.2s, color 0.2s, box-shadow 0.2s;
          font-weight: 600;
          padding: 0.5rem;
          margin-right: 0.5rem;
          &:enabled {
            &:hover {
              color: $color_5;
            }
          }
        }
      }
    }
    table {
      font-size: 1rem;
      margin: 0.5rem 0;
      th {
        padding: 0.5rem;
        > span {
          width: 2.5rem;
          height: 2.5rem;
        }
      }
      td {
        padding: 0.5rem;
        > span {
          width: 2.5rem;
          height: 2.5rem;
          border-radius: 50%;
          transition: box-shadow 0.2s;
          border: 1px solid transparent;
          &:focus {
            outline: 0 none;
            outline-offset: 0;
            box-shadow: 0 0 0 0.2rem $focus-blue-color;
          }
        }
        > span.p-highlight {
          color: $color_2;
          background: $white-color;
        }
      }
      td.p-datepicker-today {
        > span {
          background: $border-light-gray-color;
          color: $color_2;
          border-color: $border-color_3;
        }
        > span.p-highlight {
          color: $color_2;
          background: $white-color;
        }
      }
    }
    .p-datepicker-buttonbar {
      padding: 1rem 0;
      border-top: 1px solid $space-grey-color;
      .p-button {
        width: auto;
      }
    }
    .p-timepicker {
      border-top: 1px solid $space-grey-color;
      padding: 0.5rem;
      button {
        width: 2rem;
        height: 2rem;
        color: $color_1;
        border: 0 none;
        background: transparent;
        border-radius: 50%;
        transition: background-color 0.2s, color 0.2s, box-shadow 0.2s;
        &:enabled {
          &:hover {
            color: $color_2;
            border-color: $border-color_3;
            background: $background-color_5;
          }
        }
        &:focus-visible {
          outline: 0 none;
          outline-offset: 0;
          box-shadow: 0 0 0 0.2rem $focus-blue-color;
        }
        &:last-child {
          margin-top: 0.2em;
        }
      }
      span {
        font-size: 1.25rem;
      }
      > div {
        padding: 0 0.5rem;
      }
    }
    .p-monthpicker {
      margin: 0.5rem 0;
      .p-monthpicker-month {
        padding: 0.5rem;
        transition: box-shadow 0.2s;
        border-radius: 6px;
      }
      .p-monthpicker-month.p-highlight {
        color: $color_2;
        background: $white-color;
      }
    }
    .p-yearpicker {
      margin: 0.5rem 0;
      .p-yearpicker-year {
        padding: 0.5rem;
        transition: box-shadow 0.2s;
        border-radius: 6px;
      }
      .p-yearpicker-year.p-highlight {
        color: $color_2;
        background: $white-color;
      }
    }
    &:not(.p-disabled) {
      table {
        td {
          span {
            &:not(.p-highlight) {
              &:not(.p-disabled) {
                &:hover {
                  background: $background-color_5;
                }
                &:focus {
                  outline: 0 none;
                  outline-offset: 0;
                  box-shadow: 0 0 0 0.2rem $focus-blue-color;
                }
              }
            }
          }
        }
      }
      .p-monthpicker {
        .p-monthpicker-month {
          &:not(.p-disabled) {
            &:not(.p-highlight) {
              &:hover {
                background: $background-color_5;
              }
            }
            &:focus {
              outline: 0 none;
              outline-offset: 0;
              box-shadow: 0 0 0 0.2rem $focus-blue-color;
            }
          }
        }
      }
      .p-yearpicker {
        .p-yearpicker-year {
          &:not(.p-disabled) {
            &:not(.p-highlight) {
              &:hover {
                background: $background-color_5;
              }
            }
            &:focus {
              outline: 0 none;
              outline-offset: 0;
              box-shadow: 0 0 0 0.2rem $focus-blue-color;
            }
          }
        }
      }
    }
  }
  .p-datepicker.p-datepicker-timeonly {
    .p-timepicker {
      border-top: 0 none;
    }
  }
  .p-datepicker.p-datepicker-multiple-month {
    .p-datepicker-group {
      border-left: 1px solid $space-grey-color;
      padding-right: 0.5rem;
      padding-left: 0.5rem;
      padding-top: 0;
      padding-bottom: 0;
      &:first-child {
        padding-left: 0;
        border-left: 0 none;
      }
      &:last-child {
        padding-right: 0;
      }
    }
  }
  p-calendar.p-calendar-clearable {
    .p-inputtext {
      padding-right: 2rem;
    }
    .p-calendar-clear-icon {
      color: $color_1;
      right: 0.5rem;
    }
    .p-calendar-w-btn {
      .p-calendar-clear-icon {
        color: $color_1;
        right: 2.857rem;
      }
    }
  }
  .p-cascadeselect {
    background: $white-color;
    border: 1px solid $border-light-gray-color;
    transition: background-color 0.2s, color 0.2s, border-color 0.2s,
      box-shadow 0.2s;
    border-radius: 6px;
    &:not(.p-disabled) {
      &:hover {
        border-color: $border-color_1;
      }
    }
    &:not(.p-disabled).p-focus {
      outline: 0 none;
      outline-offset: 0;
      box-shadow: 0 0 0 0.2rem $focus-blue-color;
      border-color: $border-color_1;
    }
    .p-cascadeselect-label {
      background: transparent;
      border: 0 none;
      padding: 0.5rem 0.5rem;
      &:enabled {
        &:focus {
          outline: 0 none;
          box-shadow: none;
        }
      }
    }
    .p-cascadeselect-label.p-placeholder {
      color: $color_1;
    }
    .p-cascadeselect-trigger {
      background: transparent;
      color: $color_1;
      width: 2.357rem;
      border-top-right-radius: 6px;
      border-bottom-right-radius: 6px;
    }
  }
  .p-cascadeselect.p-invalid.p-component {
    border-color: $border-color_2;
  }
  .p-cascadeselect-panel {
    background: $white-color;
    color: $color_2;
    border: 0 none;
    border-radius: 6px;
    box-shadow: 0 2px 4px -1px rgba(0, 0, 0, 0.2),
      0 4px 5px 0 rgba(0, 0, 0, 0.14), 0 1px 10px 0 rgba(0, 0, 0, 0.12);
    .p-cascadeselect-items {
      padding: 0.5rem 0;
      .p-cascadeselect-item {
        margin: 0;
        border: 0 none;
        color: $color_2;
        background: transparent;
        transition: box-shadow 0.2s;
        border-radius: 0;
        &:not(.p-highlight) {
          &:not(.p-disabled).p-focus {
            color: $color_2;
            background: $space-grey-color;
          }
          &:not(.p-disabled) {
            &:hover {
              color: $color_2;
              background: $background-color_5;
            }
          }
        }
        .p-cascadeselect-item-content {
          padding: 0.5rem 1rem;
        }
        .p-cascadeselect-group-icon {
          font-size: 0.875rem;
        }
      }
      .p-cascadeselect-item.p-highlight {
        color: $color_2;
        background: $white-color;
      }
      .p-cascadeselect-item.p-highlight.p-focus {
        background: rgba(33, 150, 243, 0.24);
      }
    }
  }
  .p-input-filled {
    .p-cascadeselect {
      background: $light-grey-color;
      &:not(.p-disabled) {
        &:hover {
          background-color: $background-color_2;
        }
      }
      &:not(.p-disabled).p-focus {
        background-color: $background-color_3;
      }
    }
    .p-checkbox {
      .p-checkbox-box {
        background-color: $background-color_2;
      }
      .p-checkbox-box.p-highlight {
        background: $dark-blue-color;
      }
      &:not(.p-checkbox-disabled) {
        .p-checkbox-box {
          &:hover {
            background-color: $background-color_2;
          }
        }
        .p-checkbox-box.p-highlight {
          &:hover {
            background: $royal-blue-color;
          }
        }
      }
    }
    .p-dropdown {
      background: $light-grey-color;
      &:not(.p-disabled) {
        &:hover {
          background-color: $background-color_2;
        }
      }
      &:not(.p-disabled).p-focus {
        background-color: $background-color_3;
        .p-inputtext {
          background-color: $background-color_4;
        }
      }
    }
    .p-inputtext {
      background-color: $background-color_2;
      &:enabled {
        &:hover {
          background-color: $background-color_2;
        }
        &:focus {
          background-color: $background-color_3;
        }
      }
    }
    .p-multiselect {
      background: $light-grey-color;
      &:not(.p-disabled) {
        &:hover {
          background-color: $background-color_2;
        }
      }
      &:not(.p-disabled).p-focus {
        background-color: $background-color_3;
      }
    }
    .p-radiobutton {
      .p-radiobutton-box {
        background-color: $background-color_2;
        &:not(.p-disabled) {
          &:hover {
            background-color: $background-color_2;
          }
        }
      }
      .p-radiobutton-box.p-highlight {
        background: $dark-blue-color;
        &:not(.p-disabled) {
          &:hover {
            background: $royal-blue-color;
          }
        }
      }
    }
    .p-treeselect {
      background: $light-grey-color;
      &:not(.p-disabled) {
        &:hover {
          background-color: $background-color_2;
        }
      }
      &:not(.p-disabled).p-focus {
        background-color: $background-color_3;
      }
    }
  }
  p-cascadeselect.ng-dirty.ng-invalid {
    > .p-cascadeselect {
      border-color: $border-color_2;
    }
  }
  p-cascadeselect.p-cascadeselect-clearable {
    .p-cascadeselect-label {
      padding-right: 0.5rem;
    }
    .p-cascadeselect-clear-icon {
      color: $color_1;
      right: 2.357rem;
    }
  }
  .p-overlay-modal {
    .p-cascadeselect-sublist {
      .p-cascadeselect-panel {
        box-shadow: none;
        border-radius: 0;
        padding: 0.25rem 0 0.25rem 0.5rem;
      }
    }
    .p-cascadeselect-item-active {
      > .p-cascadeselect-item-content {
        .p-cascadeselect-group-icon {
          transform: rotate(90deg);
        }
      }
    }
  }
  .p-checkbox {
    width: 20px;
    height: 20px;
    .p-checkbox-box {
      border: 2px solid $border-light-gray-color;
      background: $white-color;
      width: 20px;
      height: 20px;
      color: $color_2;
      border-radius: 6px;
      transition: background-color 0.2s, color 0.2s, border-color 0.2s,
        box-shadow 0.2s;
      .p-checkbox-icon {
        transition-duration: 0.2s;
        color: $color_6;
        font-size: 14px;
      }
      .p-icon {
        width: 14px;
        height: 14px;
      }
    }
    .p-checkbox-box.p-highlight {
      border-color: $border-color_1;
      background: $dark-blue-color;
    }
    &:not(.p-checkbox-disabled) {
      .p-checkbox-box {
        &:hover {
          border-color: $border-color_1;
        }
      }
      .p-checkbox-box.p-focus {
        outline: 0 none;
        outline-offset: 0;
        box-shadow: 0 0 0 0.2rem $focus-blue-color;
        border-color: $border-color_1;
      }
      .p-checkbox-box.p-highlight {
        &:hover {
          border-color: $border-color_4;
          background: $royal-blue-color;
          color: $color_6;
        }
      }
    }
  }
  p-checkbox.ng-dirty.ng-invalid {
    > .p-checkbox {
      > .p-checkbox-box {
        border-color: $border-color_2;
      }
    }
  }
  .p-checkbox-label {
    margin-left: 0.5rem;
  }
  p-tristatecheckbox.ng-dirty.ng-invalid {
    > .p-checkbox {
      > .p-checkbox-box {
        border-color: $border-color_2;
      }
    }
  }
  .p-chips {
    &:not(.p-disabled) {
      &:hover {
        .p-chips-multiple-container {
          border-color: $border-color_1;
        }
      }
    }
    &:not(.p-disabled).p-focus {
      .p-chips-multiple-container {
        outline: 0 none;
        outline-offset: 0;
        box-shadow: 0 0 0 0.2rem $focus-blue-color;
        border-color: $border-color_1;
      }
    }
    .p-chips-multiple-container {
      padding: 0.25rem 0.5rem;
      gap: 0.5rem;
      .p-chips-token {
        padding: 0.25rem 0.5rem;
        margin-right: 0.5rem;
        background: $space-grey-color;
        color: $color_2;
        border-radius: 16px;
        .p-chips-token-icon {
          margin-left: 0.5rem;
        }
      }
      .p-chips-token.p-focus {
        background: $border-light-gray-color;
        color: $color_2;
      }
      .p-chips-input-token {
        padding: 0.25rem 0;
        input {
          font-family: $font-family_2;
          font-feature-settings: $font-family_1;
          font-size: 1rem;
          color: $color_2;
          padding: 0;
          margin: 0;
        }
      }
    }
  }
  p-chips.ng-dirty.ng-invalid {
    > .p-chips {
      > .p-inputtext {
        border-color: $border-color_2;
      }
    }
  }
  p-chips.p-chips-clearable {
    .p-inputtext {
      padding-right: 1.5rem;
    }
    .p-chips-clear-icon {
      color: $color_1;
      right: 0.5rem;
    }
  }
  .p-colorpicker-preview {
    width: 2rem;
    height: 2rem;
  }
  .p-fluid {
    .p-colorpicker-preview.p-inputtext {
      width: 2rem;
      height: 2rem;
    }
    .p-inputgroup {
      .p-button {
        width: auto;
      }
      .p-button.p-button-icon-only {
        width: 2.357rem;
      }
    }
    .p-button {
      width: 100%;
    }
    .p-button-icon-only {
      width: 2.357rem;
    }
    .p-buttonset {
      display: flex;
      .p-button {
        flex: 1;
      }
    }
  }
  .p-colorpicker-panel {
    background: $color_20;
    border: 1px solid$color_20;
    .p-colorpicker-color-handle {
      border-color: $border-color_5;
    }
    .p-colorpicker-hue-handle {
      border-color: $border-color_5;
    }
  }
  .p-colorpicker-overlay-panel {
    box-shadow: 0 2px 4px -1px rgba(0, 0, 0, 0.2),
      0 4px 5px 0 rgba(0, 0, 0, 0.14), 0 1px 10px 0 rgba(0, 0, 0, 0.12);
  }
  .p-dropdown {
    background: $white-color;
    border: 1px solid $border-light-gray-color;
    transition: background-color 0.2s, color 0.2s, border-color 0.2s,
      box-shadow 0.2s;
    border-radius: 6px;
    &:not(.p-disabled) {
      &:hover {
        border-color: $border-color_1;
      }
    }
    &:not(.p-disabled).p-focus {
      outline: 0 none;
      outline-offset: 0;
      box-shadow: 0 0 0 0.2rem $focus-blue-color;
      border-color: $border-color_1;
    }
    .p-dropdown-label {
      background: transparent;
      border: 0 none;
      &:focus {
        outline: 0 none;
        box-shadow: none;
      }
      &:enabled {
        &:focus {
          outline: 0 none;
          box-shadow: none;
        }
      }
    }
    .p-dropdown-label.p-placeholder {
      color: $color_1;
    }
    .p-dropdown-trigger {
      background: transparent;
      color: $color_1;
      width: 2.357rem;
      border-radius: 6px;
    }
    .p-dropdown-clear-icon {
      color: $color_1;
      right: 2.357rem;
    }
  }
  .p-dropdown.p-dropdown-clearable {
    .p-dropdown-label {
      padding-right: 1.5rem;
    }
  }
  .p-dropdown.p-invalid.p-component {
    border-color: $border-color_2;
  }
  .p-dropdown-panel {
    background: $white-color;
    color: $color_2;
    border: 0 none;
    border-radius: 6px;
    box-shadow: 0 2px 4px -1px rgba(0, 0, 0, 0.2),
      0 4px 5px 0 rgba(0, 0, 0, 0.14), 0 1px 10px 0 rgba(0, 0, 0, 0.12);
    .p-dropdown-header {
      padding: 0.5rem 1rem;
      border-bottom: 0 none;
      color: $color_2;
      background: $light-grey-color;
      margin: 0;
      border-radius: 6px;
      .p-dropdown-filter {
        padding-right: 1.5rem;
        margin-right: -1.5rem;
      }
      .p-dropdown-filter-icon {
        right: 0.5rem;
        color: $color_1;
      }
    }
    .p-dropdown-items {
      padding: 0.5rem 0;
      .p-dropdown-item {
        margin: 0;
        padding: 0.5rem 1rem;
        border: 0 none;
        color: $color_2;
        background: transparent;
        transition: box-shadow 0.2s;
        border-radius: 0;
        &:not(.p-highlight) {
          &:not(.p-disabled).p-focus {
            color: $color_2;
            background: $space-grey-color;
          }
          &:not(.p-disabled) {
            &:hover {
              color: $color_2;
              background: $background-color_5;
            }
          }
        }
      }
      .p-dropdown-item.p-highlight {
        color: $color_2;
        background: $white-color;
      }
      .p-dropdown-item.p-highlight.p-focus {
        background: rgba(33, 150, 243, 0.24);
      }
      .p-dropdown-item-group {
        margin: 0;
        padding: 0.75rem 1rem;
        color: $color_2;
        background: $white-color;
        font-weight: 600;
      }
      .p-dropdown-empty-message {
        padding: 0.5rem 1rem;
        color: $color_2;
        background: transparent;
      }
    }
  }
  p-dropdown.ng-dirty.ng-invalid {
    > .p-dropdown {
      border-color: $border-color_2;
    }
  }
  .p-inputgroup-addon {
    background: $background-color_5;
    color: $color_1;
    border-top: 1px solid $border-light-gray-color;
    border-left: 1px solid $border-light-gray-color;
    border-bottom: 1px solid $border-light-gray-color;
    padding: 0.5rem 0.5rem;
    min-width: 2.357rem;
    &:last-child {
      border-right: 1px solid $border-light-gray-color;
      border-top-right-radius: 6px;
      border-bottom-right-radius: 6px;
    }
    &:first-child {
      border-top-left-radius: 6px;
      border-bottom-left-radius: 6px;
    }
  }
  .p-inputgroup {
    > .p-component {
      border-radius: 0;
      margin: 0;
      + {
        .p-inputgroup-addon {
          border-left: 0 none;
        }
      }
      &:focus {
        z-index: 1;
        ~ {
          label {
            z-index: 1;
          }
        }
      }
    }
    > .p-inputwrapper {
      > .p-inputtext {
        border-radius: 0;
        margin: 0;
        + {
          .p-inputgroup-addon {
            border-left: 0 none;
          }
        }
        &:focus {
          z-index: 1;
          ~ {
            label {
              z-index: 1;
            }
          }
        }
      }
      &:first-child {
        > .p-component {
          border-top-left-radius: 6px;
          border-bottom-left-radius: 6px;
          > .p-inputtext {
            border-top-left-radius: 6px;
            border-bottom-left-radius: 6px;
          }
        }
      }
      &:last-child {
        > .p-component {
          border-top-right-radius: 6px;
          border-bottom-right-radius: 6px;
          > .p-inputtext {
            border-top-right-radius: 6px;
            border-bottom-right-radius: 6px;
          }
        }
      }
    }
    > .p-float-label {
      > .p-component {
        border-radius: 0;
        margin: 0;
        + {
          .p-inputgroup-addon {
            border-left: 0 none;
          }
        }
        &:focus {
          z-index: 1;
          ~ {
            label {
              z-index: 1;
            }
          }
        }
      }
    }
    button {
      &:first-child {
        border-top-left-radius: 6px;
        border-bottom-left-radius: 6px;
      }
      &:last-child {
        border-top-right-radius: 6px;
        border-bottom-right-radius: 6px;
      }
    }
    input {
      &:first-child {
        border-top-left-radius: 6px;
        border-bottom-left-radius: 6px;
      }
      &:last-child {
        border-top-right-radius: 6px;
        border-bottom-right-radius: 6px;
      }
    }
    .p-float-label {
      &:first-child {
        input {
          border-top-left-radius: 6px;
          border-bottom-left-radius: 6px;
        }
      }
      &:last-child {
        input {
          border-top-right-radius: 6px;
          border-bottom-right-radius: 6px;
        }
      }
    }
  }
  p-inputmask.ng-dirty.ng-invalid {
    > .p-inputtext {
      border-color: $border-color_2;
    }
  }
  p-inputmask.p-inputmask-clearable {
    .p-inputtext {
      padding-right: 2rem;
    }
    .p-inputmask-clear-icon {
      color: $color_1;
      right: 0.5rem;
    }
  }
  p-inputnumber.ng-dirty.ng-invalid {
    > .p-inputnumber {
      > .p-inputtext {
        border-color: $border-color_2;
      }
    }
  }
  p-inputnumber.p-inputnumber-clearable {
    .p-inputnumber-input {
      padding-right: 2rem;
    }
    .p-inputnumber-clear-icon {
      color: $color_1;
      right: 0.5rem;
    }
    .p-inputnumber-buttons-stacked {
      .p-inputnumber-clear-icon {
        right: 2.857rem;
      }
    }
    .p-inputnumber-buttons-horizontal {
      .p-inputnumber-clear-icon {
        right: 2.857rem;
      }
    }
  }
  .p-inputswitch {
    width: 3rem;
    height: 1.75rem;
    .p-inputswitch-slider {
      background: $border-light-gray-color;
      transition: background-color 0.2s, color 0.2s, border-color 0.2s,
        box-shadow 0.2s;
      border-radius: 30px;
      &:before {
        background: $white-color;
        width: 1.25rem;
        height: 1.25rem;
        left: 0.25rem;
        margin-top: -0.625rem;
        border-radius: 50%;
        transition-duration: 0.2s;
      }
    }
    &:not(.p-disabled) {
      &:hover {
        .p-inputswitch-slider {
          background: $color_21;
        }
      }
    }
  }
  .p-inputswitch.p-inputswitch-checked {
    .p-inputswitch-slider {
      &:before {
        transform: translateX(1.25rem);
        background: $white-color;
      }
      background: $dark-blue-color;
    }
    &:not(.p-disabled) {
      &:hover {
        .p-inputswitch-slider {
          background: $royal-blue-color;
        }
      }
    }
  }
  .p-inputswitch.p-focus {
    .p-inputswitch-slider {
      outline: 0 none;
      outline-offset: 0;
      box-shadow: 0 0 0 0.2rem $focus-blue-color;
    }
  }
  p-inputswitch.ng-dirty.ng-invalid {
    > .p-inputswitch {
      > .p-inputswitch-slider {
        border-color: $border-color_2;
      }
    }
  }
  .p-inputtext {
    font-family: $font-family_2;
    font-feature-settings: $font-family_1;
    font-size: 1rem;
    color: $color_2;
    background: $white-color;
    padding: 0.5rem 0.5rem;
    border: 1px solid $border-light-gray-color;
    transition: background-color 0.2s, color 0.2s, border-color 0.2s,
      box-shadow 0.2s;
    appearance: none;
    border-radius: 6px;
    &:enabled {
      &:hover {
        border-color: $border-color_1;
      }
      &:focus {
        outline: 0 none;
        outline-offset: 0;
        box-shadow: 0 0 0 0.2rem $focus-blue-color;
        border-color: $border-color_1;
      }
    }
  }
  .p-inputtext.ng-dirty.ng-invalid {
    border-color: $border-color_2;
  }
  .p-inputtext.p-inputtext-sm {
    font-size: 0.875rem;
    padding: 0.4375rem 0.4375rem;
  }
  .p-inputtext.p-inputtext-lg {
    font-size: 1.25rem;
    padding: 0.625rem 0.625rem;
  }
  .p-float-label {
    > label {
      left: 0.5rem;
      color: $color_1;
      transition-duration: 0.2s;
    }
    > .ng-invalid.ng-dirty {
      + {
        label {
          color: $color_4;
        }
      }
    }
  }
  .text-area {
    .p-input-icon-left {
      > .p-icon-wrapper.p-icon {
        left: 0.5rem;
        color: $color_1;
      }
      > i {
        &:first-of-type {
          color: $color_1;
        }
      }
    }
  }
  .p-input-icon-left {
    > .p-icon-wrapper.p-icon {
      left: 0.5rem;
      color: $color_1;
    }
    > i {
      &:first-of-type {
        left: 0.5rem;
        color: $color_1;
      }
    }
    > .p-inputtext {
      padding-left: 2rem;
    }
  }
  .p-input-icon-left.p-float-label {
    > label {
      left: 2rem;
    }
  }
  .p-input-icon-right {
    > .p-icon-wrapper {
      right: 0.5rem;
      color: $color_1;
    }
    > i {
      &:last-of-type {
        right: 0.5rem;
        color: $color_1;
      }
    }
    > .p-inputtext {
      padding-right: 2rem;
    }
  }
  ::-webkit-input-placeholder {
    color: $color_1;
  }

  ::-webkit-scrollbar {
    width: 6px;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    background-color: $white-color;
    opacity: 0.5;
    border-radius: 6px;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: $single-light-gray-color;
    border-radius: 6px;
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: $single-light-gray-color;
  }
  :-moz-placeholder {
    color: $color_1;
  }
  ::-moz-placeholder {
    color: $color_1;
  }
  :-ms-input-placeholder {
    color: $color_1;
  }
  .p-inputtext-sm {
    .p-inputtext {
      font-size: 0.875rem;
      padding: 0.4375rem 0.4375rem;
    }
  }
  .p-inputtext-lg {
    .p-inputtext {
      font-size: 1.25rem;
      padding: 0.625rem 0.625rem;
    }
  }
  .p-listbox {
    background: $white-color;
    color: $color_2;
    border: 1px solid $border-light-gray-color;
    border-radius: 6px;
    transition: background-color 0.2s, color 0.2s, border-color 0.2s,
      box-shadow 0.2s;
    .p-listbox-header {
      padding: 0.5rem 1rem;
      border-bottom: 0 none;
      color: $color_2;
      background: $light-grey-color;
      margin: 0;
      border-top-right-radius: 6px;
      border-top-left-radius: 6px;
      .p-listbox-filter {
        padding-right: 1.5rem;
      }
      .p-listbox-filter-icon {
        right: 0.5rem;
        color: $color_1;
      }
      .p-checkbox {
        margin-right: 0.5rem;
      }
    }
    .p-listbox-list {
      padding: 0.5rem 0;
      outline: 0 none;
      .p-listbox-item {
        margin: 0;
        padding: 0.5rem 1rem;
        border: 0 none;
        color: $color_2;
        transition: box-shadow 0.2s;
        border-radius: 0;
        .p-checkbox {
          margin-right: 0.5rem;
        }
      }
      .p-listbox-item.p-highlight {
        color: $color_2;
        background: $white-color;
      }
      .p-listbox-item-group {
        margin: 0;
        padding: 0.75rem 1rem;
        color: $color_2;
        background: $white-color;
        font-weight: 600;
      }
      .p-listbox-empty-message {
        padding: 0.5rem 1rem;
        color: $color_2;
        background: transparent;
      }
    }
    &:not(.p-disabled) {
      .p-listbox-item.p-highlight.p-focus {
        background: rgba(33, 150, 243, 0.24);
      }
      .p-listbox-item {
        &:not(.p-highlight) {
          &:not(.p-disabled).p-focus {
            color: $color_2;
            background: $space-grey-color;
          }
          &:not(.p-disabled) {
            &:hover {
              color: $color_2;
              background: $background-color_5;
            }
          }
        }
      }
    }
  }
  .p-listbox.p-focus {
    outline: 0 none;
    outline-offset: 0;
    box-shadow: 0 0 0 0.2rem $focus-blue-color;
    border-color: $border-color_1;
  }
  p-listbox.ng-dirty.ng-invalid {
    > .p-listbox {
      border-color: $border-color_2;
    }
  }
  .p-multiselect {
    background: $white-color;
    border: 1px solid $border-light-gray-color;
    transition: background-color 0.2s, color 0.2s, border-color 0.2s,
      box-shadow 0.2s;
    border-radius: 6px;
    &:not(.p-disabled) {
      &:hover {
        border-color: $border-color_1;
      }
    }
    &:not(.p-disabled).p-focus {
      outline: 0 none;
      outline-offset: 0;
      box-shadow: 0 0 0 0.2rem $focus-blue-color;
      border-color: $border-color_1;
    }
    .p-multiselect-label {
      padding: 0.5rem 0.5rem;
      transition: background-color 0.2s, color 0.2s, border-color 0.2s,
        box-shadow 0.2s;
    }
    .p-multiselect-label.p-placeholder {
      color: $color_1;
    }
    .p-multiselect-trigger {
      background: transparent;
      color: $color_1;
      width: 2.357rem;
      border-top-right-radius: 6px;
      border-bottom-right-radius: 6px;
    }
  }
  .p-multiselect.p-multiselect-chip {
    .p-multiselect-token {
      padding: 0.25rem 0.5rem;
      margin-right: 0.5rem;
      background: $space-grey-color;
      color: $color_2;
      border-radius: 16px;
      .p-multiselect-token-icon {
        margin-left: 0.5rem;
      }
    }
  }
  .p-inputwrapper-filled.p-multiselect.p-multiselect-chip {
    .p-multiselect-label {
      padding: 0.25rem 0.5rem;
    }
  }
  .p-multiselect-clearable {
    .p-multiselect-label-container {
      padding-right: 1.5rem;
    }
    .p-multiselect-clear-icon {
      color: $color_1;
      right: 2.357rem;
    }
  }
  .p-multiselect-panel {
    background: $white-color;
    color: $color_2;
    border: 0 none;
    border-radius: 6px;
    box-shadow: 0 2px 4px -1px rgba(0, 0, 0, 0.2),
      0 4px 5px 0 rgba(0, 0, 0, 0.14), 0 1px 10px 0 rgba(0, 0, 0, 0.12);
    .p-multiselect-header {
      padding: 0.5rem 1rem;
      border-bottom: 0 none;
      color: $color_2;
      background: $light-grey-color;
      margin: 0;
      border-top-right-radius: 6px;
      border-top-left-radius: 6px;
      .p-multiselect-filter-container {
        .p-inputtext {
          padding-right: 1.5rem;
        }
        .p-multiselect-filter-icon {
          right: 0.5rem;
          color: $color_1;
        }
      }
      .p-checkbox {
        margin-right: 0.5rem;
      }
      .p-multiselect-close {
        margin-left: 0.5rem;
        width: 2rem;
        height: 2rem;
        color: $color_1;
        border: 0 none;
        background: transparent;
        border-radius: 50%;
        transition: background-color 0.2s, color 0.2s, box-shadow 0.2s;
        &:enabled {
          &:hover {
            color: $color_2;
            border-color: $border-color_3;
            background: $background-color_5;
          }
        }
        &:focus-visible {
          outline: 0 none;
          outline-offset: 0;
          box-shadow: 0 0 0 0.2rem $focus-blue-color;
        }
      }
    }
    .p-multiselect-items {
      padding: 0.5rem 0;
      .p-multiselect-item {
        margin: 0;
        padding: 0.5rem 1rem;
        border: 0 none;
        color: $color_2;
        background: transparent;
        transition: box-shadow 0.2s;
        border-radius: 0;
        &:not(.p-highlight) {
          &:not(.p-disabled).p-focus {
            color: $color_2;
            background: $space-grey-color;
          }
          &:not(.p-disabled) {
            &:hover {
              color: $color_2;
              background: $background-color_5;
            }
          }
        }
        .p-checkbox {
          margin-right: 0.5rem;
        }
      }
      .p-multiselect-item.p-highlight {
        color: $color_2;
        background: $white-color;
      }
      .p-multiselect-item.p-highlight.p-focus {
        background: rgba(33, 150, 243, 0.24);
      }
      .p-multiselect-item-group {
        margin: 0;
        padding: 0.75rem 1rem;
        color: $color_2;
        background: $white-color;
        font-weight: 600;
      }
      .p-multiselect-empty-message {
        padding: 0.5rem 1rem;
        color: $color_2;
        background: transparent;
      }
    }
  }
  p-multiselect.ng-dirty.ng-invalid {
    > .p-multiselect {
      border-color: $border-color_2;
    }
  }
  p-password.ng-invalid.ng-dirty {
    > .p-password {
      > .p-inputtext {
        border-color: $border-color_2;
      }
    }
  }
  .p-password-panel {
    padding: 1rem;
    background: $white-color;
    color: $color_2;
    border: 0 none;
    box-shadow: 0 2px 4px -1px rgba(0, 0, 0, 0.2),
      0 4px 5px 0 rgba(0, 0, 0, 0.14), 0 1px 10px 0 rgba(0, 0, 0, 0.12);
    border-radius: 6px;
    .p-password-meter {
      margin-bottom: 0.5rem;
      background: $space-grey-color;
      .p-password-strength.weak {
        background: $color_7;
      }
      .p-password-strength.medium {
        background: $color_10;
      }
      .p-password-strength.strong {
        background: $dark-green-color;
      }
    }
  }
  p-password.p-password-clearable {
    .p-password-input {
      padding-right: 2rem;
    }
    .p-password-clear-icon {
      color: $color_1;
      right: 0.5rem;
    }
  }
  p-password.p-password-clearable.p-password-mask {
    .p-password-input {
      padding-right: 3.5rem;
    }
    .p-password-clear-icon {
      color: $color_1;
      right: 2rem;
    }
  }
  .p-radiobutton {
    width: 20px;
    height: 20px;
    .p-radiobutton-box {
      border: 2px solid $border-light-gray-color;
      background: $white-color;
      width: 20px;
      height: 20px;
      color: $color_2;
      border-radius: 50%;
      transition: background-color 0.2s, color 0.2s, border-color 0.2s,
        box-shadow 0.2s;
      &:not(.p-disabled) {
        &:not(.p-highlight) {
          &:hover {
            border-color: $border-color_1;
          }
        }
      }
      &:not(.p-disabled).p-focus {
        outline: 0 none;
        outline-offset: 0;
        box-shadow: 0 0 0 0.2rem $focus-blue-color;
        border-color: $border-color_1;
      }
      .p-radiobutton-icon {
        width: 12px;
        height: 12px;
        transition-duration: 0.2s;
        background-color: $background-color_3;
      }
    }
    .p-radiobutton-box.p-highlight {
      border-color: $border-color_1;
      background: $dark-blue-color;
      &:not(.p-disabled) {
        &:hover {
          border-color: $border-color_4;
          background: $royal-blue-color;
          color: $color_6;
        }
      }
    }
  }
  p-radiobutton.ng-dirty.ng-invalid {
    > .p-radiobutton {
      > .p-radiobutton-box {
        border-color: $border-color_2;
      }
    }
  }
  .p-radiobutton-label {
    margin-left: 0.5rem;
  }
  .p-rating {
    gap: 0.5rem;
    .p-rating-item {
      .p-rating-icon {
        color: $color_2;
        transition: background-color 0.2s, color 0.2s, border-color 0.2s,
          box-shadow 0.2s;
        font-size: 1.143rem;
      }
      .p-rating-icon.p-icon {
        width: 1.143rem;
        height: 1.143rem;
      }
      .p-rating-icon.p-rating-cancel {
        color: $color_4;
      }
    }
    .p-rating-item.p-focus {
      outline: 0 none;
      outline-offset: 0;
      box-shadow: 0 0 0 0.2rem $focus-blue-color;
      border-color: $border-color_1;
    }
    .p-rating-item.p-rating-item-active {
      .p-rating-icon {
        color: $color_5;
      }
    }
    &:not(.p-disabled) {
      &:not(.p-readonly) {
        .p-rating-item {
          &:hover {
            .p-rating-icon {
              color: $color_5;
            }
            .p-rating-icon.p-rating-cancel {
              color: $color_7;
            }
          }
        }
      }
    }
  }
  .p-selectbutton {
    .p-button {
      background: $white-color;
      border: 1px solid $border-light-gray-color;
      color: $color_2;
      transition: background-color 0.2s, color 0.2s, border-color 0.2s,
        box-shadow 0.2s;
      .p-button-icon-left {
        color: $color_1;
      }
      .p-button-icon-right {
        color: $color_1;
      }
      &:not(.p-disabled) {
        &:not(.p-highlight) {
          &:hover {
            background: $background-color_5;
            border-color: $border-color_6;
            color: $color_2;
            .p-button-icon-left {
              color: $color_1;
            }
            .p-button-icon-right {
              color: $color_1;
            }
          }
        }
      }
    }
    .p-button.p-highlight {
      background: $dark-blue-color;
      border-color: $border-color_1;
      color: $color_6;
      .p-button-icon-left {
        color: $color_6;
      }
      .p-button-icon-right {
        color: $color_6;
      }
      &:hover {
        background: $royal-blue-color;
        border-color: $border-color_4;
        color: $color_6;
        .p-button-icon-left {
          color: $color_6;
        }
        .p-button-icon-right {
          color: $color_6;
        }
      }
    }
  }
  p-selectbutton.ng-dirty.ng-invalid {
    > .p-selectbutton {
      > .p-button {
        border-color: $border-color_2;
      }
    }
  }
  .p-slider {
    background: $space-grey-color;
    border: 0 none;
    border-radius: 6px;
    .p-slider-handle {
      height: 1.143rem;
      width: 1.143rem;
      background: $white-color;
      border: 2px solid $dark-blue-color;
      border-radius: 50%;
      transition: background-color 0.2s, color 0.2s, border-color 0.2s,
        box-shadow 0.2s;
      &:focus {
        outline: 0 none;
        outline-offset: 0;
        box-shadow: 0 0 0 0.2rem $focus-blue-color;
      }
    }
    .p-slider-range {
      background: $dark-blue-color;
    }
    &:not(.p-disabled) {
      .p-slider-handle {
        &:hover {
          background: $dark-blue-color;
          border-color: $border-color_1;
        }
      }
    }
  }
  .p-slider.p-slider-horizontal {
    height: 0.286rem;
    .p-slider-handle {
      margin-top: -0.5715rem;
      margin-left: -0.5715rem;
    }
  }
  .p-slider.p-slider-vertical {
    width: 0.286rem;
    .p-slider-handle {
      margin-left: -0.5715rem;
      margin-bottom: -0.5715rem;
    }
  }
  .p-slider.p-slider-animate.p-slider-horizontal {
    .p-slider-handle {
      transition: background-color 0.2s, color 0.2s, border-color 0.2s,
        box-shadow 0.2s, left 0.2s;
    }
    .p-slider-range {
      transition: width 0.2s;
    }
  }
  .p-slider.p-slider-animate.p-slider-vertical {
    .p-slider-handle {
      transition: background-color 0.2s, color 0.2s, border-color 0.2s,
        box-shadow 0.2s, bottom 0.2s;
    }
    .p-slider-range {
      transition: height 0.2s;
    }
  }
  .p-togglebutton.p-button {
    background: $white-color;
    border: 1px solid $border-light-gray-color;
    color: $color_2;
    transition: background-color 0.2s, color 0.2s, border-color 0.2s,
      box-shadow 0.2s;
    .p-button-icon-left {
      color: $color_1;
    }
    .p-button-icon-right {
      color: $color_1;
    }
    &:not(.p-disabled) {
      &:not(.p-highlight) {
        &:hover {
          background: $background-color_5;
          border-color: $border-color_6;
          color: $color_2;
          .p-button-icon-left {
            color: $color_1;
          }
          .p-button-icon-right {
            color: $color_1;
          }
        }
      }
    }
  }
  .p-togglebutton.p-button.p-highlight {
    background: $dark-blue-color;
    border-color: $border-color_1;
    color: $color_6;
    .p-button-icon-left {
      color: $color_6;
    }
    .p-button-icon-right {
      color: $color_6;
    }
    &:hover {
      background: $royal-blue-color;
      border-color: $border-color_4;
      color: $color_6;
      .p-button-icon-left {
        color: $color_6;
      }
      .p-button-icon-right {
        color: $color_6;
      }
    }
  }
  p-togglebutton.ng-dirty.ng-invalid {
    > .p-togglebutton.p-button {
      border-color: $border-color_2;
    }
  }
  .p-treeselect {
    background: $white-color;
    border: 1px solid $border-light-gray-color;
    transition: background-color 0.2s, color 0.2s, border-color 0.2s,
      box-shadow 0.2s;
    border-radius: 6px;
    &:not(.p-disabled) {
      &:hover {
        border-color: $border-color_1;
      }
    }
    &:not(.p-disabled).p-focus {
      outline: 0 none;
      outline-offset: 0;
      box-shadow: 0 0 0 0.2rem $focus-blue-color;
      border-color: $border-color_1;
    }
    .p-treeselect-label {
      padding: 0.5rem 0.5rem;
      transition: background-color 0.2s, color 0.2s, border-color 0.2s,
        box-shadow 0.2s;
    }
    .p-treeselect-label.p-placeholder {
      color: $color_1;
    }
    .p-treeselect-trigger {
      background: transparent;
      color: $color_1;
      width: 2.357rem;
      border-top-right-radius: 6px;
      border-bottom-right-radius: 6px;
    }
  }
  .p-treeselect.p-treeselect-chip {
    .p-treeselect-token {
      padding: 0.25rem 0.5rem;
      margin-right: 0.5rem;
      background: $space-grey-color;
      color: $color_2;
      border-radius: 16px;
    }
  }
  p-treeselect.ng-invalid.ng-dirty {
    > .p-treeselect {
      border-color: $border-color_2;
    }
  }
  .p-inputwrapper-filled {
    .p-treeselect.p-treeselect-chip {
      .p-treeselect-label {
        padding: 0.25rem 0.5rem;
      }
    }
  }
  .p-treeselect-panel {
    background: $white-color;
    color: $color_2;
    border: 0 none;
    border-radius: 6px;
    box-shadow: 0 2px 4px -1px rgba(0, 0, 0, 0.2),
      0 4px 5px 0 rgba(0, 0, 0, 0.14), 0 1px 10px 0 rgba(0, 0, 0, 0.12);
    .p-treeselect-header {
      padding: 0.5rem 1rem;
      border-bottom: 0 none;
      color: $color_2;
      background: $light-grey-color;
      margin: 0;
      border-top-right-radius: 6px;
      border-top-left-radius: 6px;
      .p-treeselect-filter-container {
        margin-right: 0.5rem;
        .p-treeselect-filter {
          padding-right: 1.5rem;
        }
        .p-treeselect-filter-icon {
          right: 0.5rem;
          color: $color_1;
        }
      }
      .p-treeselect-filter-container.p-treeselect-clearable-filter {
        .p-treeselect-filter {
          padding-right: 3rem;
        }
        .p-treeselect-filter-clear-icon {
          right: 2rem;
        }
      }
      .p-treeselect-close {
        width: 2rem;
        height: 2rem;
        color: $color_1;
        border: 0 none;
        background: transparent;
        border-radius: 50%;
        transition: background-color 0.2s, color 0.2s, box-shadow 0.2s;
        &:enabled {
          &:hover {
            color: $color_2;
            border-color: $border-color_3;
            background: $background-color_5;
          }
        }
        &:focus-visible {
          outline: 0 none;
          outline-offset: 0;
          box-shadow: 0 0 0 0.2rem $focus-blue-color;
        }
      }
    }
    .p-treeselect-items-wrapper {
      .p-tree {
        border: 0 none;
      }
      .p-treeselect-empty-message {
        padding: 0.5rem 1rem;
        color: $color_2;
        background: transparent;
      }
    }
  }
  p-treeselect.p-treeselect-clearable {
    .p-treeselect-label-container {
      padding-right: 1.5rem;
    }
    .p-treeselect-clear-icon {
      color: $color_1;
      right: 2.357rem;
    }
  }
  .p-button {
    color: $color_6;
    background: $royal-blue-color;
    border: 1px solid $royal-blue-color;
    padding: 0.5rem 1rem;
    font-size: 1rem;
    transition: background-color 0.2s, color 0.2s, border-color 0.2s,
      box-shadow 0.2s;
    border-radius: 6px;
    &:not(:disabled) {
      &:hover {
        background: $royal-blue-color;
        color: $color_6;
        border-color: $border-color_4;
      }
      &:active {
        background: $royal-blue-color;
        color: $color_6;
        border-color: $border-color_4;
      }
    }
    &:focus {
      outline: 0 none;
      outline-offset: 0;
      box-shadow: 0 0 0 0.2rem $focus-blue-color;
    }
    .p-button-label {
      transition-duration: 0.2s;
    }
    .p-button-icon-left {
      margin-right: 0.5rem;
    }
    .p-button-icon-right {
      margin-left: 0.5rem;
    }
    .p-button-icon-bottom {
      margin-top: 0.5rem;
    }
    .p-button-icon-top {
      margin-bottom: 0.5rem;
    }
    .p-badge {
      margin-left: 0.5rem;
      min-width: 1rem;
      height: 1rem;
      line-height: 1rem;
      color: $color_5;
      background-color: $background-color_3;
    }
  }
  .p-button.p-button-outlined {
    background-color: $background-color_4;
    color: $color_3;
    border: 1px solid;
    border-radius: 6px;
    &:not(:disabled) {
      &:hover {
        background: $light-blue-color;
        color: $color_3;
        border: 1px solid;
      }
      &:active {
        background: $light-blue-color;
        color: $color_3;
        border: 1px solid;
      }
    }
  }
  .p-button.p-button-outlined.p-button-plain {
    color: $color_1;
    border-color: $border-color_7;
    &:not(:disabled) {
      &:hover {
        background: $background-color_5;
        color: $color_1;
      }
      &:active {
        background: $space-grey-color;
        color: $color_1;
      }
    }
  }
  .p-button.p-button-text {
    background-color: $background-color_4;
    color: $color_3;
    border-color: $border-color_3;
    &:not(:disabled) {
      &:hover {
        background: rgba(33, 150, 243, 0.04);
        color: $color_3;
        border-color: $border-color_3;
      }
      &:active {
        background: rgba(33, 150, 243, 0.16);
        color: $color_3;
        border-color: $border-color_3;
      }
    }
  }
  .p-button.p-button-text.p-button-plain {
    color: $color_1;
    &:not(:disabled) {
      &:hover {
        background: $background-color_5;
        color: $color_1;
      }
      &:active {
        background: $space-grey-color;
        color: $color_1;
      }
    }
  }
  .p-button.p-button-raised {
    box-shadow: 0 6px 1px -2px rgba(0, 0, 0, 0.2),
      0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 1px 5px 0 rgba(0, 0, 0, 0.12);
  }
  .p-button.p-button-rounded {
    border-radius: 2rem;
  }
  .p-button.p-button-icon-only {
    width: 2.357rem;
    padding: 0.5rem 0;
    .p-button-icon-left {
      margin: 0;
    }
    .p-button-icon-right {
      margin: 0;
    }
  }
  .p-button.p-button-icon-only.p-button-rounded {
    border-radius: 50%;
    height: 2.357rem;
  }
  .p-button.p-button-sm {
    font-size: 0.875rem;
    padding: 0.4375rem 0.875rem;
    .p-button-icon {
      font-size: 0.875rem;
    }
  }
  .p-button.p-button-lg {
    font-size: 1.25rem;
    padding: 0.625rem 1.25rem;
    .p-button-icon {
      font-size: 1.25rem;
    }
  }
  .p-button.p-button-loading-label-only {
    .p-button-label {
      margin-left: 0.5rem;
    }
    .p-button-loading-icon {
      margin-right: 0;
    }
  }
  .p-button.p-button-secondary {
    color: $color_3;
    background: $white-color;
    border: 1px solid $royal-blue-color;
    &:not(:disabled) {
      &:hover {
        background: $light-blue-color;
        color: $color_3;
        border-color: $border-color_4;
      }
      &:focus {
        box-shadow: 0 0 0 0.2rem$color_22;
      }
      &:active {
        background: $light-blue-color;
        color: $color_3;
        border-color: $border-color_4;
      }
    }
  }
  .p-buttonset.p-button-secondary {
    > .p-button {
      color: $color_3;
      background: $white-color;
      border: 1px solid $royal-blue-color;
      &:not(:disabled) {
        &:hover {
          background: $light-blue-color;
          color: $color_3;
          border-color: $border-color_4;
        }
        &:focus {
          box-shadow: 0 0 0 0.2rem$color_22;
        }
        &:active {
          background: $light-blue-color;
          color: $color_3;
          border-color: $border-color_4;
        }
      }
    }
    > .p-button.p-button-outlined {
      background-color: $background-color_4;
      color: $color_8;
      border: 1px solid;
      &:not(:disabled) {
        &:hover {
          background: rgba(96, 125, 139, 0.04);
          color: $color_8;
          border: 1px solid;
        }
        &:active {
          background: rgba(96, 125, 139, 0.16);
          color: $color_8;
          border: 1px solid;
        }
      }
    }
    > .p-button.p-button-text {
      background-color: $background-color_4;
      color: $color_8;
      border-color: $border-color_3;
      &:not(:disabled) {
        &:hover {
          background: rgba(96, 125, 139, 0.04);
          border-color: $border-color_3;
          color: $color_8;
        }
        &:active {
          background: rgba(96, 125, 139, 0.16);
          border-color: $border-color_3;
          color: $color_8;
        }
      }
    }
  }
  .p-splitbutton.p-button-secondary {
    > .p-button {
      color: $color_3;
      background: $white-color;
      border: 1px solid $royal-blue-color;
      &:not(:disabled) {
        &:hover {
          background: $light-blue-color;
          color: $color_3;
          border-color: $border-color_4;
        }
        &:focus {
          box-shadow: 0 0 0 0.2rem$color_22;
        }
        &:active {
          background: $light-blue-color;
          color: $color_3;
          border-color: $border-color_4;
        }
      }
    }
    > .p-button.p-button-outlined {
      background-color: $background-color_4;
      color: $color_8;
      border: 1px solid;
      &:not(:disabled) {
        &:hover {
          background: rgba(96, 125, 139, 0.04);
          color: $color_8;
          border: 1px solid;
        }
        &:active {
          background: rgba(96, 125, 139, 0.16);
          color: $color_8;
          border: 1px solid;
        }
      }
    }
    > .p-button.p-button-text {
      background-color: $background-color_4;
      color: $color_8;
      border-color: $border-color_3;
      &:not(:disabled) {
        &:hover {
          background: rgba(96, 125, 139, 0.04);
          border-color: $border-color_3;
          color: $color_8;
        }
        &:active {
          background: rgba(96, 125, 139, 0.16);
          border-color: $border-color_3;
          color: $color_8;
        }
      }
    }
  }
  .p-button.p-button-secondary.p-button-outlined {
    background-color: $background-color_4;
    color: $color_8;
    border: 1px solid;
    &:not(:disabled) {
      &:hover {
        background: rgba(96, 125, 139, 0.04);
        color: $color_8;
        border: 1px solid;
      }
      &:active {
        background: rgba(96, 125, 139, 0.16);
        color: $color_8;
        border: 1px solid;
      }
    }
  }
  .p-button.p-button-secondary.p-button-text {
    background-color: $background-color_4;
    color: $color_8;
    border-color: $border-color_3;
    &:not(:disabled) {
      &:hover {
        background: rgba(96, 125, 139, 0.04);
        border-color: $border-color_3;
        color: $color_8;
      }
      &:active {
        background: rgba(96, 125, 139, 0.16);
        border-color: $border-color_3;
        color: $color_8;
      }
    }
  }
  .p-button.p-button-info {
    color: $color_6;
    background: $royal-blue-color;
    border: 1px solid $royal-blue-color;
    &:not(:disabled) {
      &:hover {
        background: $dark-blue-color;
        color: $color_6;
        border-color: $border-color_8;
      }
      &:focus {
        box-shadow: 0 0 0 0.2rem$color_23;
      }
      &:active {
        background: $color_24;
        color: $color_6;
        border-color: $border-color_9;
      }
    }
  }
  .p-buttonset.p-button-info {
    > .p-button {
      color: $color_6;
      background: $royal-blue-color;
      border: 1px solid $royal-blue-color;
      &:not(:disabled) {
        &:hover {
          background: $dark-blue-color;
          color: $color_6;
          border-color: $border-color_8;
        }
        &:focus {
          box-shadow: 0 0 0 0.2rem$color_23;
        }
        &:active {
          background: $color_24;
          color: $color_6;
          border-color: $border-color_9;
        }
      }
    }
    > .p-button.p-button-outlined {
      background-color: $background-color_4;
      color: $color_3;
      border: 1px solid;
      &:not(:disabled) {
        &:hover {
          background: rgba(2, 136, 209, 0.04);
          color: $color_3;
          border: 1px solid;
        }
        &:active {
          background: rgba(2, 136, 209, 0.16);
          color: $color_3;
          border: 1px solid;
        }
      }
    }
    > .p-button.p-button-text {
      background-color: $background-color_4;
      color: $color_3;
      border-color: $border-color_3;
      &:not(:disabled) {
        &:hover {
          background: rgba(2, 136, 209, 0.04);
          border-color: $border-color_3;
          color: $color_3;
        }
        &:active {
          background: rgba(2, 136, 209, 0.16);
          border-color: $border-color_3;
          color: $color_3;
        }
      }
    }
  }
  .p-splitbutton.p-button-info {
    > .p-button {
      color: $color_6;
      background: $royal-blue-color;
      border: 1px solid $royal-blue-color;
      &:not(:disabled) {
        &:hover {
          background: $dark-blue-color;
          color: $color_6;
          border-color: $border-color_8;
        }
        &:focus {
          box-shadow: 0 0 0 0.2rem$color_23;
        }
        &:active {
          background: $color_24;
          color: $color_6;
          border-color: $border-color_9;
        }
      }
    }
    > .p-button.p-button-outlined {
      background-color: $background-color_4;
      color: $color_3;
      border: 1px solid;
      &:not(:disabled) {
        &:hover {
          background: rgba(2, 136, 209, 0.04);
          color: $color_3;
          border: 1px solid;
        }
        &:active {
          background: rgba(2, 136, 209, 0.16);
          color: $color_3;
          border: 1px solid;
        }
      }
    }
    > .p-button.p-button-text {
      background-color: $background-color_4;
      color: $color_3;
      border-color: $border-color_3;
      &:not(:disabled) {
        &:hover {
          background: rgba(2, 136, 209, 0.04);
          border-color: $border-color_3;
          color: $color_3;
        }
        &:active {
          background: rgba(2, 136, 209, 0.16);
          border-color: $border-color_3;
          color: $color_3;
        }
      }
    }
  }
  .p-button.p-button-info.p-button-outlined {
    background-color: $background-color_4;
    color: $color_3;
    border: 1px solid;
    &:not(:disabled) {
      &:hover {
        background: rgba(2, 136, 209, 0.04);
        color: $color_3;
        border: 1px solid;
      }
      &:active {
        background: rgba(2, 136, 209, 0.16);
        color: $color_3;
        border: 1px solid;
      }
    }
  }
  .p-button.p-button-info.p-button-text {
    background-color: $background-color_4;
    color: $color_3;
    border-color: $border-color_3;
    &:not(:disabled) {
      &:hover {
        background: rgba(2, 136, 209, 0.04);
        border-color: $border-color_3;
        color: $color_3;
      }
      &:active {
        background: rgba(2, 136, 209, 0.16);
        border-color: $border-color_3;
        color: $color_3;
      }
    }
  }
  .p-button.p-button-success {
    color: $color_6;
    background: $royal-blue-color;
    border: 1px solid $royal-blue-color;
    border-radius: 6px;
    &:not(:disabled) {
      &:hover {
        background: $royal-blue-color;
        color: $color_6;
        border-color: $border-color_4;
      }
      &:focus {
        box-shadow: 0 0 0 0.2rem $light-blue-color;
      }
      &:active {
        background: $royal-blue-color;
        color: $color_6;
        border-color: $border-color_4;
      }
    }
  }
  .p-buttonset.p-button-success {
    > .p-button {
      color: $color_6;
      background: $royal-blue-color;
      border: 1px solid $royal-blue-color;
      border-radius: 6px;
      &:not(:disabled) {
        &:hover {
          background: $royal-blue-color;
          color: $color_6;
          border-color: $border-color_4;
        }
        &:focus {
          box-shadow: 0 0 0 0.2rem $light-blue-color;
        }
        &:active {
          background: $royal-blue-color;
          color: $color_6;
          border-color: $border-color_4;
        }
      }
    }
    > .p-button.p-button-outlined {
      background-color: $background-color_4;
      color: $color_3;
      border: 1px solid;
      &:not(:disabled) {
        &:hover {
          background: $light-royal-blue-color;
          color: $color_3;
          border: 1px solid;
        }
        &:active {
          background: $light-royal-blue-color;
          color: $color_3;
          border: 1px solid;
        }
      }
    }
    > .p-button.p-button-text {
      background-color: $background-color_4;
      color: $color_3;
      border-color: $border-color_3;
      &:not(:disabled) {
        &:hover {
          background: $light-royal-blue-color;
          border-color: $border-color_3;
          color: $color_3;
        }
        &:active {
          background: $light-royal-blue-color;
          border-color: $border-color_3;
          color: $color_3;
        }
      }
    }
  }
  .p-splitbutton.p-button-success {
    > .p-button {
      color: $color_6;
      background: $royal-blue-color;
      border: 1px solid $royal-blue-color;
      border-radius: 6px;
      &:not(:disabled) {
        &:hover {
          background: $royal-blue-color;
          color: $color_6;
          border-color: $border-color_4;
        }
        &:focus {
          box-shadow: 0 0 0 0.2rem $light-blue-color;
        }
        &:active {
          background: $royal-blue-color;
          color: $color_6;
          border-color: $border-color_4;
        }
      }
    }
    > .p-button.p-button-outlined {
      background-color: $background-color_4;
      color: $color_3;
      border: 1px solid;
      &:not(:disabled) {
        &:hover {
          background: $light-royal-blue-color;
          color: $color_3;
          border: 1px solid;
        }
        &:active {
          background: $light-royal-blue-color;
          color: $color_3;
          border: 1px solid;
        }
      }
    }
    > .p-button.p-button-text {
      background-color: $background-color_4;
      color: $color_3;
      border-color: $border-color_3;
      &:not(:disabled) {
        &:hover {
          background: $light-royal-blue-color;
          border-color: $border-color_3;
          color: $color_3;
        }
        &:active {
          background: $light-royal-blue-color;
          border-color: $border-color_3;
          color: $color_3;
        }
      }
    }
  }
  .p-button.p-button-success.p-button-outlined {
    background-color: $background-color_4;
    color: $color_3;
    border: 1px solid;
    &:not(:disabled) {
      &:hover {
        background: $light-royal-blue-color;
        color: $color_3;
        border: 1px solid;
      }
      &:active {
        background: $light-royal-blue-color;
        color: $color_3;
        border: 1px solid;
      }
    }
  }
  .p-button.p-button-success.p-button-text {
    background-color: $background-color_4;
    color: $color_3;
    border-color: $border-color_3;
    &:not(:disabled) {
      &:hover {
        background: $light-royal-blue-color;
        border-color: $border-color_3;
        color: $color_3;
      }
      &:active {
        background: $light-royal-blue-color;
        border-color: $border-color_3;
        color: $color_3;
      }
    }
  }
  .p-button.p-button-warning {
    color: $white-color;
    background: $dark-yellow-color;
    border: 1px solid $dark-yellow-color;
    &:not(:disabled) {
      &:hover {
        background: $dark-yellow-color;
        color: $white-color;
        border-color: $border-color_10;
      }
      &:focus {
        box-shadow: 0 0 0 0.2rem$color_25;
      }
      &:active {
        background: $dark-yellow-color;
        color: $color_9;
        border-color: $border-color_10;
      }
    }
  }
  .p-buttonset.p-button-warning {
    > .p-button {
      color: $color_9;
      background: $dark-yellow-color;
      border: 1px solid $dark-yellow-color;
      &:not(:disabled) {
        &:hover {
          background: $dark-yellow-color;
          color: $color_9;
          border-color: $border-color_10;
        }
        &:focus {
          box-shadow: 0 0 0 0.2rem$color_25;
        }
        &:active {
          background: $dark-yellow-color;
          color: $color_9;
          border-color: $border-color_10;
        }
      }
    }
    > .p-button.p-button-outlined {
      background-color: $background-color_4;
      color: $color_10;
      border: 1px solid;
      &:not(:disabled) {
        &:hover {
          background: rgba(251, 192, 45, 0.04);
          color: $color_10;
          border: 1px solid;
        }
        &:active {
          background: rgba(251, 192, 45, 0.16);
          color: $color_10;
          border: 1px solid;
        }
      }
    }
    > .p-button.p-button-text {
      background-color: $background-color_4;
      color: $color_10;
      border-color: $border-color_3;
      &:not(:disabled) {
        &:hover {
          background: rgba(251, 192, 45, 0.04);
          border-color: $border-color_3;
          color: $color_10;
        }
        &:active {
          background: rgba(251, 192, 45, 0.16);
          border-color: $border-color_3;
          color: $color_10;
        }
      }
    }
  }
  .p-splitbutton.p-button-warning {
    > .p-button {
      color: $color_9;
      background: $dark-yellow-color;
      border: 1px solid $dark-yellow-color;
      &:not(:disabled) {
        &:hover {
          background: $dark-yellow-color;
          color: $color_9;
          border-color: $border-color_10;
        }
        &:focus {
          box-shadow: 0 0 0 0.2rem$color_25;
        }
        &:active {
          background: $dark-yellow-color;
          color: $color_9;
          border-color: $border-color_10;
        }
      }
    }
    > .p-button.p-button-outlined {
      background-color: $background-color_4;
      color: $color_10;
      border: 1px solid;
      &:not(:disabled) {
        &:hover {
          background: rgba(251, 192, 45, 0.04);
          color: $color_10;
          border: 1px solid;
        }
        &:active {
          background: rgba(251, 192, 45, 0.16);
          color: $color_10;
          border: 1px solid;
        }
      }
    }
    > .p-button.p-button-text {
      background-color: $background-color_4;
      color: $color_10;
      border-color: $border-color_3;
      &:not(:disabled) {
        &:hover {
          background: rgba(251, 192, 45, 0.04);
          border-color: $border-color_3;
          color: $color_10;
        }
        &:active {
          background: rgba(251, 192, 45, 0.16);
          border-color: $border-color_3;
          color: $color_10;
        }
      }
    }
  }
  .p-button.p-button-warning.p-button-outlined {
    background-color: $background-color_4;
    color: $color_10;
    border: 1px solid;
    &:not(:disabled) {
      &:hover {
        background: rgba(251, 192, 45, 0.04);
        color: $color_10;
        border: 1px solid;
      }
      &:active {
        background: rgba(251, 192, 45, 0.16);
        color: $color_10;
        border: 1px solid;
      }
    }
  }
  .p-button.p-button-warning.p-button-text {
    background-color: $background-color_4;
    color: $color_10;
    border-color: $border-color_3;
    &:not(:disabled) {
      &:hover {
        background: rgba(251, 192, 45, 0.04);
        border-color: $border-color_3;
        color: $color_10;
      }
      &:active {
        background: rgba(251, 192, 45, 0.16);
        border-color: $border-color_3;
        color: $color_10;
      }
    }
  }
  .p-button.p-button-help {
    color: $color_6;
    background: $color_11;
    border: 1px solid $color_11;
    &:not(:disabled) {
      &:hover {
        background: $color_26;
        color: $color_6;
        border-color: $border-color_11;
      }
      &:focus {
        box-shadow: 0 0 0 0.2rem $color_27;
      }
      &:active {
        background: $color_11;
        color: $color_6;
        border-color: $border-color_12;
      }
    }
  }
  .p-buttonset.p-button-help {
    > .p-button {
      color: $color_6;
      background: $color_11;
      border: 1px solid $color_11;
      &:not(:disabled) {
        &:hover {
          background: $color_26;
          color: $color_6;
          border-color: $border-color_11;
        }
        &:focus {
          box-shadow: 0 0 0 0.2rem $color_27;
        }
        &:active {
          background: $color_11;
          color: $color_6;
          border-color: $border-color_12;
        }
      }
    }
    > .p-button.p-button-outlined {
      background-color: $background-color_4;
      color: $color_11;
      border: 1px solid;
      &:not(:disabled) {
        &:hover {
          background: rgba(156, 39, 176, 0.04);
          color: $color_11;
          border: 1px solid;
        }
        &:active {
          background: rgba(156, 39, 176, 0.16);
          color: $color_11;
          border: 1px solid;
        }
      }
    }
    > .p-button.p-button-text {
      background-color: $background-color_4;
      color: $color_11;
      border-color: $border-color_3;
      &:not(:disabled) {
        &:hover {
          background: rgba(156, 39, 176, 0.04);
          border-color: $border-color_3;
          color: $color_11;
        }
        &:active {
          background: rgba(156, 39, 176, 0.16);
          border-color: $border-color_3;
          color: $color_11;
        }
      }
    }
  }
  .p-splitbutton.p-button-help {
    > .p-button {
      color: $color_6;
      background: $color_11;
      border: 1px solid $color_11;
      &:not(:disabled) {
        &:hover {
          background: $color_26;
          color: $color_6;
          border-color: $border-color_11;
        }
        &:focus {
          box-shadow: 0 0 0 0.2rem $color_27;
        }
        &:active {
          background: $color_11;
          color: $color_6;
          border-color: $border-color_12;
        }
      }
    }
    > .p-button.p-button-outlined {
      background-color: $background-color_4;
      color: $color_11;
      border: 1px solid;
      &:not(:disabled) {
        &:hover {
          background: rgba(156, 39, 176, 0.04);
          color: $color_11;
          border: 1px solid;
        }
        &:active {
          background: rgba(156, 39, 176, 0.16);
          color: $color_11;
          border: 1px solid;
        }
      }
    }
    > .p-button.p-button-text {
      background-color: $background-color_4;
      color: $color_11;
      border-color: $border-color_3;
      &:not(:disabled) {
        &:hover {
          background: rgba(156, 39, 176, 0.04);
          border-color: $border-color_3;
          color: $color_11;
        }
        &:active {
          background: rgba(156, 39, 176, 0.16);
          border-color: $border-color_3;
          color: $color_11;
        }
      }
    }
  }
  .p-button.p-button-help.p-button-outlined {
    background-color: $background-color_4;
    color: $color_11;
    border: 1px solid;
    &:not(:disabled) {
      &:hover {
        background: rgba(156, 39, 176, 0.04);
        color: $color_11;
        border: 1px solid;
      }
      &:active {
        background: rgba(156, 39, 176, 0.16);
        color: $color_11;
        border: 1px solid;
      }
    }
  }
  .p-button.p-button-help.p-button-text {
    background-color: $background-color_4;
    color: $color_11;
    border-color: $border-color_3;
    &:not(:disabled) {
      &:hover {
        background: rgba(156, 39, 176, 0.04);
        border-color: $border-color_3;
        color: $color_11;
      }
      &:active {
        background: rgba(156, 39, 176, 0.16);
        border-color: $border-color_3;
        color: $color_11;
      }
    }
  }
  .p-button.p-button-danger {
    color: $vibrant-red-color;
    background: $light-pink-color !important;
    border: 1px solid $light-pink-color !important;
    &:not(:disabled) {
      &:hover {
        background: $pale-red-color;
        color: $vibrant-red-color;
        border-color: $vibrant-red-color;
      }
      &:focus {
        box-shadow: none;
      }
      &:active {
        background: $vibrant-red-color;
        color: $white-color;
        border-color: $vibrant-red-color;
      }
    }
  }
  .p-buttonset.p-button-danger {
    > .p-button {
      color: $vibrant-red-color;
      background: $light-pink-color;
      border: 1px solid $light-pink-color;
      &:not(:disabled) {
        &:hover {
          background: $pale-red-color;
          color: $vibrant-red-color;
          border-color: $vibrant-red-color;
        }
        &:focus {
          box-shadow: 0 0 0 0.2rem $color_29;
        }
        &:active {
          background: $vibrant-red-color;
          color: $white-color;
          border-color: $vibrant-red-color;
        }
      }
    }
    > .p-button.p-button-outlined {
      background-color: $background-color_4;
      color: $color_7;
      border: 1px solid;
      &:not(:disabled) {
        &:hover {
          background: rgba(211, 47, 47, 0.04);
          color: $color_7;
          border: 1px solid;
        }
        &:active {
          background: rgba(211, 47, 47, 0.16);
          color: $color_7;
          border: 1px solid;
        }
      }
    }
    > .p-button.p-button-text {
      background-color: $background-color_4;
      color: $color_7;
      border-color: $border-color_3;
      &:not(:disabled) {
        &:hover {
          background: rgba(211, 47, 47, 0.04);
          border-color: $border-color_3;
          color: $color_7;
        }
        &:active {
          background: rgba(211, 47, 47, 0.16);
          border-color: $border-color_3;
          color: $color_7;
        }
      }
    }
  }
  .p-splitbutton.p-button-danger {
    > .p-button {
      color: $vibrant-red-color;
      background: $light-pink-color;
      border: 1px solid $light-pink-color;
      &:not(:disabled) {
        &:hover {
          background: $pale-red-color;
          color: $vibrant-red-color;
          border-color: $pale-red-color;
        }
        &:focus {
          box-shadow: 0 0 0 0.2rem $color_29;
        }
        &:active {
          background: $vibrant-red-color;
          color: $white-color;
          border-color: $vibrant-red-color;
        }
      }
    }
    > .p-button.p-button-outlined {
      background-color: $background-color_4;
      color: $color_7;
      border: 1px solid;
      &:not(:disabled) {
        &:hover {
          background: rgba(211, 47, 47, 0.04);
          color: $color_7;
          border: 1px solid;
        }
        &:active {
          background: rgba(211, 47, 47, 0.16);
          color: $color_7;
          border: 1px solid;
        }
      }
    }
    > .p-button.p-button-text {
      background-color: $background-color_4;
      color: $color_7;
      border-color: $border-color_3;
      &:not(:disabled) {
        &:hover {
          background: rgba(211, 47, 47, 0.04);
          border-color: $border-color_3;
          color: $color_7;
        }
        &:active {
          background: rgba(211, 47, 47, 0.16);
          border-color: $border-color_3;
          color: $color_7;
        }
      }
    }
  }
  .p-button.p-button-danger.p-button-outlined {
    background-color: $light-pink-color;
    color: $vibrant-red-color;
    border: 1px solid;
    &:not(:disabled) {
      &:hover {
        background: $pale-red-color;
        color: $vibrant-red-color;
        border: 1px solid;
      }
      &:active {
        background: $vibrant-red-color;
        color: $white-color;
        border: 1px solid;
      }
    }
  }
  .p-button.p-button-danger.p-button-text {
    background-color: $light-pink-color;
    color: $vibrant-red-color;
    border: 1px solid $light-pink-color;
    &:not(:disabled) {
      &:hover {
        background: $pale-red-color;
        color: $vibrant-red-color;
        border: 1px solid $pale-red-color;
      }
      &:active {
        background: $vibrant-red-color;
        color: $white-color;
        border: 1px solid $vibrant-red-color;
      }
    }
  }
  .p-button.p-button-link {
    color: $color_3;
    background: transparent;
    border: transparent;
    &:not(:disabled) {
      &:hover {
        background: transparent;
        color: $color_3;
        border-color: $border-color_3;
        .p-button-label {
          text-decoration: underline;
        }
      }
      &:focus {
        background: transparent;
        box-shadow: 0 0 0 0.2rem $focus-blue-color;
        border-color: $border-color_3;
      }
      &:active {
        background: transparent;
        color: $color_3;
        border-color: $border-color_3;
      }
    }
  }
  .p-speeddial-button.p-button.p-button-icon-only {
    width: 4rem;
    height: 4rem;
    .p-button-icon {
      font-size: 1.3rem;
    }
    .p-icon {
      width: 1.3rem;
      height: 1.3rem;
    }
  }
  .p-speeddial-list {
    outline: 0 none;
  }
  .p-speeddial-item.p-focus {
    > .p-speeddial-action {
      outline: 0 none;
      outline-offset: 0;
      box-shadow: 0 0 0 0.2rem $focus-blue-color;
    }
  }
  .p-speeddial-action {
    width: 3rem;
    height: 3rem;
    background: $text-gray-color;
    color: $color_6;
    &:hover {
      background: $color_9;
      color: $color_6;
    }
  }
  .p-speeddial-direction-up {
    .p-speeddial-item {
      margin: 0.25rem 0;
      &:first-child {
        margin-bottom: 0.5rem;
      }
    }
  }
  .p-speeddial-direction-down {
    .p-speeddial-item {
      margin: 0.25rem 0;
      &:first-child {
        margin-top: 0.5rem;
      }
    }
  }
  .p-speeddial-direction-left {
    .p-speeddial-item {
      margin: 0 0.25rem;
      &:first-child {
        margin-right: 0.5rem;
      }
    }
  }
  .p-speeddial-direction-right {
    .p-speeddial-item {
      margin: 0 0.25rem;
      &:first-child {
        margin-left: 0.5rem;
      }
    }
  }
  .p-speeddial-circle {
    .p-speeddial-item {
      margin: 0;
      &:first-child {
        margin: 0;
      }
      &:last-child {
        margin: 0;
      }
    }
  }
  .p-speeddial-semi-circle {
    .p-speeddial-item {
      margin: 0;
      &:first-child {
        margin: 0;
      }
      &:last-child {
        margin: 0;
      }
    }
  }
  .p-speeddial-quarter-circle {
    .p-speeddial-item {
      margin: 0;
      &:first-child {
        margin: 0;
      }
      &:last-child {
        margin: 0;
      }
    }
  }
  .p-speeddial-mask {
    background-color: $background-color_1;
  }
  .p-splitbutton {
    border-radius: 6px;
  }
  .p-splitbutton.p-button-outlined {
    > .p-button {
      background-color: $background-color_4;
      color: $color_5;
      border: 1px solid;
      &:not(:disabled) {
        &:hover {
          background: rgba(33, 150, 243, 0.04);
          color: $color_5;
        }
        &:active {
          background: rgba(33, 150, 243, 0.16);
          color: $color_5;
        }
      }
    }
  }
  .p-splitbutton.p-button-outlined.p-button-plain {
    > .p-button {
      color: $color_1;
      border-color: $border-color_7;
      &:not(:disabled) {
        &:hover {
          background: $background-color_5;
          color: $color_1;
        }
        &:active {
          background: $space-grey-color;
          color: $color_1;
        }
      }
    }
  }
  .p-splitbutton.p-button-text {
    > .p-button {
      background-color: $background-color_4;
      color: $color_5;
      border-color: $border-color_3;
      &:not(:disabled) {
        &:hover {
          background: rgba(33, 150, 243, 0.04);
          color: $color_5;
          border-color: $border-color_3;
        }
        &:active {
          background: rgba(33, 150, 243, 0.16);
          color: $color_5;
          border-color: $border-color_3;
        }
      }
    }
  }
  .p-splitbutton.p-button-text.p-button-plain {
    > .p-button {
      color: $color_1;
      &:not(:disabled) {
        &:hover {
          background: $background-color_5;
          color: $color_1;
        }
        &:active {
          background: $space-grey-color;
          color: $color_1;
        }
      }
    }
  }
  .p-splitbutton.p-button-raised {
    box-shadow: 0 6px 1px -2px rgba(0, 0, 0, 0.2),
      0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 1px 5px 0 rgba(0, 0, 0, 0.12);
  }
  .p-splitbutton.p-button-rounded {
    border-radius: 2rem;
    > .p-button {
      border-radius: 2rem;
    }
  }
  .p-splitbutton.p-button-sm {
    > .p-button {
      font-size: 0.875rem;
      padding: 0.4375rem 0.875rem;
      .p-button-icon {
        font-size: 0.875rem;
      }
    }
  }
  .p-splitbutton.p-button-lg {
    > .p-button {
      font-size: 1.25rem;
      padding: 0.625rem 1.25rem;
      .p-button-icon {
        font-size: 1.25rem;
      }
    }
  }
  .p-splitbutton.p-button-secondary.p-button-outlined {
    > .p-button {
      background-color: $background-color_4;
      color: $color_8;
      border: 1px solid;
      &:not(:disabled) {
        &:hover {
          background: rgba(96, 125, 139, 0.04);
          color: $color_8;
        }
        &:active {
          background: rgba(96, 125, 139, 0.16);
          color: $color_8;
        }
      }
    }
  }
  .p-splitbutton.p-button-secondary.p-button-text {
    > .p-button {
      background-color: $background-color_4;
      color: $color_8;
      border-color: $border-color_3;
      &:not(:disabled) {
        &:hover {
          background: rgba(96, 125, 139, 0.04);
          border-color: $border-color_3;
          color: $color_8;
        }
        &:active {
          background: rgba(96, 125, 139, 0.16);
          border-color: $border-color_3;
          color: $color_8;
        }
      }
    }
  }
  .p-splitbutton.p-button-info.p-button-outlined {
    > .p-button {
      background-color: $background-color_4;
      color: $color_3;
      border: 1px solid;
      &:not(:disabled) {
        &:hover {
          background: rgba(2, 136, 209, 0.04);
          color: $color_3;
        }
        &:active {
          background: rgba(2, 136, 209, 0.16);
          color: $color_3;
        }
      }
    }
  }
  .p-splitbutton.p-button-info.p-button-text {
    > .p-button {
      background-color: $background-color_4;
      color: $color_3;
      border-color: $border-color_3;
      &:not(:disabled) {
        &:hover {
          background: rgba(2, 136, 209, 0.04);
          border-color: $border-color_3;
          color: $color_3;
        }
        &:active {
          background: rgba(2, 136, 209, 0.16);
          border-color: $border-color_3;
          color: $color_3;
        }
      }
    }
  }
  .p-splitbutton.p-button-success.p-button-outlined {
    > .p-button {
      background-color: $background-color_4;
      color: $color_12;
      border: 1px solid;
      &:not(:disabled) {
        &:hover {
          background: rgba(104, 159, 56, 0.04);
          color: $color_12;
        }
        &:active {
          background: rgba(104, 159, 56, 0.16);
          color: $color_12;
        }
      }
    }
  }
  .p-splitbutton.p-button-success.p-button-text {
    > .p-button {
      background-color: $background-color_4;
      color: $color_12;
      border-color: $border-color_3;
      &:not(:disabled) {
        &:hover {
          background: rgba(104, 159, 56, 0.04);
          border-color: $border-color_3;
          color: $color_12;
        }
        &:active {
          background: rgba(104, 159, 56, 0.16);
          border-color: $border-color_3;
          color: $color_12;
        }
      }
    }
  }
  .p-splitbutton.p-button-warning.p-button-outlined {
    > .p-button {
      background-color: $background-color_4;
      color: $color_10;
      border: 1px solid;
      &:not(:disabled) {
        &:hover {
          background: rgba(251, 192, 45, 0.04);
          color: $color_10;
        }
        &:active {
          background: rgba(251, 192, 45, 0.16);
          color: $color_10;
        }
      }
    }
  }
  .p-splitbutton.p-button-warning.p-button-text {
    > .p-button {
      background-color: $background-color_4;
      color: $color_10;
      border-color: $border-color_3;
      &:not(:disabled) {
        &:hover {
          background: rgba(251, 192, 45, 0.04);
          border-color: $border-color_3;
          color: $color_10;
        }
        &:active {
          background: rgba(251, 192, 45, 0.16);
          border-color: $border-color_3;
          color: $color_10;
        }
      }
    }
  }
  .p-splitbutton.p-button-help.p-button-outlined {
    > .p-button {
      background-color: $background-color_4;
      color: $color_11;
      border: 1px solid;
      &:not(:disabled) {
        &:hover {
          background: rgba(156, 39, 176, 0.04);
          color: $color_11;
        }
        &:active {
          background: rgba(156, 39, 176, 0.16);
          color: $color_11;
        }
      }
    }
  }
  .p-splitbutton.p-button-help.p-button-text {
    > .p-button {
      background-color: $background-color_4;
      color: $color_11;
      border-color: $border-color_3;
      &:not(:disabled) {
        &:hover {
          background: rgba(156, 39, 176, 0.04);
          border-color: $border-color_3;
          color: $color_11;
        }
        &:active {
          background: rgba(156, 39, 176, 0.16);
          border-color: $border-color_3;
          color: $color_11;
        }
      }
    }
  }
  .p-splitbutton.p-button-danger.p-button-outlined {
    > .p-button {
      background-color: $light-pink-color;
      color: $vibrant-red-color;
      border: 1px solid;
      &:not(:disabled) {
        &:hover {
          background: $pale-red-color;
          color: $vibrant-red-color;
          border: 1px solid;
        }
        &:active {
          background: $vibrant-red-color;
          color: $white-color;
          border: 1px solid;
        }
      }
    }
  }
  .p-splitbutton.p-button-danger.p-button-text {
    > .p-button {
      background-color: $background-color_4;
      color: $color_7;
      border-color: $border-color_3;
      &:not(:disabled) {
        &:hover {
          background: rgba(211, 47, 47, 0.04);
          border-color: $border-color_3;
          color: $color_7;
        }
        &:active {
          background: rgba(211, 47, 47, 0.16);
          border-color: $border-color_3;
          color: $color_7;
        }
      }
    }
  }
  .p-carousel {
    .p-carousel-content {
      .p-carousel-prev {
        width: 2rem;
        height: 2rem;
        color: $color_1;
        border: 0 none;
        background: transparent;
        border-radius: 50%;
        transition: background-color 0.2s, color 0.2s, box-shadow 0.2s;
        margin: 0.5rem;
        &:enabled {
          &:hover {
            color: $color_2;
            border-color: $border-color_3;
            background: $background-color_5;
          }
        }
        &:focus-visible {
          outline: 0 none;
          outline-offset: 0;
          box-shadow: 0 0 0 0.2rem $focus-blue-color;
        }
      }
      .p-carousel-next {
        width: 2rem;
        height: 2rem;
        color: $color_1;
        border: 0 none;
        background: transparent;
        border-radius: 50%;
        transition: background-color 0.2s, color 0.2s, box-shadow 0.2s;
        margin: 0.5rem;
        &:enabled {
          &:hover {
            color: $color_2;
            border-color: $border-color_3;
            background: $background-color_5;
          }
        }
        &:focus-visible {
          outline: 0 none;
          outline-offset: 0;
          box-shadow: 0 0 0 0.2rem $focus-blue-color;
        }
      }
    }
    .p-carousel-indicators {
      padding: 1rem;
      .p-carousel-indicator {
        margin-right: 0.5rem;
        margin-bottom: 0.5rem;
        button {
          background-color: $background-color_5;
          width: 2rem;
          height: 0.5rem;
          transition: background-color 0.2s, color 0.2s, box-shadow 0.2s;
          border-radius: 0;
          &:hover {
            background: $space-grey-color;
          }
        }
      }
      .p-carousel-indicator.p-highlight {
        button {
          background: $white-color;
          color: $color_2;
        }
      }
    }
  }
  .p-datatable {
    border: 1px solid $border-light-gray-color;
    border-radius: 6px;
    .p-paginator-top {
      border-width: 0 0 1px 0;
      border-radius: 0;
    }
    .p-paginator-bottom {
      border-width: 0 0 1px 0;
      border-radius: 0;
    }

    .p-datatable-header {
      background: $light-grey-color;
      color: $color_2;
      border: 1px solid$background-color_5;
      border-width: 1px 0 1px 0;
      padding: 1rem 1rem;
      font-weight: 600;
      border-radius: 0.375rem;
      .p-button.p-button-success {
        height: 40px;
      }
      .p-button-icon-only {
        height: 40px;
      }
      .clear-container {
        .p-button-outlined {
          height: 40px !important;
        }
      }
    }
    .p-datatable-footer {
      background: $light-grey-color;
      color: $color_2;
      border: 1px solid$background-color_5;
      border-width: 0 0 1px 0;
      padding: 1rem 1rem;
      font-weight: 600;
    }
    .p-datatable-thead {
      > tr {
        > th {
          text-align: left;
          padding: 1rem 1rem;
          border: 1px solid$background-color_5;
          border-width: 0 0 1px 0;
          font-weight: 600;
          color: $color_2;
          background: $light-grey-color;
          transition: box-shadow 0.2s;
        }
      }
    }
    .p-datatable-tfoot {
      > tr {
        > td {
          text-align: left;
          padding: 1rem 1rem;
          border: 1px solid$background-color_5;
          border-width: 0 0 1px 0;
          font-weight: 600;
          color: $color_2;
          background: $light-grey-color;
        }
      }
    }
    .p-sortable-column {
      .p-sortable-column-icon {
        color: $color_1;
        margin-left: 0.5rem;
      }
      .p-sortable-column-badge {
        border-radius: 50%;
        height: 1.143rem;
        min-width: 1.143rem;
        line-height: 1.143rem;
        color: $color_2;
        background: $white-color;
        margin-left: 0.5rem;
      }
      &:not(.p-highlight) {
        &:hover {
          background: $background-color_5;
          color: $color_2;
          .p-sortable-column-icon {
            color: $color_1;
          }
        }
      }
      &:focus-visible {
        box-shadow: inset 0 0 0 0.15rem $focus-blue-color;
        outline: 0 none;
      }
    }
    .p-sortable-column.p-highlight {
      background: $light-grey-color;
      color: $color_5;
      .p-sortable-column-icon {
        color: $color_5;
      }
      &:hover {
        background: $background-color_5;
        color: $color_5;
        .p-sortable-column-icon {
          color: $color_5;
        }
      }
    }
    .p-datatable-tbody {
      > tr {
        background: $white-color;
        color: $color_2;
        transition: box-shadow 0.2s;
        > td {
          text-align: left;
          border: 1px solid$background-color_5;
          border-width: 0 0 1px 0;
          padding: 1rem 1rem;
          // .p-button {
          //   background-color: $background-color_6 ;
          //   color: $color_3 ;
          // }
          .p-row-toggler {
            width: 2rem;
            height: 2rem;
            color: $color_1;
            border: 0 none;
            background: transparent;
            border-radius: 50%;
            transition: background-color 0.2s, color 0.2s, box-shadow 0.2s;
            &:enabled {
              &:hover {
                color: $color_2;
                border-color: $border-color_3;
                background: $background-color_5;
              }
            }
            &:focus-visible {
              outline: 0 none;
              outline-offset: 0;
              box-shadow: 0 0 0 0.2rem $focus-blue-color;
            }
          }
          .p-row-editor-init {
            width: 2rem;
            height: 2rem;
            color: $color_1;
            border: 0 none;
            background: transparent;
            border-radius: 50%;
            transition: background-color 0.2s, color 0.2s, box-shadow 0.2s;
            &:enabled {
              &:hover {
                color: $color_2;
                border-color: $border-color_3;
                background: $background-color_5;
              }
            }
            &:focus-visible {
              outline: 0 none;
              outline-offset: 0;
              box-shadow: 0 0 0 0.2rem $focus-blue-color;
            }
          }
          .p-row-editor-save {
            width: 2rem;
            height: 2rem;
            color: $color_1;
            border: 0 none;
            background: transparent;
            border-radius: 50%;
            transition: background-color 0.2s, color 0.2s, box-shadow 0.2s;
            margin-right: 0.5rem;
            &:enabled {
              &:hover {
                color: $color_2;
                border-color: $border-color_3;
                background: $background-color_5;
              }
            }
            &:focus-visible {
              outline: 0 none;
              outline-offset: 0;
              box-shadow: 0 0 0 0.2rem $focus-blue-color;
            }
          }
          .p-row-editor-cancel {
            width: 2rem;
            height: 2rem;
            color: $color_1;
            border: 0 none;
            background: transparent;
            border-radius: 50%;
            transition: background-color 0.2s, color 0.2s, box-shadow 0.2s;
            &:enabled {
              &:hover {
                color: $color_2;
                border-color: $border-color_3;
                background: $background-color_5;
              }
            }
            &:focus-visible {
              outline: 0 none;
              outline-offset: 0;
              box-shadow: 0 0 0 0.2rem $focus-blue-color;
            }
          }
        }
        &:focus-visible {
          outline: 0.15rem solid $focus-blue-color;
          outline-offset: -0.15rem;
        }
      }
      > tr.p-highlight {
        background: $white-color;
        color: $color_2;
      }
      > tr.p-datatable-dragpoint-top {
        > td {
          box-shadow: inset 0 2px 0 0 $white-color;
          box-shadow: inset 0 2px 0 0 $dark-blue-color;
        }
      }
      > tr.p-datatable-dragpoint-bottom {
        > td {
          box-shadow: inset 0 -2px 0 0 $white-color;
          box-shadow: inset 0 -2px 0 0 $dark-blue-color;
        }
      }
    }
    .p-column-resizer-helper {
      background: $dark-blue-color;
    }
    .p-datatable-scrollable-header {
      background: $light-grey-color;
    }
    .p-datatable-scrollable-footer {
      background: $light-grey-color;
    }
    .p-datatable-loading-icon {
      font-size: 2rem;
    }
  }
  .p-datatable.p-datatable-hoverable-rows {
    .p-datatable-tbody {
      > tr {
        &:not(.p-highlight) {
          &:hover {
            background: $background-color_5;
            color: $color_2;
          }
        }
      }
    }
  }
  .p-datatable.p-datatable-scrollable {
    > .p-datatable-wrapper {
      > .p-datatable-table {
        > .p-datatable-thead {
          background-color: $background-color_2;
        }
        > .p-datatable-tfoot {
          background-color: $background-color_2;
        }
      }
      > .p-scroller-viewport {
        > .p-scroller {
          > .p-datatable-table {
            > .p-datatable-thead {
              background-color: $background-color_2;
            }
            > .p-datatable-tfoot {
              background-color: $background-color_2;
            }
          }
        }
      }
    }
  }
  .p-datatable.p-datatable-gridlines {
    .p-datatable-header {
      border-width: 1px 1px 0 1px;
    }
    .p-datatable-footer {
      border-width: 0 1px 1px 1px;
    }
    .p-paginator-top {
      border-width: 0 1px 0 1px;
    }
    .p-paginator-bottom {
      border-width: 0 1px 1px 1px;
    }
    .p-datatable-thead {
      > tr {
        > th {
          border-width: 1px 0 1px 1px;
          &:last-child {
            border-width: 1px;
          }
        }
      }
      + {
        .p-datatable-tfoot {
          > tr {
            > td {
              border-width: 0 0 1px 1px;
              &:last-child {
                border-width: 0 1px 1px 1px;
              }
            }
          }
        }
      }
    }
    .p-datatable-tbody {
      > tr {
        > td {
          border-width: 1px 0 0 1px;
          &:last-child {
            border-width: 1px 1px 0 1px;
          }
        }
        &:last-child {
          > td {
            border-width: 1px 0 1px 1px;
            &:last-child {
              border-width: 1px;
            }
          }
        }
      }
    }
    .p-datatable-tfoot {
      > tr {
        > td {
          border-width: 1px 0 1px 1px;
          &:last-child {
            border-width: 1px 1px 1px 1px;
          }
        }
      }
    }
    &:has(.p-datatable-thead) {
      &:has(.p-datatable-tbody) {
        .p-datatable-tbody {
          > tr {
            > td {
              border-width: 0 0 1px 1px;
              &:last-child {
                border-width: 0 1px 1px 1px;
              }
            }
          }
        }
      }
    }
  }
}
:has(.p-datatable-tbody) {
  &:has(.p-datatable-tfoot) {
    .p-datatable-tbody {
      > tr {
        &:last-child {
          > td {
            border-width: 0 0 0 1px;
            &:last-child {
              border-width: 0 1px 0 1px;
            }
          }
        }
      }
    }
  }
}

.p-datatable.p-datatable-striped {
  .p-datatable-tbody {
    > tr {
      &:nth-child(even) {
        background: $white-color;
      }
      &:nth-child(even).p-highlight {
        background: $white-color;
        color: $color_2;
        .p-row-toggler {
          color: $color_2;
          &:hover {
            color: $color_2;
          }
        }
      }
    }
  }
}
.p-datatable.p-datatable-sm {
  .p-datatable-header {
    padding: 0.5rem 0.5rem;
  }
  .p-datatable-thead {
    > tr {
      > th {
        padding: 0.5rem 0.5rem;
      }
    }
  }
  .p-datatable-tbody {
    > tr {
      > td {
        padding: 0.5rem 0.5rem;
      }
    }
  }
  .p-datatable-tfoot {
    > tr {
      > td {
        padding: 0.5rem 0.5rem;
      }
    }
  }
  .p-datatable-footer {
    padding: 0.5rem 0.5rem;
  }
}
.p-datatable.p-datatable-lg {
  .p-datatable-header {
    padding: 1.25rem 1.25rem;
  }
  .p-datatable-thead {
    > tr {
      > th {
        padding: 1.25rem 1.25rem;
      }
    }
  }
  .p-datatable-tbody {
    > tr {
      > td {
        padding: 1.25rem 1.25rem;
      }
    }
  }
  .p-datatable-tfoot {
    > tr {
      > td {
        padding: 1.25rem 1.25rem;
      }
    }
  }
  .p-datatable-footer {
    padding: 1.25rem 1.25rem;
  }
}
.p-dataview {
  .p-paginator-top {
    border-width: 0 0 1px 0;
    border-radius: 0;
  }
  .p-paginator-bottom {
    border-width: 0 0 1px 0;
    border-radius: 0;
  }
  .p-dataview-header {
    background: $light-grey-color;
    color: $color_2;
    border: 1px solid$background-color_5;
    border-width: 1px 0 1px 0;
    padding: 1rem 1rem;
    font-weight: 600;
  }
  .p-dataview-content {
    background: $white-color;
    color: $color_2;
    border: 0 none;
    padding: 0;
  }
  .p-dataview-footer {
    background: $light-grey-color;
    color: $color_2;
    border: 1px solid$background-color_5;
    border-width: 0 0 1px 0;
    padding: 1rem 1rem;
    font-weight: 600;
    border-bottom-left-radius: 6px;
    border-bottom-right-radius: 6px;
  }
  .p-dataview-loading-icon {
    font-size: 2rem;
  }
  .p-dataview-emptymessage {
    padding: 1rem;
  }
}
.p-column-filter-row {
  .p-column-filter-menu-button {
    margin-left: 0.5rem;
  }
  .p-column-filter-clear-button {
    margin-left: 0.5rem;
  }
}
.p-column-filter-menu-button {
  width: 2rem;
  height: 2rem;
  color: $color_1;
  border: 0 none;
  background: transparent;
  border-radius: 50%;
  transition: background-color 0.2s, color 0.2s, box-shadow 0.2s;
  &:hover {
    color: $color_2;
    border-color: $border-color_3;
    background: $background-color_5;
  }
  &:focus-visible {
    outline: 0 none;
    outline-offset: 0;
    box-shadow: 0 0 0 0.2rem $focus-blue-color;
  }
}
.p-column-filter-menu-button.p-column-filter-menu-button-open {
  background: $background-color_5;
  color: $color_2;
  &:hover {
    background: $background-color_5;
    color: $color_2;
  }
}
.p-column-filter-menu-button.p-column-filter-menu-button-active {
  background: $white-color;
  color: $color_2;
  &:hover {
    background: $white-color;
    color: $color_2;
  }
}
.p-column-filter-clear-button {
  width: 2rem;
  height: 2rem;
  color: $color_1;
  border: 0 none;
  background: transparent;
  border-radius: 50%;
  transition: background-color 0.2s, color 0.2s, box-shadow 0.2s;
  &:hover {
    color: $color_2;
    border-color: $border-color_3;
    background: $background-color_5;
  }
  &:focus-visible {
    outline: 0 none;
    outline-offset: 0;
    box-shadow: 0 0 0 0.2rem $focus-blue-color;
  }
}
.p-column-filter-overlay {
  background: $white-color;
  color: $color_2;
  border: 0 none;
  border-radius: 6px;
  box-shadow: 0 2px 4px -1px rgba(0, 0, 0, 0.2), 0 4px 5px 0 rgba(0, 0, 0, 0.14),
    0 1px 10px 0 rgba(0, 0, 0, 0.12);
  min-width: 12.5rem;
  .p-column-filter-row-items {
    padding: 0.5rem 0;
    .p-column-filter-row-item {
      margin: 0;
      padding: 0.5rem 1rem;
      border: 0 none;
      color: $color_2;
      background: transparent;
      transition: box-shadow 0.2s;
      border-radius: 0;
      &:not(.p-highlight) {
        &:not(.p-disabled) {
          &:hover {
            color: $color_2;
            background: $background-color_5;
          }
        }
      }
      &:focus-visible {
        outline: 0 none;
        outline-offset: 0;
        box-shadow: inset 0 0 0 0.15rem $focus-blue-color;
      }
    }
    .p-column-filter-row-item.p-highlight {
      color: $color_2;
      background: $white-color;
    }
    .p-column-filter-separator {
      border-top: 1px solid $space-grey-color;
      margin: 0.25rem 0;
    }
  }
}
.p-column-filter-overlay-menu {
  .p-column-filter-operator {
    padding: 0.5rem 1rem;
    border-bottom: 0 none;
    color: $color_2;
    background: $light-grey-color;
    margin: 0;
    border-top-right-radius: 6px;
    border-top-left-radius: 6px;
  }
  .p-column-filter-constraint {
    padding: 1rem;
    border-bottom: 1px solid $space-grey-color;
    .p-column-filter-matchmode-dropdown {
      margin-bottom: 0.5rem;
    }
    .p-column-filter-remove-button {
      margin-top: 0.5rem;
    }
    &:last-child {
      border-bottom: 0 none;
    }
  }
  .p-column-filter-add-rule {
    padding: 0.5rem 1rem;
  }
  .p-column-filter-buttonbar {
    padding: 1rem;
    gap: 0.25rem;
  }
}
.p-orderlist {
  .p-orderlist-controls {
    padding: 1rem;
    .p-button {
      margin-bottom: 0.5rem;
    }
  }
  .p-orderlist-list-container {
    background: $white-color;
    border: 1px solid $space-grey-color;
    border-radius: 6px;
    transition: background-color 0.2s, color 0.2s, border-color 0.2s,
      box-shadow 0.2s;
    outline-color: $outline-color_1;
  }
  .p-orderlist-list-container.p-focus {
    outline: 0 none;
    outline-offset: 0;
    box-shadow: 0 0 0 0.2rem $focus-blue-color;
    border-color: $border-color_1;
  }
  .p-orderlist-header {
    color: $color_2;
    padding: 1rem;
    font-weight: 600;
    .p-orderlist-title {
      font-weight: 600;
    }
  }
  .p-orderlist-filter-container {
    padding: 1rem;
    background: $white-color;
    border: 1px solid $space-grey-color;
    border-bottom: 0 none;
    .p-orderlist-filter-input {
      padding-right: 1.5rem;
    }
    .p-orderlist-filter-icon {
      right: 0.5rem;
      color: $color_1;
    }
  }
  .p-orderlist-list {
    color: $color_2;
    padding: 0.5rem 0;
    outline: 0 none;
    &:not(:first-child) {
      border-top: 1px solid $space-grey-color;
    }
    .p-orderlist-item {
      padding: 0.5rem 1rem;
      margin: 0;
      border: 0 none;
      color: $color_2;
      background: transparent;
      transition: box-shadow 0.2s;
      &:not(.p-highlight) {
        &:hover {
          background: $background-color_5;
          color: $color_2;
        }
      }
    }
    .p-orderlist-item.p-focus {
      color: $color_2;
      background: $space-grey-color;
    }
    .p-orderlist-item.p-highlight {
      color: $color_2;
      background: $white-color;
    }
    .p-orderlist-item.p-highlight.p-focus {
      background: rgba(33, 150, 243, 0.24);
    }
    .p-orderlist-empty-message {
      padding: 0.5rem 1rem;
      color: $color_2;
    }
    &:not(.cdk-drop-list-dragging) {
      .p-orderlist-item {
        &:not(.p-highlight) {
          &:hover {
            background: $background-color_5;
            color: $color_2;
          }
        }
      }
    }
  }
}
.p-orderlist.p-orderlist-striped {
  .p-orderlist-list {
    .p-orderlist-item {
      &:nth-child(even) {
        background: $background-color_5;
        &:hover {
          background: $background-color_5;
        }
      }
    }
  }
}
.p-orderlist-item.cdk-drag-preview {
  padding: 0.5rem 1rem;
  box-shadow: 0 2px 4px -1px rgba(0, 0, 0, 0.2), 0 4px 5px 0 rgba(0, 0, 0, 0.14),
    0 1px 10px 0 rgba(0, 0, 0, 0.12);
  border: 0 none;
  color: $color_2;
  background: $white-color;
  margin: 0;
}
.p-organizationchart {
  .p-organizationchart-node-content.p-organizationchart-selectable-node {
    &:not(.p-highlight) {
      &:hover {
        background: $background-color_5;
        color: $color_2;
      }
    }
  }
}

.p-organizationchart-node-content.p-highlight {
  background: $white-color;
  color: $color_2;
  .p-node-toggler {
    i {
      color: $color_13;
    }
  }
}
.p-organizationchart-line-down {
  background: $space-grey-color;
}
.p-organizationchart-line-left {
  border-right: 1px solid $space-grey-color;
  border-color: $border-color_14;
}
.p-organizationchart-line-top {
  border-top: 1px solid $space-grey-color;
  border-color: $border-color_14;
}
.p-organizationchart-node-content {
  border: 1px solid $space-grey-color;
  background: $white-color;
  color: $color_2;
  padding: 1rem;
  .p-node-toggler {
    background: inherit;
    color: $color_14;
    border-radius: 50%;
    &:focus-visible {
      outline: 0 none;
      outline-offset: 0;
      box-shadow: 0 0 0 0.2rem $focus-blue-color;
    }
  }
}

.p-paginator {
  background: $white-color;
  color: $color_2;
  border: solid $white-color;
  border-width: 0;
  padding: 0.5rem 1rem;
  border-radius: 6px;
  .p-paginator-first {
    background-color: $background-color_4;
    border: 0 none;
    color: $color_2;
    min-width: 2.357rem;
    height: 2.357rem;
    margin: 0.143rem;
    transition: box-shadow 0.2s;
    border-radius: 6px;
    border-top-left-radius: 20px;
    border-bottom-left-radius: 20px;
    &:not(.p-disabled) {
      &:not(.p-highlight) {
        &:hover {
          background: $white-color;
          border-color: $border-color_3;
          color: $color_2;
        }
      }
    }
  }
  .p-paginator-prev {
    background-color: $background-color_4;
    border: 0 none;
    color: $color_2;
    min-width: 2.357rem;
    height: 2.357rem;
    margin: 0.143rem;
    transition: box-shadow 0.2s;
    border-radius: 6px;
    &:not(.p-disabled) {
      &:not(.p-highlight) {
        &:hover {
          background: $white-color;
          border-color: $border-color_3;
          color: $color_2;
        }
      }
    }
  }
  .p-paginator-next {
    background-color: $background-color_4;
    border: 0 none;
    color: $color_2;
    min-width: 2.357rem;
    height: 2.357rem;
    margin: 0.143rem;
    transition: box-shadow 0.2s;
    border-radius: 6px;
    &:not(.p-disabled) {
      &:not(.p-highlight) {
        &:hover {
          background: $white-color;
          border-color: $border-color_3;
          color: $color_2;
        }
      }
    }
  }
  .p-paginator-last {
    background-color: $background-color_4;
    border: 0 none;
    color: $color_2;
    min-width: 2.357rem;
    height: 2.357rem;
    margin: 0.143rem;
    transition: box-shadow 0.2s;
    border-radius: 6px;
    border-top-right-radius: 20px;
    border-bottom-right-radius: 20px;
    &:not(.p-disabled) {
      &:not(.p-highlight) {
        &:hover {
          background: $white-color;
          border-color: $border-color_3;
          color: $color_2;
        }
      }
    }
  }
  .p-dropdown {
    margin-left: 0.5rem;
    margin-right: 0.5rem;
    height: 2.357rem;
    .p-dropdown-label {
      padding-right: 0;
    }
  }
  .p-paginator-page-input {
    margin-left: 0.5rem;
    margin-right: 0.5rem;
    .p-inputtext {
      max-width: 2.357rem;
    }
  }
  .p-paginator-current {
    background-color: $background-color_4;
    border: 0 none;
    color: $color_3;
    min-width: 2.357rem;
    height: 2.357rem;
    margin: 0.143rem;
    padding: 0 0.5rem;
  }
  .p-paginator-pages {
    .p-paginator-page {
      background-color: $background-color_4;
      border: 0 none;
      color: $color_2;
      min-width: 2.357rem;
      height: 2.357rem;
      margin: 0.143rem;
      transition: box-shadow 0.2s;
      border-radius: 20px;
      &:not(.p-highlight) {
        &:hover {
          background: $light-blue-color;
          border-color: $border-color_3;
          color: $color_3;
        }
      }
    }
    .p-paginator-page.p-highlight {
      background: $light-royal-blue-color;
      border-color: $border-color_15;
      color: $color_3;
    }
  }
}
.p-picklist {
  .p-picklist-buttons {
    padding: 1rem;
    .p-button {
      margin-bottom: 0.5rem;
    }
  }
  .p-picklist-list-wrapper {
    background: $white-color;
    border: 1px solid $space-grey-color;
    border-radius: 6px;
    transition: background-color 0.2s, color 0.2s, border-color 0.2s,
      box-shadow 0.2s;
    outline-color: $outline-color_1;
  }
  .p-picklist-list-wrapper.p-focus {
    outline: 0 none;
    outline-offset: 0;
    box-shadow: 0 0 0 0.2rem $focus-blue-color;
    border-color: $border-color_1;
  }
  .p-picklist-header {
    color: $color_2;
    padding: 1rem;
    font-weight: 600;
    .p-picklist-title {
      font-weight: 600;
    }
  }
  .p-picklist-filter-container {
    padding: 1rem;
    background: $white-color;
    border: 1px solid $space-grey-color;
    border-bottom: 0 none;
    .p-picklist-filter-input {
      padding-right: 1.5rem;
    }
    .p-picklist-filter-icon {
      right: 0.5rem;
      color: $color_1;
    }
  }
  .p-picklist-list {
    color: $color_2;
    padding: 0.5rem 0;
    outline: 0 none;
    &:not(:first-child) {
      border-top: 1px solid $space-grey-color;
    }
    .p-picklist-item {
      padding: 0.5rem 1rem;
      margin: 0;
      border: 0 none;
      color: $color_2;
      background: transparent;
      transition: box-shadow 0.2s;
      &:not(.p-highlight) {
        &:hover {
          background: $background-color_5;
          color: $color_2;
        }
      }
    }
    .p-picklist-item.p-focus {
      color: $color_2;
      background: $space-grey-color;
    }
    .p-picklist-item.p-highlight {
      color: $color_2;
      background: $white-color;
    }
    .p-picklist-item.p-highlight.p-focus {
      background: rgba(33, 150, 243, 0.24);
    }
    .p-picklist-empty-message {
      padding: 0.5rem 1rem;
      color: $color_2;
    }
    &:not(.cdk-drop-list-dragging) {
      .p-picklist-item {
        &:not(.p-highlight) {
          &:hover {
            background: $background-color_5;
            color: $color_2;
          }
        }
      }
    }
  }
}
.p-picklist.p-picklist-striped {
  .p-picklist-list {
    .p-picklist-item {
      &:nth-child(even) {
        background: $background-color_5;
        &:hover {
          background: $background-color_5;
        }
      }
    }
  }
}
.p-picklist-item.cdk-drag-preview {
  padding: 0.5rem 1rem;
  box-shadow: 0 2px 4px -1px rgba(0, 0, 0, 0.2), 0 4px 5px 0 rgba(0, 0, 0, 0.14),
    0 1px 10px 0 rgba(0, 0, 0, 0.12);
  border: 0 none;
  color: $color_2;
  background: $white-color;
  margin: 0;
}
.p-timeline {
  .p-timeline-event-marker {
    border: 2px solid $dark-blue-color;
    border-radius: 50%;
    width: 1rem;
    height: 1rem;
    background-color: $background-color_3;
  }
  .p-timeline-event-connector {
    background-color: $background-color_7;
  }
}
.p-timeline.p-timeline-vertical {
  .p-timeline-event-opposite {
    padding: 0 1rem;
  }
  .p-timeline-event-content {
    padding: 0 1rem;
  }
  .p-timeline-event-connector {
    width: 2px;
  }
}
.p-timeline.p-timeline-horizontal {
  .p-timeline-event-opposite {
    padding: 1rem 0;
  }
  .p-timeline-event-content {
    padding: 1rem 0;
  }
  .p-timeline-event-connector {
    height: 2px;
  }
}
.p-tree {
  border: 1px solid $space-grey-color;
  background: $white-color;
  color: $color_2;
  padding: 1rem;
  border-radius: 6px;
  .p-tree-container {
    .p-treenode {
      padding: 0.143rem;
      outline: 0 none;
      &:focus {
        > .p-treenode-content {
          outline: 0 none;
          outline-offset: 0;
          box-shadow: inset 0 0 0 0.15rem $focus-blue-color;
        }
      }
      .p-treenode-content {
        border-radius: 6px;
        transition: box-shadow 0.2s;
        padding: 0;
        .p-tree-toggler {
          margin-right: 0.5rem;
          width: 2rem;
          height: 2rem;
          color: $color_1;
          border: 0 none;
          background: transparent;
          border-radius: 50%;
          transition: background-color 0.2s, color 0.2s, box-shadow 0.2s;
          &:enabled {
            &:hover {
              color: $color_2;
              border-color: $border-color_3;
              background: $background-color_5;
            }
          }
          &:focus-visible {
            outline: 0 none;
            outline-offset: 0;
            box-shadow: 0 0 0 0.2rem $focus-blue-color;
          }
        }
        .p-treenode-icon {
          margin-right: 0.5rem;
          color: $color_1;
        }
        .p-checkbox {
          margin-right: 0.5rem;
          .p-indeterminate {
            .p-checkbox-icon {
              color: $color_2;
            }
          }
        }
      }
      .p-treenode-content.p-highlight {
        background: $white-color;
        color: $color_2;
        .p-tree-toggler {
          color: $color_2;
          &:hover {
            color: $color_2;
          }
        }
        .p-treenode-icon {
          color: $color_2;
          &:hover {
            color: $color_2;
          }
        }
      }
      .p-treenode-content.p-treenode-selectable {
        &:not(.p-highlight) {
          &:hover {
            background: $background-color_5;
            color: $color_2;
          }
        }
      }
      .p-treenode-content.p-treenode-dragover {
        background: $background-color_5;
        color: $color_2;
      }
    }
  }
  .p-tree-filter-container {
    margin-bottom: 0.5rem;
    .p-tree-filter {
      width: 100%;
      padding-right: 1.5rem;
    }
    .p-tree-filter-icon {
      right: 0.5rem;
      color: $color_1;
    }
  }
  .p-treenode-children {
    padding: 0 0 0 1rem;
  }
  .p-tree-loading-icon {
    font-size: 2rem;
  }
  .p-tree-loading-icon.p-icon {
    width: 2rem;
    height: 2rem;
  }
  .p-treenode-droppoint.p-treenode-droppoint-active {
    background-color: $background-color_6;
  }
}
.p-tree.p-tree-horizontal {
  .p-treenode {
    .p-treenode-content {
      border-radius: 6px;
      border: 1px solid $space-grey-color;
      background-color: $background-color_3;
      color: $color_2;
      padding: 0;
      transition: box-shadow 0.2s;
      .p-tree-toggler {
        margin-right: 0.5rem;
      }
      .p-treenode-icon {
        color: $color_1;
        margin-right: 0.5rem;
      }
      .p-checkbox {
        margin-right: 0.5rem;
      }
      .p-treenode-label {
        &:not(.p-highlight) {
          &:hover {
            background-color: $background-color_8;
            color: $color_14;
          }
        }
      }
      &:focus {
        outline: 0 none;
        outline-offset: 0;
        box-shadow: 0 0 0 0.2rem $focus-blue-color;
      }
    }
    .p-treenode-content.p-highlight {
      background-color: $background-color_3;
      color: $color_2;
      .p-treenode-icon {
        color: $color_2;
      }
    }
    .p-treenode-content.p-treenode-selectable {
      &:not(.p-highlight) {
        &:hover {
          background: $background-color_5;
          color: $color_2;
        }
      }
    }
  }
}
.p-treetable {
  .p-paginator-top {
    border-width: 0 0 1px 0;
    border-radius: 0;
  }
  .p-paginator-bottom {
    border-width: 0 0 1px 0;
    border-radius: 0;
  }
  .p-treetable-header {
    background: $light-grey-color;
    color: $color_2;
    border: 1px solid$background-color_5;
    border-width: 1px 0 1px 0;
    padding: 1rem 1rem;
    font-weight: 600;
  }
  .p-treetable-footer {
    background: $light-grey-color;
    color: $color_2;
    border: 1px solid$background-color_5;
    border-width: 0 0 1px 0;
    padding: 1rem 1rem;
    font-weight: 600;
  }
  .p-treetable-thead {
    > tr {
      > th {
        text-align: left;
        padding: 1rem 1rem;
        border: 1px solid$background-color_5;
        border-width: 0 0 1px 0;
        font-weight: 600;
        color: $color_2;
        background: $light-grey-color;
        transition: box-shadow 0.2s;
      }
    }
  }
  .p-treetable-tfoot {
    > tr {
      > td {
        text-align: left;
        padding: 1rem 1rem;
        border: 1px solid$background-color_5;
        border-width: 0 0 1px 0;
        font-weight: 600;
        color: $color_2;
        background: $light-grey-color;
      }
    }
  }
  .p-sortable-column {
    outline-color: $outline-color_2;
    .p-sortable-column-icon {
      color: $color_1;
      margin-left: 0.5rem;
    }
    .p-sortable-column-badge {
      border-radius: 50%;
      height: 1.143rem;
      min-width: 1.143rem;
      line-height: 1.143rem;
      color: $color_2;
      background: $white-color;
      margin-left: 0.5rem;
    }
    &:not(.p-highlight) {
      &:hover {
        background: $background-color_5;
        color: $color_2;
        .p-sortable-column-icon {
          color: $color_1;
        }
      }
    }
  }
  .p-sortable-column.p-highlight {
    background: $light-grey-color;
    color: $color_5;
    .p-sortable-column-icon {
      color: $color_5;
    }
  }
  .p-treetable-tbody {
    > tr {
      background: $white-color;
      color: $color_2;
      transition: box-shadow 0.2s;
      > td {
        text-align: left;
        border: 1px solid$background-color_5;
        border-width: 0 0 1px 0;
        padding: 1rem 1rem;
        .p-treetable-toggler {
          width: 2rem;
          height: 2rem;
          color: $color_1;
          border: 0 none;
          background: transparent;
          border-radius: 50%;
          transition: background-color 0.2s, color 0.2s, box-shadow 0.2s;
          margin-right: 0.5rem;
          &:enabled {
            &:hover {
              color: $color_2;
              border-color: $border-color_3;
              background: $background-color_5;
            }
          }
          &:focus-visible {
            outline: 0 none;
            outline-offset: 0;
            box-shadow: 0 0 0 0.2rem $focus-blue-color;
          }
        }
        .p-treetable-toggler.p-icon {
          width: 2rem;
          height: 2rem;
        }
        p-treetablecheckbox {
          .p-checkbox {
            margin-right: 0.5rem;
            .p-indeterminate {
              .p-checkbox-icon {
                color: $color_2;
              }
            }
          }
        }
      }
      &:focus-visible {
        outline: 0.15rem solid $focus-blue-color;
        outline-offset: -0.15rem;
      }
    }
    > tr.p-highlight {
      background: $white-color;
      color: $color_2;
      .p-treetable-toggler {
        color: $color_2;
        &:hover {
          color: $color_2;
        }
      }
    }
  }
  .p-column-resizer-helper {
    background: $dark-blue-color;
  }
  .p-treetable-scrollable-header {
    background: $light-grey-color;
  }
  .p-treetable-scrollable-footer {
    background: $light-grey-color;
  }
  .p-treetable-loading-icon {
    font-size: 2rem;
  }
  .p-treetable-loading-icon.p-icon {
    width: 2rem;
    height: 2rem;
  }
}
.p-treetable.p-treetable-hoverable-rows {
  .p-treetable-tbody {
    > tr {
      &:not(.p-highlight) {
        &:hover {
          background: $background-color_5;
          color: $color_2;
          .p-treetable-toggler {
            color: $color_2;
          }
        }
      }
    }
  }
}
.p-treetable.p-treetable-gridlines {
  .p-datatable-header {
    border-width: 1px 1px 0 1px;
  }
  .p-treetable-footer {
    border-width: 0 1px 1px 1px;
  }
  .p-treetable-top {
    border-width: 0 1px 0 1px;
  }
  .p-treetable-bottom {
    border-width: 0 1px 1px 1px;
  }
  .p-treetable-thead {
    > tr {
      > th {
        border-width: 1px;
      }
    }
  }
  .p-treetable-tbody {
    > tr {
      > td {
        border-width: 1px;
      }
    }
  }
  .p-treetable-tfoot {
    > tr {
      > td {
        border-width: 1px;
      }
    }
  }
}
.p-treetable.p-treetable-sm {
  .p-treetable-header {
    padding: 0.875rem 0.875rem;
  }
  .p-treetable-thead {
    > tr {
      > th {
        padding: 0.5rem 0.5rem;
      }
    }
  }
  .p-treetable-tbody {
    > tr {
      > td {
        padding: 0.5rem 0.5rem;
      }
    }
  }
  .p-treetable-tfoot {
    > tr {
      > td {
        padding: 0.5rem 0.5rem;
      }
    }
  }
  .p-treetable-footer {
    padding: 0.5rem 0.5rem;
  }
}
.p-treetable.p-treetable-lg {
  .p-treetable-header {
    padding: 1.25rem 1.25rem;
  }
  .p-treetable-thead {
    > tr {
      > th {
        padding: 1.25rem 1.25rem;
      }
    }
  }
  .p-treetable-tbody {
    > tr {
      > td {
        padding: 1.25rem 1.25rem;
      }
    }
  }
  .p-treetable-tfoot {
    > tr {
      > td {
        padding: 1.25rem 1.25rem;
      }
    }
  }
  .p-treetable-footer {
    padding: 1.25rem 1.25rem;
  }
}
.p-virtualscroller {
  .p-virtualscroller-header {
    background: $light-grey-color;
    color: $color_2;
    border: 1px solid$background-color_5;
    border-width: 1px 0 1px 0;
    padding: 1rem 1rem;
    font-weight: 600;
  }
  .p-virtualscroller-content {
    background: $white-color;
    color: $color_2;
    border: 0 none;
    padding: 0;
  }
  .p-virtualscroller-footer {
    background: $light-grey-color;
    color: $color_2;
    border: 1px solid$background-color_5;
    border-width: 0 0 1px 0;
    padding: 1rem 1rem;
    font-weight: 600;
    border-bottom-left-radius: 6px;
    border-bottom-right-radius: 6px;
  }
}
.p-accordion {
  .p-accordion-header {
    .p-accordion-header-link {
      padding: 1rem;
      border: 1px solid $space-grey-color;
      color: $color_2;
      background: $white-color;
      font-weight: 600;
      border-radius: 6px;
      transition: box-shadow 0.2s;
      display: flex;
      flex-direction: row-reverse;
      justify-content: space-between;
      .p-accordion-toggle-icon {
        margin-right: 0.5rem;
      }
    }
    &:not(.p-disabled) {
      .p-accordion-header-link {
        &:focus-visible {
          outline: 0 none;
          outline-offset: 0;
          box-shadow: inset 0 0 0 0.2rem $focus-blue-color;
        }
      }
    }
    &:not(.p-highlight) {
      &:not(.p-disabled) {
        &:hover {
          .p-accordion-header-link {
            background: $background-color_5;
            border-color: $border-color_14;
            color: $color_2;
          }
        }
      }
    }
    &:not(.p-disabled).p-highlight {
      .p-accordion-header-link {
        background: $light-grey-color;
        border-color: $border-color_14;
        color: $color_2;
        border-bottom-right-radius: 0;
        border-bottom-left-radius: 0;
      }
      &:hover {
        .p-accordion-header-link {
          border-color: $border-color_14;
          background: $background-color_5;
          color: $color_2;
        }
      }
    }
  }

  .p-accordion-content {
    padding: 1rem;
    border: 1px solid $space-grey-color;
    background: $white-color;
    color: $color_2;
    border-top: 0;
    border-top-right-radius: 0;
    border-top-left-radius: 0;
    border-bottom-right-radius: 6px;
    border-bottom-left-radius: 6px;
  }
  p-accordiontab {
    .p-accordion-tab {
      margin-bottom: 0;
    }
    .p-accordion-header {
      .p-accordion-header-link {
        border-radius: 0;
      }
    }
    .p-accordion-content {
      border-radius: 0;
    }
    &:not(:first-child) {
      .p-accordion-header {
        .p-accordion-header-link {
          border-top: 0 none;
        }
        &:not(.p-highlight) {
          &:not(.p-disabled) {
            &:hover {
              .p-accordion-header-link {
                border-top: 0 none;
              }
            }
          }
        }
        &:not(.p-disabled).p-highlight {
          &:hover {
            .p-accordion-header-link {
              border-top: 0 none;
            }
          }
        }
      }
    }
    &:first-child {
      .p-accordion-header {
        .p-accordion-header-link {
          border-top-right-radius: 6px;
          border-top-left-radius: 6px;
        }
      }
    }
    &:last-child {
      .p-accordion-header {
        &:not(.p-highlight) {
          .p-accordion-header-link {
            border-bottom-right-radius: 6px;
            border-bottom-left-radius: 6px;
          }
        }
      }
      .p-accordion-content {
        border-bottom-right-radius: 6px;
        border-bottom-left-radius: 6px;
      }
    }
  }
}
.p-card {
  background: $white-color;
  color: $color_2;
  box-shadow: 0 2px 1px -1px rgba(0, 0, 0, 0.2), 0 1px 1px 0 rgba(0, 0, 0, 0.14),
    0 1px 3px 0 rgba(0, 0, 0, 0.12);
  border-radius: 6px;
  .p-card-body {
    padding: 1rem;
  }
  .p-card-title {
    font-size: 1.5rem;
    font-weight: 700;
    margin-bottom: 0.5rem;
  }
  .p-card-subtitle {
    font-weight: 400;
    margin-bottom: 0.5rem;
    color: $color_1;
  }
  .p-card-content {
    padding: 1rem 0;
  }
  .p-card-footer {
    padding: 1rem 0 0 0;
  }
}
.p-divider {
  .p-divider-content {
    background-color: $background-color_3;
  }
}
.p-divider.p-divider-horizontal {
  margin: 1rem 0;
  padding: 0 1rem;
  &:before {
    border-top: 1px $space-grey-color;
  }
  .p-divider-content {
    padding: 0 0.5rem;
  }
}
.p-divider.p-divider-vertical {
  margin: 0 1rem;
  padding: 1rem 0;
  &:before {
    border-left: 1px $space-grey-color;
  }
  .p-divider-content {
    padding: 0.5rem 0;
  }
}
.p-fieldset {
  border: 1px solid $space-grey-color;
  background: $white-color;
  color: $color_2;
  border-radius: 6px;
  .p-fieldset-legend {
    padding: 1rem;
    border: 1px solid $space-grey-color;
    color: $color_2;
    background: $light-grey-color;
    font-weight: 600;
    border-radius: 6px;
  }
  .p-fieldset-content {
    padding: 1rem;
  }
}
.p-fieldset.p-fieldset-toggleable {
  .p-fieldset-legend {
    padding: 0;
    transition: background-color 0.2s, color 0.2s, box-shadow 0.2s;
    a {
      padding: 1rem;
      color: $color_2;
      border-radius: 6px;
      transition: box-shadow 0.2s;
      .p-fieldset-toggler {
        margin-right: 0.5rem;
      }
      &:focus-visible {
        outline: 0 none;
        outline-offset: 0;
        box-shadow: 0 0 0 0.2rem $focus-blue-color;
      }
    }
    &:hover {
      background: $background-color_5;
      border-color: $border-color_14;
      color: $color_2;
    }
  }
}
.p-panel {
  .p-panel-header {
    border: 1px solid $space-grey-color;
    padding: 1rem;
    background: $light-grey-color;
    color: $color_2;
    border-top-right-radius: 6px;
    border-top-left-radius: 6px;
    .p-panel-title {
      font-weight: 600;
    }
    .p-panel-header-icon {
      width: 2rem;
      height: 2rem;
      color: $color_1;
      border: 0 none;
      background: transparent;
      border-radius: 50%;
      transition: background-color 0.2s, color 0.2s, box-shadow 0.2s;
      &:enabled {
        &:hover {
          color: $color_2;
          border-color: $border-color_3;
          background: $background-color_5;
        }
      }
      &:focus-visible {
        outline: 0 none;
        outline-offset: 0;
        box-shadow: 0 0 0 0.2rem $focus-blue-color;
      }
    }
  }
  .p-panel-content {
    padding: 1rem;
    border: 1px solid $space-grey-color;
    background: $white-color;
    color: $color_2;
    border-top: 0 none;
    &:last-child {
      border-bottom-right-radius: 6px;
      border-bottom-left-radius: 6px;
    }
  }
  .p-panel-footer {
    padding: 0.5rem 1rem;
    border: 1px solid $space-grey-color;
    background: $white-color;
    color: $color_2;
    border-bottom-right-radius: 6px;
    border-bottom-left-radius: 6px;
    border-top: 0 none;
  }
  .p-panel-icons-end {
    order: 2;
    margin-left: auto;
  }
  .p-panel-icons-start {
    order: 0;
    margin-right: 0.5rem;
  }
  .p-panel-icons-center {
    order: 2;
    width: 100%;
    text-align: center;
  }
}
.p-panel.p-panel-toggleable {
  .p-panel-header {
    padding: 0.5rem 1rem;
  }
}
.p-scrollpanel {
  .p-scrollpanel-bar {
    background: $light-grey-color;
    border: 0 none;
    transition: background-color 0.2s, color 0.2s, border-color 0.2s,
      box-shadow 0.2s;
    &:focus-visible {
      outline: 0 none;
      outline-offset: 0;
      box-shadow: 0 0 0 0.2rem $focus-blue-color;
    }
  }
}
.p-splitter {
  border: 1px solid $space-grey-color;
  background: $white-color;
  border-radius: 6px;
  color: $color_2;
  .p-splitter-gutter {
    transition: background-color 0.2s, color 0.2s, box-shadow 0.2s;
    background: $light-grey-color;
    .p-splitter-gutter-handle {
      background: $space-grey-color;
      &:focus-visible {
        outline: 0 none;
        outline-offset: 0;
        box-shadow: 0 0 0 0.2rem $focus-blue-color;
      }
    }
  }
  .p-splitter-gutter-resizing {
    background: $space-grey-color;
  }
}
.p-tabview {
  .p-tabview-nav-content {
    scroll-padding-inline: 2.357rem;
  }
  .p-tabview-nav {
    background: $white-color;
    border: 1px solid $space-grey-color;
    border-width: 0 0 2px 0;
    li {
      margin-right: 0;
      .p-tabview-nav-link {
        border: solid $space-grey-color;
        border-width: 0 0 2px 0;
        border-color: $border-color_16;
        background: $white-color;
        color: $color_1;
        padding: 1rem;
        font-weight: 600;
        border-top-right-radius: 6px;
        border-top-left-radius: 6px;
        transition: box-shadow 0.2s;
        margin: 0 0 -2px 0;
        &:not(.p-disabled) {
          &:focus-visible {
            outline: 0 none;
            outline-offset: 0;
            box-shadow: inset 0 0 0 0.2rem $focus-blue-color;
          }
        }
      }
      &:not(.p-highlight) {
        &:not(.p-disabled) {
          &:hover {
            .p-tabview-nav-link {
              background: $white-color;
              border-color: $border-color_7;
              color: $color_1;
            }
          }
        }
      }
    }
    li.p-highlight {
      .p-tabview-nav-link {
        background: $white-color;
        border-color: $border-color_1;
        color: $color_5;
      }
    }
  }
  .p-tabview-left-icon {
    margin-right: 0.5rem;
  }
  .p-tabview-right-icon {
    margin-left: 0.5rem;
  }
  .p-tabview-close {
    margin-left: 0.5rem;
  }
  .p-tabview-nav-btn.p-link {
    background: $white-color;
    color: $color_5;
    width: 2.357rem;
    box-shadow: 0 3px 1px -2px rgba(0, 0, 0, 0.2),
      0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 1px 5px 0 rgba(0, 0, 0, 0.12);
    border-radius: 0;
    &:focus-visible {
      outline: 0 none;
      outline-offset: 0;
      box-shadow: inset 0 0 0 0.2rem $focus-blue-color;
    }
  }
  .p-tabview-panels {
    background: $white-color;
    padding: 1rem;
    border: 0 none;
    color: $color_2;
    border-bottom-right-radius: 6px;
    border-bottom-left-radius: 6px;
  }
}
.p-toolbar {
  background: $light-grey-color;
  border: 1px solid $space-grey-color;
  padding: 1rem;
  border-radius: 6px;
  gap: 0.5rem;
  .p-toolbar-separator {
    margin: 0 0.5rem;
  }
}
.p-confirm-popup {
  background: $white-color;
  color: $color_2;
  border: 0 none;
  border-radius: 6px;
  box-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2),
    0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12);
  .p-confirm-popup-content {
    padding: 1rem;
  }
  .p-confirm-popup-footer {
    text-align: right;
    padding: 0.5rem 1rem;
    button {
      margin: 0 0.5rem 0 0;
      width: auto;
      &:last-child {
        margin: 0;
      }
    }
  }
  &:after {
    border: solid transparent;
    border-color: $border-color_17;
    border-bottom-color: $border-bottom-color_1;
  }
  &:before {
    border: solid transparent;
    border-color: $border-color_17;
    border-bottom-color: $border-bottom-color_1;
  }
  .p-confirm-popup-icon {
    font-size: 1.5rem;
  }
  .p-confirm-popup-icon.p-icon {
    width: 1.5rem;
    height: 1.5rem;
  }
  .p-confirm-popup-message {
    margin-left: 1rem;
  }
}
.p-confirm-popup.p-confirm-popup-flipped {
  &:after {
    border-top-color: $border-top-color_1;
  }
  &:before {
    border-top-color: $border-top-color_1;
  }
}
.p-dialog {
  border-radius: 6px;
  box-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2),
    0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12);
  .p-dialog-header {
    border-bottom: 1px solid $single-light-gray-color;
    background: $white-color;
    color: $color_2;
    padding: 1rem 1.5rem;
    border-top-right-radius: 6px;
    border-top-left-radius: 6px;
    .p-dialog-title {
      font-weight: 600;
      font-size: 1.25rem;
    }
    .p-dialog-header-icon {
      width: 2rem;
      height: 2rem;
      color: $color_1;
      border: 0 none;
      background: transparent;
      border-radius: 50%;
      transition: background-color 0.2s, color 0.2s, box-shadow 0.2s;
      margin-right: 0.5rem;
      &:enabled {
        &:hover {
          color: $color_2;
          border-color: $border-color_3;
          background: $background-color_5;
        }
      }
      &:focus-visible {
        outline: 0 none;
        outline-offset: 0;
        box-shadow: 0 0 0 0.2rem $focus-blue-color;
      }
      &:last-child {
        margin-right: 0;
      }
    }
  }
  .p-dialog-content {
    background: $white-color;
    color: $color_2;
    padding: 1rem 1.5rem 1rem 1.5rem;
    &:last-of-type {
      border-bottom-right-radius: 0px;
      border-bottom-left-radius: 0px;
    }
  }
  .p-dialog-footer {
    border-radius: 0rem 0rem 0.375rem 0.375rem;
    background: var(--input-background, #fff);
    box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2),
      0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
    background: $white-color;
    color: $color_2;
    padding: 1rem 1.5rem 1rem 1.5rem;
    text-align: right;
    border-bottom-right-radius: 6px;
    border-bottom-left-radius: 6px;
    button {
      margin: 0 0.5rem 0 0;
      width: auto;
    }
  }
}
.p-dialog.p-confirm-dialog {
  height: 230px !important;
  .p-confirm-dialog-icon {
    font-size: 2rem;
  }
  .p-confirm-dialog-icon.p-icon {
    width: 2rem;
    height: 2rem;
  }
  .p-confirm-dialog-message {
    margin-left: 1rem;
  }
}
.p-overlaypanel {
  background: $white-color;
  color: $color_2;
  border: 0 none;
  border-radius: 6px;
  box-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2),
    0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12);
  .p-overlaypanel-content {
    padding: 1rem;
    .p-avatar img {
      width: 100%;
      height: 100%;
      border-radius: 6px;
    }
    .p-button {
      background-color: $hot-green-color;
      border: none;
      width: 78px;
      height: 24px;
      font-size: 10px;
      margin: 0px;
      padding: 0px;
    }
  }
  .p-overlaypanel-close {
    background: $dark-blue-color;
    color: $color_6;
    width: 2rem;
    height: 2rem;
    transition: background-color 0.2s, color 0.2s, box-shadow 0.2s;
    border-radius: 50%;
    position: absolute;
    top: -1rem;
    right: -1rem;
    &:enabled {
      &:hover {
        background: $royal-blue-color;
        color: $color_6;
      }
    }
  }
  &:after {
    border: solid transparent;
    border-color: $border-color_17;
    border-bottom-color: $border-bottom-color_1;
  }
  &:before {
    border: solid transparent;
    border-color: $border-color_17;
    border-bottom-color: $border-bottom-color_2;
  }
}
.p-overlaypanel.p-overlaypanel-flipped {
  &:after {
    border-top-color: $border-top-color_1;
  }
  &:before {
    border-top-color: $border-top-color_1;
  }
}
.p-sidebar {
  background: $white-color;
  color: $color_2;
  border: 0 none;
  box-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2),
    0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12);
  .p-sidebar-header {
    padding: 1rem;
    .p-sidebar-close {
      width: 2rem;
      height: 2rem;
      color: $color_1;
      border: 0 none;
      background: transparent;
      border-radius: 50%;
      transition: background-color 0.2s, color 0.2s, box-shadow 0.2s;
      &:enabled {
        &:hover {
          color: $color_2;
          border-color: $border-color_3;
          background: $background-color_5;
        }
      }
      &:focus-visible {
        outline: 0 none;
        outline-offset: 0;
        box-shadow: 0 0 0 0.2rem $focus-blue-color;
      }
    }
    .p-sidebar-icon {
      width: 2rem;
      height: 2rem;
      color: $color_1;
      border: 0 none;
      background: transparent;
      border-radius: 50%;
      transition: background-color 0.2s, color 0.2s, box-shadow 0.2s;
      &:enabled {
        &:hover {
          color: $color_2;
          border-color: $border-color_3;
          background: $background-color_5;
        }
      }
      &:focus-visible {
        outline: 0 none;
        outline-offset: 0;
        box-shadow: 0 0 0 0.2rem $focus-blue-color;
      }
    }
    + {
      .p-sidebar-content {
        padding-top: 0;
      }
    }
  }
  .p-sidebar-content {
    padding: 1rem;
  }
  .p-sidebar-footer {
    padding: 1rem;
  }
}
.p-tooltip {
  .p-tooltip-text {
    background: $text-gray-color;
    color: $color_6;
    padding: 0.5rem 0.5rem;
    box-shadow: 0 2px 4px -1px rgba(0, 0, 0, 0.2),
      0 4px 5px 0 rgba(0, 0, 0, 0.14), 0 1px 10px 0 rgba(0, 0, 0, 0.12);
    border-radius: 6px;
  }
}
.p-tooltip.p-tooltip-right {
  .p-tooltip-arrow {
    border-right-color: $border-right-color_1;
  }
}
.p-tooltip.p-tooltip-left {
  .p-tooltip-arrow {
    border-left-color: $border-left-color_1;
  }
}
.p-tooltip.p-tooltip-top {
  .p-tooltip-arrow {
    border-top-color: $border-top-color_2;
  }
}
.p-tooltip.p-tooltip-bottom {
  .p-tooltip-arrow {
    border-bottom-color: $border-bottom-color_3;
  }
}
.p-fileupload {
  .p-fileupload-buttonbar {
    background: $light-grey-color;
    padding: 1rem;
    border: 1px solid $space-grey-color;
    color: $color_2;
    border-bottom: 0 none;
    border-top-right-radius: 6px;
    border-top-left-radius: 6px;
    .p-button {
      margin-right: 0.5rem;
    }
    .p-button.p-fileupload-choose.p-focus {
      outline: 0 none;
      outline-offset: 0;
      box-shadow: 0 0 0 0.2rem $focus-blue-color;
    }
  }
  .p-fileupload-content {
    background: $white-color;
    padding: 2rem 1rem;
    border: 1px solid $space-grey-color;
    color: $color_2;
    border-bottom-right-radius: 6px;
    border-bottom-left-radius: 6px;
  }
  .p-fileupload-content.p-fileupload-highlight {
    border-color: $border-color_18;
    border-style: dashed;
    background-color: $background-color_3;
  }
  .p-progressbar {
    height: 0.25rem;
  }
  .p-fileupload-row {
    > div {
      padding: 1rem 1rem;
    }
  }
}
.p-fileupload.p-fileupload-advanced {
  .p-message {
    margin-top: 0;
  }
}
.p-fileupload-choose {
  &:not(.p-disabled) {
    &:hover {
      background: $royal-blue-color;
      color: $color_6;
      border-color: $border-color_4;
    }
    &:active {
      background: $royal-blue-color;
      color: $color_6;
      border-color: $border-color_4;
    }
  }
}
.p-breadcrumb {
  background: $white-color;
  border: 1px solid $space-grey-color;
  border-radius: 6px;
  padding: 1rem;
  .p-breadcrumb-list {
    li {
      .p-menuitem-link {
        transition: box-shadow 0.2s;
        border-radius: 6px;
        &:focus-visible {
          outline: 0 none;
          outline-offset: 0;
          box-shadow: 0 0 0 0.2rem $focus-blue-color;
        }
        .p-menuitem-text {
          color: $color_6;
        }
        .p-menuitem-icon {
          color: $color_1;
        }
      }
      &:last-child {
        .p-menuitem-text {
          color: $color_2;
        }
        .p-menuitem-icon {
          color: $color_1;
        }
      }
    }
    li.p-menuitem-separator {
      margin: 0 0.5rem 0 0.5rem;
      color: $color_2;
    }
  }
}
.p-contextmenu {
  padding: 0.25rem 0;
  background: $white-color;
  color: $color_2;
  border: 0 none;
  box-shadow: 0 2px 4px -1px rgba(0, 0, 0, 0.2), 0 4px 5px 0 rgba(0, 0, 0, 0.14),
    0 1px 10px 0 rgba(0, 0, 0, 0.12);
  border-radius: 6px;
  width: 12.5rem;
  .p-contextmenu-root-list {
    outline: 0 none;
  }
  .p-submenu-list {
    padding: 0.25rem 0;
    background: $white-color;
    border: 0 none;
    box-shadow: 0 2px 4px -1px rgba(0, 0, 0, 0.2),
      0 4px 5px 0 rgba(0, 0, 0, 0.14), 0 1px 10px 0 rgba(0, 0, 0, 0.12);
    border-radius: 6px;
  }
  .p-menuitem {
    > .p-menuitem-content {
      color: $color_2;
      transition: box-shadow 0.2s;
      border-radius: 0;
      .p-menuitem-link {
        color: $color_2;
        padding: 0.5rem;
        user-select: none;
        .p-menuitem-text {
          color: $color_2;
        }
        .p-menuitem-icon {
          color: $color_1;
          margin-right: 0.5rem;
        }
        .p-submenu-icon {
          color: $color_1;
        }
      }
    }
    &:not(.p-highlight) {
      &:not(.p-disabled).p-focus {
        > .p-menuitem-content {
          color: $color_2;
          background: $space-grey-color;
          .p-menuitem-link {
            .p-menuitem-text {
              color: $color_2;
            }
            .p-menuitem-icon {
              color: $color_2;
            }
            .p-submenu-icon {
              color: $color_2;
            }
          }
        }
      }
      &:not(.p-disabled) {
        > .p-menuitem-content {
          &:hover {
            color: $color_2;
            background: $background-color_5;
            .p-menuitem-link {
              .p-menuitem-text {
                color: $color_2;
              }
              .p-menuitem-icon {
                color: $color_1;
              }
              .p-submenu-icon {
                color: $color_1;
              }
            }
          }
        }
      }
    }
  }
  .p-menuitem.p-highlight {
    > .p-menuitem-content {
      color: $color_2;
      background: $background-color_5;
      .p-menuitem-link {
        .p-menuitem-text {
          color: $color_2;
        }
        .p-menuitem-icon {
          color: $color_1;
        }
        .p-submenu-icon {
          color: $color_1;
        }
      }
    }
  }
  .p-menuitem.p-highlight.p-focus {
    > .p-menuitem-content {
      background: $background-color_5;
    }
  }
  .p-menuitem-separator {
    border-top: 1px solid $space-grey-color;
    margin: 0.25rem 0;
  }
  .p-submenu-icon {
    font-size: 0.875rem;
  }
  .p-submenu-icon.p-icon {
    width: 0.875rem;
    height: 0.875rem;
  }
}
.p-dock {
  .p-dock-list-container {
    background: rgba(255, 255, 255, 0.1);
    border: 1px solid rgba(255, 255, 255, 0.2);
    padding: 0.5rem 0.5rem;
    border-radius: 0.5rem;
    .p-dock-list {
      outline: 0 none;
    }
  }
  .p-dock-item {
    padding: 0.5rem;
    border-radius: 6px;
  }
  .p-dock-item.p-focus {
    outline: 0 none;
    outline-offset: 0;
    box-shadow: inset 0 0 0 0.15rem $focus-blue-color;
  }
  .p-dock-link {
    width: 4rem;
    height: 4rem;
  }
}
.p-dock.p-dock-top {
  .p-dock-item-second-prev {
    margin: 0 0.9rem;
  }
  .p-dock-item-second-next {
    margin: 0 0.9rem;
  }
  .p-dock-item-prev {
    margin: 0 1.3rem;
  }
  .p-dock-item-next {
    margin: 0 1.3rem;
  }
  .p-dock-item-current {
    margin: 0 1.5rem;
  }
}
.p-dock.p-dock-bottom {
  .p-dock-item-second-prev {
    margin: 0 0.9rem;
  }
  .p-dock-item-second-next {
    margin: 0 0.9rem;
  }
  .p-dock-item-prev {
    margin: 0 1.3rem;
  }
  .p-dock-item-next {
    margin: 0 1.3rem;
  }
  .p-dock-item-current {
    margin: 0 1.5rem;
  }
}
.p-dock.p-dock-left {
  .p-dock-item-second-prev {
    margin: 0.9rem 0;
  }
  .p-dock-item-second-next {
    margin: 0.9rem 0;
  }
  .p-dock-item-prev {
    margin: 1.3rem 0;
  }
  .p-dock-item-next {
    margin: 1.3rem 0;
  }
  .p-dock-item-current {
    margin: 1.5rem 0;
  }
}
.p-dock.p-dock-right {
  .p-dock-item-second-prev {
    margin: 0.9rem 0;
  }
  .p-dock-item-second-next {
    margin: 0.9rem 0;
  }
  .p-dock-item-prev {
    margin: 1.3rem 0;
  }
  .p-dock-item-next {
    margin: 1.3rem 0;
  }
  .p-dock-item-current {
    margin: 1.5rem 0;
  }
}
.p-megamenu {
  padding: 0.5rem;
  background: $light-grey-color;
  color: $color_2;
  border: 1px solid $space-grey-color;
  border-radius: 6px;
  .p-megamenu-root-list {
    outline: 0 none;
  }
  .p-menuitem {
    > .p-menuitem-content {
      color: $color_2;
      transition: box-shadow 0.2s;
      border-radius: 0;
      .p-menuitem-link {
        color: $color_2;
        padding: 0.5rem;
        user-select: none;
        .p-menuitem-text {
          color: $color_2;
        }
        .p-menuitem-icon {
          color: $color_1;
          margin-right: 0.5rem;
        }
        .p-submenu-icon {
          color: $color_1;
        }
      }
    }
    &:not(.p-highlight) {
      &:not(.p-disabled).p-focus {
        > .p-menuitem-content {
          color: $color_2;
          background: $space-grey-color;
          .p-menuitem-link {
            .p-menuitem-text {
              color: $color_2;
            }
            .p-menuitem-icon {
              color: $color_2;
            }
            .p-submenu-icon {
              color: $color_2;
            }
          }
        }
      }
      &:not(.p-disabled) {
        > .p-menuitem-content {
          &:hover {
            color: $color_2;
            background: $background-color_5;
            .p-menuitem-link {
              .p-menuitem-text {
                color: $color_2;
              }
              .p-menuitem-icon {
                color: $color_1;
              }
              .p-submenu-icon {
                color: $color_1;
              }
            }
          }
        }
      }
    }
  }
  .p-menuitem.p-highlight {
    > .p-menuitem-content {
      color: $color_2;
      background: $background-color_5;
      .p-menuitem-link {
        .p-menuitem-text {
          color: $color_2;
        }
        .p-menuitem-icon {
          color: $color_1;
        }
        .p-submenu-icon {
          color: $color_1;
        }
      }
    }
  }
  .p-menuitem.p-highlight.p-focus {
    > .p-menuitem-content {
      background: $background-color_5;
    }
  }
  .p-megamenu-panel {
    background: $white-color;
    color: $color_2;
    border: 0 none;
    box-shadow: 0 2px 4px -1px rgba(0, 0, 0, 0.2),
      0 4px 5px 0 rgba(0, 0, 0, 0.14), 0 1px 10px 0 rgba(0, 0, 0, 0.12);
  }
  .p-submenu-header {
    margin: 0;
    padding: 0.75rem 1rem;
    color: $color_2;
    background: $white-color;
    font-weight: 600;
    border-top-right-radius: 6px;
    border-top-left-radius: 6px;
  }
  .p-submenu-list {
    padding: 0.25rem 0;
    width: 12.5rem;
    .p-menuitem-separator {
      border-top: 1px solid $space-grey-color;
      margin: 0.25rem 0;
    }
  }
}
.p-megamenu.p-megamenu-vertical {
  width: 12.5rem;
  padding: 0.25rem 0;
}
.p-megamenu.p-megamenu-horizontal {
  .p-megamenu-root-list {
    > .p-menuitem {
      > .p-menuitem-content {
        color: $color_2;
        transition: box-shadow 0.2s;
        border-radius: 6px;
        .p-menuitem-link {
          padding: 0.5rem;
          user-select: none;
          .p-menuitem-text {
            color: $color_2;
          }
          .p-menuitem-icon {
            color: $color_1;
            margin-right: 0.5rem;
          }
          .p-submenu-icon {
            color: $color_1;
            margin-left: 0.5rem;
          }
        }
      }
      &:not(.p-highlight) {
        &:not(.p-disabled) {
          > .p-menuitem-content {
            &:hover {
              color: $color_2;
              background: $background-color_5;
              .p-menuitem-link {
                .p-menuitem-text {
                  color: $color_2;
                }
                .p-menuitem-icon {
                  color: $color_1;
                }
                .p-submenu-icon {
                  color: $color_1;
                }
              }
            }
          }
        }
      }
    }
  }
}
.p-menu {
  padding: 0.25rem 0;
  background: $white-color;
  color: $color_2;
  border: 1px solid $space-grey-color;
  border-radius: 6px;
  width: 12.5rem;
  .p-menuitem {
    > .p-menuitem-content {
      color: $color_2;
      transition: box-shadow 0.2s;
      border-radius: 0;
      .p-menuitem-link {
        color: $color_2;
        padding: 0.5rem;
        user-select: none;
        .p-menuitem-text {
          color: $color_2;
        }
        .p-menuitem-icon {
          color: $color_1;
          margin-right: 0.5rem;
        }
        .p-submenu-icon {
          color: $color_1;
        }
      }
    }
    &:not(.p-highlight) {
      &:not(.p-disabled).p-focus {
        > .p-menuitem-content {
          color: $color_2;
          background: $space-grey-color;
          .p-menuitem-link {
            .p-menuitem-text {
              color: $color_2;
            }
            .p-menuitem-icon {
              color: $color_2;
            }
            .p-submenu-icon {
              color: $color_2;
            }
          }
        }
      }
      &:not(.p-disabled) {
        > .p-menuitem-content {
          &:hover {
            color: $color_2;
            background: $background-color_5;
            .p-menuitem-link {
              .p-menuitem-text {
                color: $color_2;
              }
              .p-menuitem-icon {
                color: $color_1;
              }
              .p-submenu-icon {
                color: $color_1;
              }
            }
          }
        }
      }
    }
  }
  .p-menuitem.p-highlight {
    > .p-menuitem-content {
      color: $color_2;
      background: $background-color_5;
      .p-menuitem-link {
        .p-menuitem-text {
          color: $color_2;
        }
        .p-menuitem-icon {
          color: $color_1;
        }
        .p-submenu-icon {
          color: $color_1;
        }
      }
    }
  }
  .p-menuitem.p-highlight.p-focus {
    > .p-menuitem-content {
      background: $background-color_5;
    }
  }
  .p-submenu-header {
    margin: 0;
    padding: 0.75rem 1rem;
    color: $color_2;
    background: $white-color;
    font-weight: 600;
    border-top-right-radius: 0;
    border-top-left-radius: 0;
  }
  .p-menuitem-separator {
    border-top: 1px solid $space-grey-color;
    margin: 0.25rem 0;
  }
  .p-menuitem-badge {
    background: $dark-blue-color;
    color: $color_6;
    font-size: 0.75rem;
    font-weight: 700;
    min-width: 1.5rem;
    height: 1.5rem;
    line-height: 1.5rem;
    border-radius: 6px;
    margin-left: 0.5rem;
    padding-left: 0.5rem;
    padding-right: 0.5rem;
  }
}
.p-menu.p-menu-overlay {
  background: $white-color;
  border: 0 none;
  box-shadow: 0 2px 4px -1px rgba(0, 0, 0, 0.2), 0 4px 5px 0 rgba(0, 0, 0, 0.14),
    0 1px 10px 0 rgba(0, 0, 0, 0.12);
}
.p-menubar {
  background: $light-grey-color;
  color: $color_2;
  border: 1px solid $space-grey-color;
  border-radius: 0%;
  border-top: 0px;
  border-left: 0px;
  border-right: 0px;
  border-bottom: 1px solid rgba(255, 255, 255, 0.3);
  margin-bottom: 5%;
  color: var(--color) !important;
  background-color: transparent !important;
  margin-top: -9.5px;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  align-content: center;
  flex-wrap: wrap;
  flex-direction: row;
  height: 50px;
  .p-menubar-root-list {
    outline: 0 none;
    > .p-menuitem {
      > .p-menuitem-content {
        color: $color_2;
        transition: box-shadow 0.2s;
        border-radius: 6px;
        .p-menuitem-link {
          padding: 0.5rem;
          user-select: none;
          .p-menuitem-text {
            color: $color_6;
          }
          .p-menuitem-icon {
            color: $color_6;
            margin-right: 0.5rem;
          }
          .p-submenu-icon {
            color: $color_6;
            margin-left: 0.5rem;
          }
        }
      }
      &:not(.p-highlight) {
        &:not(.p-disabled) {
          > .p-menuitem-content {
            &:hover {
              color: $color_6;
              background: $transparent-white-color;
              border-radius: 6px;
              .p-menuitem-link {
                .p-menuitem-text {
                  color: $color_6;
                }
                .p-menuitem-icon {
                  color: $color_6;
                }
                .p-submenu-icon {
                  color: $color_6;
                }
              }
            }
            &:active {
              color: $color_6;
              background: $transparent-white-color;
            }
          }
        }
      }
    }
  }
  .p-menuitem {
    margin-right: 0.25rem;
    > .p-menuitem-content {
      color: $color_2;
      transition: box-shadow 0.2s;
      border-radius: 0;
      .p-menuitem-link {
        color: $color_2;
        padding: 0.5rem;
        user-select: none;
        .p-menuitem-text {
          color: $color_2;
        }
        .p-menuitem-icon {
          color: $color_2;
          margin-right: 0.5rem;
        }
        .p-submenu-icon {
          color: $color_6;
        }
      }
    }
    &:not(.p-highlight) {
      &:not(.p-disabled).p-focus {
        > .p-menuitem-content {
          color: $color_6;
          background: $transparent-white-color;
          border-radius: 6px;
          .p-menuitem-link {
            .p-menuitem-text {
              color: $color_6;
              border-radius: 6px;
            }
            .p-menuitem-icon {
              color: $color_2;
            }
            .p-submenu-icon {
              color: $color_2;
            }
          }
        }
      }
      &:not(.p-disabled) {
        > .p-menuitem-content {
          &:hover {
            color: $color_2;
            background: $background-color_5;
            .p-menuitem-link {
              .p-menuitem-text {
                color: $color_2;
              }
              .p-menuitem-icon {
                color: $color_1;
              }
              .p-submenu-icon {
                color: $color_1;
              }
            }
          }
        }
      }
    }
  }
  .p-menuitem.p-highlight {
    > .p-menuitem-content {
      color: $color_6;
      background: $transparent-white-color;
      border-radius: 6px;
      .p-menuitem-link {
        .p-menuitem-text {
          color: $color_6;
        }
        .p-menuitem-icon {
          color: $color_6;
        }
        .p-submenu-icon {
          color: $color_6;
        }
      }
    }
  }
  .p-menuitem.p-highlight.p-focus {
    > .p-menuitem-content {
      background: $transparent-white-color;
      border-radius: 6px;
    }
  }
  .p-submenu-list {
    padding: 0.25rem 0;
    background: $white-color;
    border: 0 none;
    box-shadow: 0 2px 4px -1px rgba(0, 0, 0, 0.2),
      0 4px 5px 0 rgba(0, 0, 0, 0.14), 0 1px 10px 0 rgba(0, 0, 0, 0.12);
    width: 12.5rem;
    border-radius: 6px;
    .p-menuitem-separator {
      border-top: 1px solid $border-light-gray-color;
      margin: 0.25rem 0;
    }
    .p-submenu-icon {
      font-size: 0.875rem;
    }
  }
}
.p-panelmenu {
  .p-panelmenu-header {
    outline: 0 none;
    .p-panelmenu-header-content {
      border: 1px solid $space-grey-color;
      color: $color_2;
      background: $light-grey-color;
      border-radius: 6px;
      transition: box-shadow 0.2s;
      .p-panelmenu-header-action {
        color: $color_2;
        padding: 1rem;
        font-weight: 600;
        .p-submenu-icon {
          margin-right: 0.5rem;
        }
        .p-menuitem-icon {
          margin-right: 0.5rem;
        }
      }
    }
    &:not(.p-disabled) {
      &:focus-visible {
        .p-panelmenu-header-content {
          outline: 0 none;
          outline-offset: 0;
          box-shadow: inset 0 0 0 0.2rem $focus-blue-color;
        }
      }
    }
    &:not(.p-highlight) {
      &:not(.p-disabled) {
        &:hover {
          .p-panelmenu-header-content {
            background: $background-color_5;
            border-color: $border-color_14;
            color: $color_2;
          }
        }
      }
    }
    &:not(.p-disabled).p-highlight {
      .p-panelmenu-header-content {
        background: $light-grey-color;
        border-color: $border-color_14;
        color: $color_2;
        border-bottom-right-radius: 0;
        border-bottom-left-radius: 0;
        margin-bottom: 0;
      }
      &:hover {
        .p-panelmenu-header-content {
          border-color: $border-color_14;
          background: $background-color_5;
          color: $color_2;
        }
      }
    }
  }
  .p-panelmenu-content {
    padding: 0.25rem 0;
    border: 1px solid $space-grey-color;
    background: $white-color;
    color: $color_2;
    border-top: 0;
    border-top-right-radius: 0;
    border-top-left-radius: 0;
    border-bottom-right-radius: 6px;
    border-bottom-left-radius: 6px;
    .p-panelmenu-root-list {
      outline: 0 none;
    }
    .p-menuitem {
      > .p-menuitem-content {
        color: $color_2;
        transition: box-shadow 0.2s;
        border-radius: 0;
        .p-menuitem-link {
          color: $color_2;
          padding: 0.5rem;
          user-select: none;
          .p-menuitem-text {
            color: $color_2;
          }
          .p-menuitem-icon {
            color: $color_1;
            margin-right: 0.5rem;
          }
          .p-submenu-icon {
            color: $color_1;
          }
        }
      }
      &:not(.p-highlight) {
        &:not(.p-disabled).p-focus {
          > .p-menuitem-content {
            color: $color_2;
            background: $space-grey-color;
            .p-menuitem-link {
              .p-menuitem-text {
                color: $color_2;
              }
              .p-menuitem-icon {
                color: $color_2;
              }
              .p-submenu-icon {
                color: $color_2;
              }
            }
          }
        }
        &:not(.p-disabled) {
          > .p-menuitem-content {
            &:hover {
              color: $color_2;
              background: $background-color_5;
              .p-menuitem-link {
                .p-menuitem-text {
                  color: $color_2;
                }
                .p-menuitem-icon {
                  color: $color_1;
                }
                .p-submenu-icon {
                  color: $color_1;
                }
              }
            }
          }
        }
      }
      .p-menuitem-content {
        .p-menuitem-link {
          .p-submenu-icon {
            margin-right: 0.5rem;
          }
        }
      }
    }
    .p-menuitem.p-highlight {
      > .p-menuitem-content {
        color: $color_2;
        background: $background-color_5;
        .p-menuitem-link {
          .p-menuitem-text {
            color: $color_2;
          }
          .p-menuitem-icon {
            color: $color_1;
          }
          .p-submenu-icon {
            color: $color_1;
          }
        }
      }
    }
    .p-menuitem.p-highlight.p-focus {
      > .p-menuitem-content {
        background: $background-color_5;
      }
    }
    .p-menuitem-separator {
      border-top: 1px solid $space-grey-color;
      margin: 0.25rem 0;
    }
    .p-submenu-list {
      &:not(.p-panelmenu-root-list) {
        padding: 0 0 0 1rem;
      }
    }
  }
  .p-panelmenu-panel {
    margin-bottom: 0;
    .p-panelmenu-header {
      .p-panelmenu-header-content {
        border-radius: 0;
      }
    }
    .p-panelmenu-content {
      border-radius: 0;
    }
    &:not(:first-child) {
      .p-panelmenu-header {
        .p-panelmenu-header-content {
          border-top: 0 none;
        }
        &:not(.p-highlight) {
          &:not(.p-disabled) {
            &:hover {
              .p-panelmenu-header-content {
                border-top: 0 none;
              }
            }
          }
        }
        &:not(.p-disabled).p-highlight {
          &:hover {
            .p-panelmenu-header-content {
              border-top: 0 none;
            }
          }
        }
      }
    }
    &:first-child {
      .p-panelmenu-header {
        .p-panelmenu-header-content {
          border-top-right-radius: 6px;
          border-top-left-radius: 6px;
        }
      }
    }
    &:last-child {
      .p-panelmenu-header {
        &:not(.p-highlight) {
          .p-panelmenu-header-content {
            border-bottom-right-radius: 6px;
            border-bottom-left-radius: 6px;
          }
        }
      }
      .p-panelmenu-content {
        border-bottom-right-radius: 6px;
        border-bottom-left-radius: 6px;
      }
    }
  }
}
.p-slidemenu {
  padding: 0.25rem 0;
  background: $white-color;
  color: $color_2;
  border: 1px solid $space-grey-color;
  border-radius: 6px;
  width: 12.5rem;
  .p-slidemenu-root-list {
    outline: 0 none;
  }
  .p-submenu-list {
    outline: 0 none;
  }
  .p-menuitem {
    > .p-menuitem-content {
      color: $color_2;
      transition: box-shadow 0.2s;
      border-radius: 0;
      .p-menuitem-link {
        color: $color_2;
        padding: 0.5rem;
        user-select: none;
        .p-menuitem-text {
          color: $color_2;
        }
        .p-menuitem-icon {
          color: $color_1;
          margin-right: 0.5rem;
        }
        .p-submenu-icon {
          color: $color_1;
        }
      }
    }
    &:not(.p-highlight) {
      &:not(.p-disabled).p-focus {
        > .p-menuitem-content {
          color: $color_2;
          background: $space-grey-color;
          .p-menuitem-link {
            .p-menuitem-text {
              color: $color_2;
            }
            .p-menuitem-icon {
              color: $color_2;
            }
            .p-submenu-icon {
              color: $color_2;
            }
          }
        }
      }
      &:not(.p-disabled) {
        > .p-menuitem-content {
          &:hover {
            color: $color_2;
            background: $background-color_5;
            .p-menuitem-link {
              .p-menuitem-text {
                color: $color_2;
              }
              .p-menuitem-icon {
                color: $color_1;
              }
              .p-submenu-icon {
                color: $color_1;
              }
            }
          }
        }
      }
    }
  }
  .p-menuitem.p-highlight {
    > .p-menuitem-content {
      color: $color_2;
      background: $background-color_5;
      .p-menuitem-link {
        .p-menuitem-text {
          color: $color_2;
        }
        .p-menuitem-icon {
          color: $color_1;
        }
        .p-submenu-icon {
          color: $color_1;
        }
      }
    }
  }
  .p-menuitem.p-highlight.p-focus {
    > .p-menuitem-content {
      background: $background-color_5;
    }
  }
  .p-slidemenu-list {
    padding: 0.25rem 0;
    background: $white-color;
    border: 0 none;
    box-shadow: 0 2px 4px -1px rgba(0, 0, 0, 0.2),
      0 4px 5px 0 rgba(0, 0, 0, 0.14), 0 1px 10px 0 rgba(0, 0, 0, 0.12);
  }
  .p-menuitem-separator {
    border-top: 1px solid $space-grey-color;
    margin: 0.25rem 0;
  }
  .p-slidemenu-icon {
    font-size: 0.875rem;
  }
  .p-icon {
    width: 0.875rem;
    height: 0.875rem;
  }
  .p-slidemenu-backward {
    padding: 0.75rem 1rem;
    color: $color_2;
    &:not(.p-disabled) {
      &:focus {
        outline: 0 none;
        outline-offset: 0;
        box-shadow: inset 0 0 0 0.2rem $focus-blue-color;
      }
    }
  }
  .p-menuitem-badge {
    background: $dark-blue-color;
    color: $color_6;
    font-size: 0.75rem;
    font-weight: 700;
    min-width: 1.5rem;
    height: 1.5rem;
    line-height: 1.5rem;
    border-radius: 6px;
    margin-left: 0.5rem;
    padding-left: 0.5rem;
    padding-right: 0.5rem;
  }
}
.p-slidemenu.p-slidemenu-overlay {
  background: $white-color;
  border: 0 none;
  box-shadow: 0 2px 4px -1px rgba(0, 0, 0, 0.2), 0 4px 5px 0 rgba(0, 0, 0, 0.14),
    0 1px 10px 0 rgba(0, 0, 0, 0.12);
}
.p-steps {
  .p-steps-item {
    .p-menuitem-link {
      background: transparent;
      transition: box-shadow 0.2s;
      border-radius: 6px;
      background: $white-color;
      .p-steps-number {
        color: $color_2;
        border: 1px solid$background-color_5;
        background: $white-color;
        min-width: 2rem;
        height: 2rem;
        line-height: 2rem;
        font-size: 1.143rem;
        z-index: 1;
        border-radius: 50%;
      }
      .p-steps-title {
        margin-top: 0.5rem;
        color: $color_1;
      }
      &:not(.p-disabled) {
        &:focus-visible {
          outline: 0 none;
          outline-offset: 0;
          box-shadow: 0 0 0 0.2rem $focus-blue-color;
        }
      }
    }
    &:before {
      content: " ";
      border-top: 1px solid $space-grey-color;
      width: 100%;
      top: 50%;
      left: 0;
      display: block;
      position: absolute;
      margin-top: -1rem;
    }
  }
  .p-steps-item.p-highlight {
    .p-steps-number {
      background: $white-color;
      color: $color_2;
    }
    .p-steps-title {
      font-weight: 600;
      color: $color_2;
    }
  }
}
.p-tabmenu {
  width: 100% !important;
  .p-tabmenu-nav {
    background: $white-color;
    border: 1px solid $space-grey-color;
    border-width: 0 0 2px 0;
    .p-menuitem-badge {
      background: $dark-blue-color;
      color: $color_6;
      font-size: 0.75rem;
      font-weight: 700;
      min-width: 1.5rem;
      height: 1.5rem;
      line-height: 1.5rem;
      border-radius: 6px;
      margin-left: 0.5rem;
      padding-left: 0.5rem;
      padding-right: 0.5rem;
    }
    .p-tabmenuitem {
      margin-right: 0;
      .p-menuitem-link {
        border: solid $space-grey-color;
        border-width: 0 0 2px 0;
        border-color: $border-color_16;
        background: $white-color;
        color: $color_1;
        padding: 1rem;
        font-weight: 600;
        border-top-right-radius: 6px;
        border-top-left-radius: 6px;
        transition: box-shadow 0.2s;
        margin: 0 0 -2px 0;
        .p-menuitem-icon {
          margin-right: 0.5rem;
        }
        &:not(.p-disabled) {
          &:focus-visible {
            outline: 0 none;
            outline-offset: 0;
            box-shadow: inset 0 0 0 0.2rem $focus-blue-color;
          }
        }
      }
      &:not(.p-highlight) {
        &:not(.p-disabled) {
          &:hover {
            .p-menuitem-link {
              background: $white-color;
              border-color: $border-color_7;
              color: $color_1;
            }
          }
        }
      }
    }
    .p-tabmenuitem.p-highlight {
      .p-menuitem-link {
        background: $white-color;
        border-color: $border-color_1;
        color: $color_5;
      }
    }
  }
  .p-tabmenu-left-icon {
    margin-right: 0.5rem;
  }
  .p-tabmenu-right-icon {
    margin-left: 0.5rem;
  }
  .p-tabmenu-nav-btn.p-link {
    background: $white-color;
    color: $color_5;
    width: 2.357rem;
    box-shadow: 0 3px 1px -2px rgba(0, 0, 0, 0.2),
      0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 1px 5px 0 rgba(0, 0, 0, 0.12);
    border-radius: 0;
    &:focus {
      outline: 0 none;
      outline-offset: 0;
      box-shadow: inset 0 0 0 0.2rem $focus-blue-color;
    }
  }
}
.p-tieredmenu {
  padding: 0.25rem 0;
  background: $white-color;
  color: $color_2;
  border-radius: 6px;
  border: none;
  width: 100%;
  .p-tieredmenu-root-list {
    outline: 0 none;
  }
  .p-submenu-list {
    padding: 0.25rem 0;
    background: $white-color;
    border: 0 none;
    box-shadow: 0 2px 4px -1px rgba(0, 0, 0, 0.2),
      0 4px 5px 0 rgba(0, 0, 0, 0.14), 0 1px 10px 0 rgba(0, 0, 0, 0.12);
    left: -177px;
    width: 160px !important;
    min-width: unset;
    border-radius: 6px;
    li {
      width: 160px;
    }
    .p-menuitem-link .p-submenu-icon {
      display: none;
    }
  }
  .p-menuitem {
    > .p-menuitem-content {
      color: $color_2;
      transition: box-shadow 0.2s;
      border-radius: 0;
      .p-menuitem-link {
        color: $color_2;
        padding: 0.75rem 1rem;
        user-select: none;
        .p-menuitem-text {
          color: $color_2;
        }
        .p-menuitem-icon {
          color: $color_1;
          margin-right: 0.5rem;
        }
        .p-submenu-icon {
          color: $color_1;
        }
      }
    }
    &:not(.p-highlight) {
      &:not(.p-disabled).p-focus {
        > .p-menuitem-content {
          color: $color_2;
          background: $space-grey-color;
          .p-menuitem-link {
            .p-menuitem-text {
              color: $color_2;
            }
            .p-menuitem-icon {
              color: $color_2;
            }
            .p-submenu-icon {
              color: $color_2;
            }
          }
        }
      }
      &:not(.p-disabled) {
        > .p-menuitem-content {
          &:hover {
            color: $color_2;
            background: $background-color_5;
            .p-menuitem-link {
              .p-menuitem-text {
                color: $color_2;
              }
              .p-menuitem-icon {
                color: $color_1;
              }
              .p-submenu-icon {
                color: $color_1;
              }
            }
          }
        }
      }
    }
  }
  .p-menuitem.p-highlight {
    > .p-menuitem-content {
      color: $color_2;
      background: $background-color_5;
      .p-menuitem-link {
        .p-menuitem-text {
          color: $color_2;
        }
        .p-menuitem-icon {
          color: $color_1;
        }
        .p-submenu-icon {
          color: $color_1;
        }
      }
    }
  }
  .p-menuitem.p-highlight.p-focus {
    > .p-menuitem-content {
      background: $background-color_5;
    }
  }
  .p-menuitem-separator {
    border-top: 1px solid $space-grey-color;
    margin: 0.25rem 0;
  }
  .p-submenu-icon {
    font-size: 0.875rem;
  }
  .p-submenu-icon.p-icon {
    width: 0.875rem;
    height: 0.875rem;
  }
  .p-menuitem-active > p-tieredmenusub > .p-submenu-list {
    left: -176px;
    width: 160px !important;
    min-width: unset;
    border-radius: 6px;
    top: 12px;
    li {
      width: 160px;
    }
  }
  .p-menuitem-link .p-submenu-icon {
    display: none;
  }
}
.p-tieredmenu.p-tieredmenu-overlay {
  background: $white-color;
  border: 0 none;
  box-shadow: 0 2px 4px -1px rgba(0, 0, 0, 0.2), 0 4px 5px 0 rgba(0, 0, 0, 0.14),
    0 1px 10px 0 rgba(0, 0, 0, 0.12);
}
.p-inline-message {
  padding: 0.5rem 0.5rem;
  margin: 0;
  border-radius: 6px;
  .p-inline-message-icon {
    font-size: 1rem;
    margin-right: 0.5rem;
  }
  .p-icon {
    width: 1rem;
    height: 1rem;
  }
  .p-inline-message-text {
    font-size: 1rem;
  }
}
.p-inline-message.p-inline-message-info {
  background: $light-blue-color;
  border: solid $dark-blue-color;
  border-width: 0px;
  color: $color_15;
  .p-inline-message-icon {
    color: $color_15;
  }
}
.p-inline-message.p-inline-message-success {
  background: $color_30;
  border: solid $color_31;
  border-width: 0px;
  color: $color_16;
  .p-inline-message-icon {
    color: $color_16;
  }
}
.p-inline-message.p-inline-message-warn {
  background: $color_32;
  border: solid $color_10;
  border-width: 0px;
  color: $color_17;
  .p-inline-message-icon {
    color: $color_17;
  }
}
.p-inline-message.p-inline-message-error {
  background: $color_33;
  border: solid $color_34;
  border-width: 0px;
  color: $color_18;
  .p-inline-message-icon {
    color: $color_18;
  }
}
.p-inline-message.p-inline-message-icon-only {
  .p-inline-message-icon {
    margin-right: 0;
  }
}
.p-message {
  margin: 1rem 0;
  border-radius: 6px;
  .p-message-wrapper {
    padding: 1rem 1.5rem;
  }
  .p-message-close {
    width: 2rem;
    height: 2rem;
    border-radius: 50%;
    background: transparent;
    transition: background-color 0.2s, color 0.2s, box-shadow 0.2s;
    &:hover {
      background: rgba(255, 255, 255, 0.5);
    }
    &:focus-visible {
      outline: 0 none;
      outline-offset: 0;
      box-shadow: 0 0 0 0.2rem $focus-blue-color;
    }
  }
  .p-message-text {
    font-size: 1rem;
    font-weight: 500;
  }
  .p-message-icon {
    font-size: 1.5rem;
    margin-right: 0.5rem;
  }
  .p-icon {
    width: 1.5rem;
    height: 1.5rem;
  }
  .p-message-summary {
    font-weight: 700;
  }
  .p-message-detail {
    margin-left: 0.5rem;
  }
}
.p-message.p-message-info {
  background: $light-blue-color;
  border: solid $dark-blue-color;
  border-width: 0 0 0 6px;
  color: $color_15;
  .p-message-icon {
    color: $color_15;
  }
  .p-message-close {
    color: $color_15;
  }
}
.p-message.p-message-success {
  background: $color_30;
  border: solid $color_35;
  border-width: 0 0 0 6px;
  color: $color_16;
  .p-message-icon {
    color: $color_16;
  }
  .p-message-close {
    color: $color_16;
  }
}
.p-message.p-message-warn {
  background: $color_32;
  border: solid $color_36;
  border-width: 0 0 0 6px;
  color: $color_17;
  .p-message-icon {
    color: $color_17;
  }
  .p-message-close {
    color: $color_17;
  }
}
.p-message.p-message-error {
  background: $color_33;
  border: solid $color_34;
  border-width: 0 0 0 6px;
  color: $color_18;
  .p-message-icon {
    color: $color_18;
  }
  .p-message-close {
    color: $color_18;
  }
}
.p-toast {
  opacity: 0.9;
  .p-toast-message {
    margin: 0 0 1rem 0;
    box-shadow: 0 2px 4px -1px rgba(0, 0, 0, 0.2),
      0 4px 5px 0 rgba(0, 0, 0, 0.14), 0 1px 10px 0 rgba(0, 0, 0, 0.12);
    border-radius: 6px;
    .p-toast-message-content {
      padding: 1rem;
      border-width: 0 0 0 6px;
      .p-toast-message-text {
        margin: 0 0 0 1rem;
      }
      .p-toast-message-icon {
        font-size: 2rem;
      }
      .p-icon {
        &:not(.p-toast-icon-close-icon) {
          width: 2rem;
          height: 2rem;
        }
      }
      .p-toast-summary {
        font-weight: 700;
      }
      .p-toast-detail {
        margin: 0.5rem 0 0 0;
      }
    }
    .p-toast-icon-close {
      width: 2rem;
      height: 2rem;
      border-radius: 50%;
      background: transparent;
      transition: background-color 0.2s, color 0.2s, box-shadow 0.2s;
      &:hover {
        background: rgba(255, 255, 255, 0.5);
      }
      &:focus-visible {
        outline: 0 none;
        outline-offset: 0;
        box-shadow: 0 0 0 0.2rem $focus-blue-color;
      }
    }
  }
  .p-toast-message.p-toast-message-info {
    background: $light-blue-color;
    border: solid $light-blue-color;
    border-width: 0 0 0 6px;
    color: $color_15;
    .p-toast-message-icon {
      color: $color_15;
    }
    .p-toast-icon-close {
      color: $color_15;
    }
  }
  .p-toast-message.p-toast-message-success {
    background: $color_30;
    border: solid $color_31;
    border-width: 0 0 0 6px;
    color: $color_16;
    .p-toast-message-icon {
      color: $color_16;
    }
    .p-toast-icon-close {
      color: $color_16;
    }
  }
  .p-toast-message.p-toast-message-warn {
    background: $color_32;
    border: solid $color_36;
    border-width: 0 0 0 6px;
    color: $color_17;
    .p-toast-message-icon {
      color: $color_17;
    }
    .p-toast-icon-close {
      color: $color_17;
    }
  }
  .p-toast-message.p-toast-message-error {
    background: $color_33;
    border: solid $color_34;
    border-width: 0 0 0 6px;
    color: $color_18;
    .p-toast-message-icon {
      color: $color_18;
    }
    .p-toast-icon-close {
      color: $color_18;
    }
  }
}
.p-galleria {
  .p-galleria-close {
    margin: 0.5rem;
    background: transparent;
    color: $color_19;
    width: 4rem;
    height: 4rem;
    transition: background-color 0.2s, color 0.2s, box-shadow 0.2s;
    border-radius: 50%;
    .p-galleria-close-icon {
      font-size: 2rem;
    }
    .p-icon-wrapper {
      .p-icon {
        width: 2rem;
        height: 2rem;
      }
    }
    &:hover {
      background: rgba(255, 255, 255, 0.1);
      color: $color_19;
    }
  }
  .p-galleria-item-nav {
    background: transparent;
    color: $color_19;
    width: 4rem;
    height: 4rem;
    transition: background-color 0.2s, color 0.2s, box-shadow 0.2s;
    border-radius: 6px;
    margin: 0 0.5rem;
    .p-galleria-item-prev-icon {
      font-size: 2rem;
    }
    .p-galleria-item-next-icon {
      font-size: 2rem;
    }
    .p-icon-wrapper {
      .p-icon {
        width: 2rem;
        height: 2rem;
      }
    }
    &:not(.p-disabled) {
      &:hover {
        background: rgba(255, 255, 255, 0.1);
        color: $color_19;
      }
    }
  }
  .p-galleria-caption {
    background: rgba(0, 0, 0, 0.5);
    color: $color_19;
    padding: 1rem;
  }
  .p-galleria-indicators {
    padding: 1rem;
    .p-galleria-indicator {
      button {
        background-color: $background-color_5;
        width: 1rem;
        height: 1rem;
        transition: background-color 0.2s, color 0.2s, box-shadow 0.2s;
        border-radius: 50%;
        &:hover {
          background: $space-grey-color;
        }
      }
    }
    .p-galleria-indicator.p-highlight {
      button {
        background: $white-color;
        color: $color_2;
        background-color: $background-color_17;
      }
    }
  }
  .p-galleria-thumbnail-container {
    background: rgba(0, 0, 0, 0.9);
    padding: 1rem 0.25rem;
    .p-galleria-thumbnail-prev {
      margin: 0.5rem;
      background-color: $background-color_4;
      color: $color_19;
      width: 2rem;
      height: 2rem;
      transition: background-color 0.2s, color 0.2s, box-shadow 0.2s;
      border-radius: 50%;
      &:hover {
        background: rgba(255, 255, 255, 0.1);
        color: $color_19;
      }
    }
    .p-galleria-thumbnail-next {
      margin: 0.5rem;
      background-color: $background-color_4;
      color: $color_19;
      width: 2rem;
      height: 2rem;
      transition: background-color 0.2s, color 0.2s, box-shadow 0.2s;
      border-radius: 50%;
      &:hover {
        background: rgba(255, 255, 255, 0.1);
        color: $color_19;
      }
    }
    .p-galleria-thumbnail-item-content {
      &:focus-visible {
        outline: 0 none;
        outline-offset: 0;
        box-shadow: 0 0 0 0.2rem $focus-blue-color;
      }
    }
  }
}
.p-galleria.p-galleria-indicators-bottom {
  .p-galleria-indicator {
    margin-right: 0.5rem;
  }
}
.p-galleria.p-galleria-indicators-top {
  .p-galleria-indicator {
    margin-right: 0.5rem;
  }
}
.p-galleria.p-galleria-indicators-left {
  .p-galleria-indicator {
    margin-bottom: 0.5rem;
  }
}
.p-galleria.p-galleria-indicators-right {
  .p-galleria-indicator {
    margin-bottom: 0.5rem;
  }
}
.p-galleria.p-galleria-indicator-onitem {
  .p-galleria-indicators {
    background: rgba(0, 0, 0, 0.5);
    .p-galleria-indicator {
      button {
        background: rgba(255, 255, 255, 0.4);
        &:hover {
          background: rgba(255, 255, 255, 0.6);
        }
      }
    }
    .p-galleria-indicator.p-highlight {
      button {
        background: $white-color;
        color: $color_2;
      }
    }
  }
}
.p-galleria-mask {
  --color_18: rgba(0, 0, 0, 0.9);
}
.p-image-mask {
  --color_18: rgba(0, 0, 0, 0.9);
}
.p-image-preview-indicator {
  background-color: $background-color_4;
  color: $color_19;
  transition: background-color 0.2s, color 0.2s, box-shadow 0.2s;
}
.p-image-preview-container {
  &:hover {
    > .p-image-preview-indicator {
      background-color: $background-color_10;
    }
  }
}
.p-image-toolbar {
  padding: 1rem;
}
.p-image-action.p-link {
  color: $color_19;
  background-color: $background-color_4;
  width: 3rem;
  height: 3rem;
  border-radius: 50%;
  transition: background-color 0.2s, color 0.2s, box-shadow 0.2s;
  margin-right: 0.5rem;
  &:last-child {
    margin-right: 0;
  }
  &:hover {
    color: $color_19;
    background-color: $background-color_11;
  }
  i {
    font-size: 1.5rem;
  }
  .p-icon {
    width: 1.5rem;
    height: 1.5rem;
  }
}
.avatar-header {
  width: 346px;
  top: 1px;
  left: 1px;
  padding: 0px, 0px, 12px, 0px;
  border-radius: 6px;
  border: 1px;
  display: flex;
  flex-wrap: wrap;
  .top-banner {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    .tag {
      width: 100%;
      display: flex;
      flex-wrap: wrap;
      justify-content: flex-end;
    }
  }
  .content {
    display: flex;
    width: 100%;
    flex-wrap: wrap;
    margin-left: 13px;
    .avatar {
      width: 20%;
    }
    .info {
      width: 80%;
      display: flex;
      flex-wrap: wrap;

      .name {
        width: 100%;
        font-size: 17.5px;
        font-weight: 600;
      }
      .mail {
        width: 100%;
        color: $single-dark-gray-color;
        font-size: 14px;
      }
    }
  }
}

.p-avatar {
  background-color: $background-color_7;
  img {
    width: 100%;
    height: 100%;
    border-radius: 6px !important;
  }
}
.p-avatar.p-avatar-lg {
  width: 3rem;
  height: 3rem;
  font-size: 1.5rem;
  border-radius: 6px !important;
  .p-avatar-icon {
    font-size: 1.5rem;
  }
}
.p-avatar.p-avatar-xl {
  width: 4rem;
  height: 4rem;
  font-size: 2rem;
  .p-avatar-icon {
    font-size: 2rem;
  }
}
.p-avatar-group {
  .p-avatar {
    border: 2px solid $white-color;
  }
}
.p-badge {
  background: $dark-blue-color;
  color: $color_6;
  font-size: 0.75rem;
  font-weight: 700;
  min-width: 1.5rem;
  height: 1.5rem;
  line-height: 1.5rem;
}
.p-badge.p-badge-secondary {
  background-color: $background-color_12;
  color: $color_6;
}
.p-badge.p-badge-success {
  background-color: $background-color_13;
  color: $color_6;
}
.p-badge.p-badge-info {
  background-color: $background-color_14;
  color: $color_6;
}
.p-badge.p-badge-warning {
  background-color: $background-color_15;
  color: $color_2;
}
.p-badge.p-badge-danger {
  background-color: $background-color_16;
  color: $color_6;
}
.p-badge.p-badge-lg {
  font-size: 1.125rem;
  min-width: 2.25rem;
  height: 2.25rem;
  line-height: 2.25rem;
}
.p-badge.p-badge-xl {
  font-size: 1.5rem;
  min-width: 3rem;
  height: 3rem;
  line-height: 3rem;
}
.p-chip {
  background-color: $background-color_7;
  color: $color_2;
  border-radius: 16px;
  padding: 0 0.5rem;
  .p-chip-text {
    line-height: 1.5;
    margin-top: 0.25rem;
    margin-bottom: 0.25rem;
  }
  .p-chip-icon {
    margin-right: 0.5rem;
  }
  .pi-chip-remove-icon {
    margin-left: 0.5rem;
    border-radius: 6px;
    transition: background-color 0.2s, color 0.2s, box-shadow 0.2s;
    &:focus-visible {
      outline: 0 none;
      outline-offset: 0;
      box-shadow: 0 0 0 0.2rem $focus-blue-color;
    }
    &:focus {
      outline: 0 none;
    }
  }
  img {
    width: 2rem;
    height: 2rem;
    margin-left: -0.5rem;
    margin-right: 0.5rem;
  }
}
.p-inplace {
  .p-inplace-display {
    padding: 0.5rem 0.5rem;
    border-radius: 6px;
    transition: background-color 0.2s, color 0.2s, border-color 0.2s,
      box-shadow 0.2s;
    &:not(.p-disabled) {
      &:hover {
        background: $background-color_5;
        color: $color_2;
      }
    }
    &:focus {
      outline: 0 none;
      outline-offset: 0;
      box-shadow: 0 0 0 0.2rem $focus-blue-color;
    }
  }
}
.p-progressbar {
  border: 0 none;
  height: 1.5rem;
  background: $space-grey-color;
  border-radius: 6px;
  .p-progressbar-value {
    border: 0 none;
    margin: 0;
    background: $dark-blue-color;
  }
  .p-progressbar-label {
    color: $color_6;
    line-height: 1.5rem;
  }
}
.p-scrolltop {
  width: 3rem;
  height: 3rem;
  border-radius: 50%;
  box-shadow: 0 2px 4px -1px rgba(0, 0, 0, 0.2), 0 4px 5px 0 rgba(0, 0, 0, 0.14),
    0 1px 10px 0 rgba(0, 0, 0, 0.12);
  transition: background-color 0.2s, color 0.2s, box-shadow 0.2s;
  .p-scrolltop-icon {
    font-size: 1.5rem;
    color: $color_19;
  }
  .p-icon {
    width: 1.5rem;
    height: 1.5rem;
  }
}
.p-scrolltop.p-link {
  background: rgba(0, 0, 0, 0.7);
  &:hover {
    background: rgba(0, 0, 0, 0.8);
  }
}
.p-skeleton {
  background-color: $background-color_5;
  border-radius: 6px;
  &:after {
    background: linear-gradient(
      90deg,
      rgba(255, 255, 255, 0),
      rgba(255, 255, 255, 0.4),
      rgba(255, 255, 255, 0)
    );
  }
}
.p-tag {
  background: $dark-blue-color;
  color: $color_6;
  font-size: 0.75rem;
  font-weight: 700;
  padding: 0.25rem 0.4rem;
  border-radius: 6px;
  .p-tag-icon {
    margin-right: 0.25rem;
    font-size: 0.75rem;
  }
  .p-icon {
    width: 0.75rem;
    height: 0.75rem;
  }
}
.p-tag.p-tag-success {
  background-color: $background-color_13;
  color: $color_6;
}
.p-tag.p-tag-info {
  background-color: $background-color_14;
  color: $color_6;
}
.p-tag.p-tag-warning {
  background-color: $background-color_15;
  color: $color_2;
}
.p-tag.p-tag-danger {
  background-color: $background-color_16;
  color: $color_6;
}
.p-terminal {
  background: $white-color;
  color: $color_2;
  border: 1px solid $space-grey-color;
  padding: 1rem;
  .p-terminal-input {
    font-family: $font-family_2;
    font-feature-settings: $font-family_1;
    font-size: 1rem;
  }
}
@layer {
  primeng {
    .p-carousel
      .p-carousel-indicators
      .p-carousel-indicator.p-highlight
      button {
      background-color: $dark-blue-color;
    }
  }
}
@media screen and (max-width: 769px) {
  .p-datepicker {
    table {
      th {
        padding: 0;
      }
      td {
        padding: 0;
      }
    }
  }
}
@media screen and (max-width: 960px) {
  .p-dock.p-dock-top {
    .p-dock-list-container {
      overflow-x: auto;
      width: 100%;
      .p-dock-list {
        margin: 0 auto;
      }
    }
  }
  .p-dock.p-dock-bottom {
    .p-dock-list-container {
      overflow-x: auto;
      width: 100%;
      .p-dock-list {
        margin: 0 auto;
      }
    }
  }
  .p-dock.p-dock-left {
    .p-dock-list-container {
      overflow-y: auto;
      height: 100%;
      .p-dock-list {
        margin: auto 0;
      }
    }
  }
  .p-dock.p-dock-right {
    .p-dock-list-container {
      overflow-y: auto;
      height: 100%;
      .p-dock-list {
        margin: auto 0;
      }
    }
  }
  .p-dock {
    .p-dock-list {
      .p-dock-item {
        transform: none;
        margin: 0;
      }
    }
  }
  .p-menubar {
    position: relative;
    .p-menubar-button {
      display: flex;
      width: 2rem;
      height: 2rem;
      color: $color_1;
      border-radius: 50%;
      transition: background-color 0.2s, color 0.2s, box-shadow 0.2s;
      &:hover {
        color: $color_1;
        background: $background-color_5;
      }
      &:focus {
        outline: 0 none;
        outline-offset: 0;
        box-shadow: 0 0 0 0.2rem $focus-blue-color;
      }
    }
    .p-menubar-root-list {
      position: absolute;
      display: none;
      padding: 0.25rem 0;
      background: $white-color;
      border: 0 none;
      box-shadow: 0 2px 4px -1px rgba(0, 0, 0, 0.2),
        0 4px 5px 0 rgba(0, 0, 0, 0.14), 0 1px 10px 0 rgba(0, 0, 0, 0.12);
      width: 100%;
      .p-menuitem-separator {
        border-top: 1px solid $space-grey-color;
        margin: 0.25rem 0;
      }
      .p-submenu-icon {
        font-size: 0.875rem;
      }
      .p-menuitem {
        width: 100%;
        position: static;
        width: 100%;
        position: static;
        .p-menuitem-content {
          .p-menuitem-link {
            .p-submenu-icon {
              margin-left: auto;
              transition: transform 0.2s;
            }
          }
        }
      }
      .p-menuitem.p-menuitem-active {
        > .p-menuitem-content {
          > .p-menuitem-link {
            > .p-submenu-icon {
              transform: rotate(-180deg);
            }
          }
        }
      }
      .p-submenu-list {
        width: 100%;
        position: static;
        box-shadow: none;
        border: 0 none;
        .p-submenu-icon {
          transition: transform 0.2s;
          transform: rotate(90deg);
        }
        .p-menuitem-active {
          > .p-menuitem-content {
            > .p-menuitem-link {
              > .p-submenu-icon {
                transform: rotate(-90deg);
              }
            }
          }
        }
        .p-menuitem {
          .p-menuitem-content {
            .p-menuitem-link {
              padding-left: 2.25rem;
            }
          }
          .p-submenu-list {
            .p-menuitem {
              .p-menuitem-content {
                .p-menuitem-link {
                  padding-left: 3.75rem;
                }
              }
              .p-submenu-list {
                .p-menuitem {
                  .p-menuitem-content {
                    .p-menuitem-link {
                      padding-left: 5.25rem;
                    }
                  }
                  .p-submenu-list {
                    .p-menuitem {
                      .p-menuitem-content {
                        .p-menuitem-link {
                          padding-left: 6.75rem;
                        }
                      }
                      .p-submenu-list {
                        .p-menuitem {
                          .p-menuitem-content {
                            .p-menuitem-link {
                              padding-left: 8.25rem;
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
  .p-menubar.p-menubar-mobile-active {
    .p-menubar-root-list {
      display: flex;
      flex-direction: column;
      top: 100%;
      left: 0;
      z-index: 1;
      background-color: $royal-blue-color !important;
      border-radius: 6px;
    }
  }
}
.managemant-widget-page-info-accordion {
  .p-accordion .p-accordion-header .p-accordion-header-link {
    padding: 1rem;
    border: none;
    background-color: white;
    border-bottom: 1px dashed #ced4da;
    color: #495057;
  }
  .p-accordion .p-accordion-header .p-accordion-header-link:hover {
    padding: 1rem;
    background-color: white !important;
    border: none;
    border-bottom: 1px dashed #ced4da;
  }
  .p-accordion p-accordiontab:last-child .p-accordion-content {
    border-bottom-left-radius: 6px;
    border: none !important;
  }
  .p-accordion
    .p-accordion-header:not(.p-disabled).p-highlight:hover
    .p-accordion-header-link {
    border-color: #dee2e6;
    background: #ffffff;
    color: #495057;
  }
}
//Custom file uploader
.file-uploader {
  width: 100%;
  background-color: white;
  border: 2px dashed #007bff;
  background-color: #ffffff;
  border-radius: 6px;

  .img {
    width: auto;
    height: auto;
  }
}
