@import "./variables";

@font-face {
  font-family: Inter;
  font-style: normal;
  font-weight: normal;
  src: local("Inter-Regular"),
    url("../fonts/static/Inter-Regular.woff") format("woff");
}

* {
  box-sizing: border-box;
  font-family: Inter;
}

body {
  background-color: $root-surface-ground-color;
  margin: 0px;
}

.p-datatable .p-datatable-thead > tr > th {
  font-size: 0.875rem;
}

.p-datatable .p-datatable-tbody > tr > td {
  font-size: 0.875rem;
}

.multiSelect-position {
  z-index: 9;
}

.p-datatable .p-datatable-tbody > tr > td {
  > div {
    font-size: 0.875rem;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 8rem;
    white-space: nowrap;
    .ellipsis-container {
      overflow: hidden;
      text-overflow: ellipsis;
      max-width: 8rem;
      white-space: nowrap;
    }
  }
}

button.p-element.p-button-primary.p-button-undefined.p-button.p-component.p-button-icon-only {
  background-color: $light-blue-color !important;
  color: $royal-blue-color !important;
  &:not(:disabled) {
    &:hover {
      background: $light-royal-blue-color;
      color: $royal-blue-color;
      border-color: $light-royal-blue-color;
    }
    &:focus {
      box-shadow: none;
    }
    &:active {
      background: $royal-blue-color;
      color: $white-color;
      border-color: $royal-blue-color;
    }
  }
}

.p-datatable {
  .p-datatable-header {
    button {
      width: max-content !important;
      &.p-element.p-ripple.p-button.p-component.p-button-icon-only.ng-star-inserted {
        padding: 0.5rem;
        width: 40px !important;
        &.p-button-warning {
          background-color: $orange-500;
        }
      }
    }
    .searchWidth {
      .p-inputtext {
        height: 40px;
      }
    }
    button.p-element.p-button-outlined.p-button.p-component.p-button-icon-only {
      padding: 0.75rem;
    }

    p-button#dropdownButton {
      button {
        padding: 0.5rem;
        background-color: transparent;
        width: 40px !important;
      }
    }
    .p-button-icon.pi-download {
      &::before {
        content: "\e98e" !important;
        font-family: "primeicons" !important;
      }
    }
  }
}

.choose-columns {
  &.p-overlaypanel {
    margin-top: 0;
    .p-overlaypanel-content {
      padding: 0.5rem;
    }
  }
}

.p-overlaypanel {
  .p-overlaypanel-content {
    .p-slidemenu {
      width: 100%;
      border: none;
      .pi-angle-left:before {
        content: "\e9f7";
        padding-left: 12px;
      }
      .p-slidemenu-wrapper {
        width: 100% !important;
        .p-slidemenu-root-list {
          width: 100% !important;
          &:not(.p-active-submenu) {
            .p-submenu-icon {
              display: none;
            }
          }
        }
      }
    }
    .avatar-header {
      &.mobile {
        width: 280px;
      }
    }
  }
}

form {
  label {
    &.error {
      color: $hot-red-color !important;
    }
    &.required-input-label {
      &::before {
        content: "*";
        padding-right: 0.125rem;
        color: $hot-red-color;
      }
    }
    &.title {
      font-weight: 600;
      font-size: 0.875rem;
      color: $text-gray-color;
      display: block;
      margin-bottom: 0.5rem;
    }
  }
  h3 {
    &.required-input-label {
      &::before {
        content: "*";
        padding-right: 0.125rem;
        color: $hot-red-color;
      }
    }
  }
}

.p-dialog {
  display: flex;
  flex-direction: column;
  pointer-events: auto;
  transform: scale(1);
  position: relative;
  width: 50%;
  height: 80%;
  margin-top: -4.7%;
  max-height: calc(100vh - 44px);
}

.remove-authorization {
  .p-dialog-header {
    border-bottom: 1px solid $border-light-gray-color;
    .p-dialog-title {
      &::before {
        content: "\e989";
        color: $button-danger-background;
        padding-right: 8px;
        font-family: "primeicons";
      }
    }
  }
}
.add-authorization {
  .p-dialog-header {
    border-bottom: 1px solid $border-light-gray-color;
    .p-dialog-title {
      &::before {
        content: "\e924";
        color: $royal-blue-color;
        padding-right: 8px;
        font-family: "primeicons";
      }
    }
  }
}

.p-dialog-maximized {
  .img-container {
    img {
      width: 34% !important ;
      height: auto !important;
    }
  }
}

.send-test-confirmation {
  .p-dialog-header {
    border-bottom: 1px solid $border-light-gray-color;
    .p-dialog-title {
      &::before {
        content: "\e924";
        color: $royal-blue-color;
        padding-right: 0.5rem;
        font-family: "primeicons";
      }
    }
  }
}
@media only screen and (max-width: 959px) {
  .p-dialog {
    width: 85% !important;
  }
}

.managemant-wrapper {
  .p-datatable .p-datatable-tbody > tr > td {
    font-size: 0.75rem;
    padding: 0 0.5rem;
    max-width: 150px;
    &:first-child {
      max-width: 220px;
    }
  }
  .p-datatable .p-datatable-thead > tr > th {
    font-size: 0.875rem;
    padding: 4px 0.5rem;
    max-width: 150px;
    &:first-child {
      max-width: 220px;
      padding-left: 0.5rem !important;
    }
  }
}
pk-header {
  .p-menubar .p-menuitem > .p-menuitem-content .p-menuitem-link {
    padding: 0.65625rem 1.0625rem;
  }
}
span.p-input-icon-left.p-ml-auto.searchWidth {
  padding-right: 4px;
}
.p-dropdown {
  width: 100%;
  height: 45px;
  display: flex;
  align-items: center;
}

@media only screen and (max-width: 960px) {
  .main .action-buttons pk-button .p-button {
    font-size: small;
    padding: 0.5rem !important;
  }

  .p-button {
    font-size: small;
    padding: 2%;
    padding-left: 10px;
    padding-right: 10px;
  }
  .p-datatable .p-datatable-header button {
    margin-right: 4px;
    margin-left: 4px;
  }
  .p-button {
    font-size: small;
    padding: 2%;
    margin: 1%;
    margin-left: 4px;
  }
  .p-datatable
    .p-datatable-header
    button.p-element.p-button-outlined.p-button.p-component.p-button-icon-only {
    padding: 0.75rem;
    padding-left: 10px !important;
    padding-right: 10px !important;
  }
  button.p-element.p-ripple.p-button-success.p-mr-2.p-button.p-component.ng-star-inserted {
    padding-right: 2px;
    padding-left: 10px;
  }

  .p-datatable .p-datatable-header span.p-button-label {
    display: none;
  }

  .input-group.clear-container button,
  .input-group .advanced-filter button {
    display: flex;
    padding-left: 8px;
    padding-right: 2px;
  }
  .input-group .clear-container button,
  .input-group .advanced-filter button {
    display: flex;
    padding-left: 10px;
    padding-right: 2px;
  }
}
@media only screen and (max-width: 620px) {
  .input-group .clear-container button,
  .input-group.advanced-filter button {
    display: flex;
    padding-left: 10px;
  }
  .p-datatable .p-datatable-header span.p-button-label {
    display: none;
  }
  .p-datatable .p-datatable-header button {
    padding-left: 10px;
  }
}
.add-widget-container {
  vlc-slider {
    width: 100% !important;
  }
  vlc-slider-bigger {
    width: 100% !important;
  }
  vlc-news {
    width: 100% !important;
  }
}
.container {
  .widget-content-container {
    min-height: calc(100vh - 156px);
    background-color: $white-color;
    border-radius: 0.5rem 0.5rem 0 0;
  }
}

.p-inputtext::placeholder {
  color: $global-text-secondary-color !important;
  padding-left: 2px;
}

.p-accordion .p-accordion-header:not(.p-disabled) .p-accordion-header-link {
  .p-accordion-toggle-icon {
    rotate: 90deg;
  }
}
.p-accordion
  .p-accordion-header:not(.p-disabled).p-highlight
  .p-accordion-header-link {
  .p-accordion-toggle-icon {
    rotate: 180deg;
  }
}
