/* style-utils.scss */
.red-color {
  color: red;
}

.text-highlight {
  background-color: yellow;
}

.text-strikethrough {
  text-decoration: line-through;
}

.text-underline {
  text-decoration: underline;
}

.text-bold {
  font-weight: bold;
}

/* Diğer stil sınıflarını buraya ekleyebilirsiniz */
